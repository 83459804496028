import React from 'react';

const TrendUpSVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='12'
      viewBox='0 0 15 12'
      fill='none'
    >
      <path
        d='M1.01138 11.4799L1.07538 11.5593C1.32604 11.8307 1.74489 11.8818 2.05592 11.664L12.4531 4.38496L11.6643 8.86808L11.653 8.97861C11.6432 9.34407 11.9022 9.67141 12.273 9.73673C12.681 9.80838 13.0698 9.53588 13.1416 9.12793L14.2445 2.85753L14.2557 2.747C14.2656 2.38155 14.0066 2.0542 13.6358 1.98897L7.37067 0.886912L7.26012 0.875819C6.89461 0.865867 6.56726 1.12486 6.50209 1.49571L6.49085 1.60625C6.48098 1.9717 6.73998 2.29904 7.11081 2.36436L11.596 3.15393L1.19556 10.4353L1.11615 10.4993C0.844727 10.75 0.79359 11.1688 1.01138 11.4799Z'
        fill='#2DD752'
      />
    </svg>
  );
};

export default TrendUpSVG;
