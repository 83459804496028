import {Response, TradePoint} from '../../../common/types';
import {apiSlice} from '../apiSlice';
import {RequestBodyType} from './types';

export const clientApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTradePointList: builder.query<Response<TradePoint[]>, RequestBodyType>({
      query: (body: RequestBodyType) => ({
        url: '/clientservice/tradePoint/list',
        method: 'POST',
        body,
      }),
    }),
    uploadTradePointPlan: builder.mutation<Response<any>, any>({
      query: ({id, body}: {id: number, body: FormData}) => ({
        url: `/clientservice/tradePoint/${id}/plan_image`,
        method: 'POST',
        body,
      }),
    }),
    updateTradePoint: builder.mutation<Response<any>, any>({
      query: ({id, body}: {id: number, body: ShopType}) => ({
        url: `/clientservice/tradePoint/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    addCamera: builder.mutation<Response<any>, any>({
      query: (body: CameraType) => ({
        url: `/clientservice/camera`,
        method: 'POST',
        body,
      }),
    }),
    updateCamera: builder.mutation<Response<any>, any>({
      query: ({id, body}: {id: number, body: CameraType}) => ({
        url: `/clientservice/camera/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getCameraList: builder.query<Response<any>, any>({
      query: (body: {limit: number, offset: number, tradePointId: number}) => ({
        url: '/clientservice/camera/list',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetTradePointListQuery,
  useGetCameraListQuery,
  useUploadTradePointPlanMutation,
  useUpdateTradePointMutation,
  useAddCameraMutation,
  useUpdateCameraMutation,
} = clientApi;
