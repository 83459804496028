import React, { useEffect, useState } from 'react';
import PieChart from './PieChart';

import styles from './PieCharts.module.scss';

type PieChartsProps = {
  categories: any[];
};

function getRandomPercentages(length: number): number[] {
  let sum = 0;
  let percentages = Array.from({ length }, () => {
    const v = Math.random();
    sum += v;
    return v;
  }); /* new Array(numItems).fill(0).map(() => Math.random()) */
  // let sum = percentages.reduce((a, b) => a + b, 0);
  return percentages.map(value => value / sum);
}

function hslToHex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function generateContrastColors(numColors: number) {
  let colors = [];
  for (let i = 0; i < numColors; i++) {
    let hue = Math.round(360 * (i / numColors));
    colors.push(hslToHex(hue, 70, 60));
  }
  return colors;
}

export default function PieCharts({ categories }: PieChartsProps) {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (categories?.length) {
      const colors = generateContrastColors(categories.length);
      setData(
        getRandomPercentages(categories.length).map((value, i) => ({
          value,
          color: colors[i],
          label: categories[i].label,
        }))
      );
    }
  }, [categories]);

  return (
    (categories?.length && (
      <>
        <tr>
          <td
            rowSpan={7}
            style={{ verticalAlign: 'top' }}
            className={styles.categories}
          >
            {categories.map((category, i) => (
              <div key={i}>
                <span style={{ color: data[i]?.color }}>&#9632;</span>
                &nbsp;
                {category.label}
              </div>
            ))}
          </td>
        </tr>
        <tr>
          <td colSpan={24} className={styles.title}>
            Продано, шт.
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
        </tr>
        <tr>
          <td colSpan={24} className={styles.title}>
            Выручка
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
        </tr>
        <tr>
          <td colSpan={24} className={styles.title}>
            Маржа
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
          <td colSpan={6}>
            <PieChart data={data} />
          </td>
        </tr>
      </>
    )) ||
    null
  );
}
