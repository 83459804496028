import { Collapse, Link, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { RatingType } from '../../../common/types/Rating';
import MainContainer from '../../../components/MainContainer/MainContainer';
import { useGetCategoryReportQuery } from '../../../redux/api/productApi/rating';
import ProductsAndCategoriesCard from '../../ProductsAndCategories/components/ProductsTable/ProductsAndCategoriesCard/ProductsAndCategoriesCard';
import RatingsFilter, { RatingFilterData } from './components/RatingsFilter';
import RatingsTable from './components/RatingsTable/RatingsTable';

const Ratings = () => {
  const [filter, setFilter] = useState<RatingFilterData | undefined>();
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<RatingType>();
  const [chartTitle, setChartTitle] = useState<string | undefined>();

  const { data, isError } = useGetCategoryReportQuery(
    {
      tradePointId: filter?.shop?.id,
      categoryId: filter?.category?.id,
      categoryLevel: filter?.category?.level,
      period: filter?.period,
      order: filter?.type,
      rating_type: filter?.rating_type,
    },
    {
      skip: !(
        filter?.category?.id &&
        filter?.category?.level !== undefined &&
        filter.period &&
        filter.shop?.id &&
        filter.type
      ),
    }
  );

  const closeDialog = () => setChartTitle(undefined);

  const onRowClick = (
    event: React.MouseEvent<HTMLElement>,
    report: RatingType
  ) => {
    setChartTitle(report.productname);
    setSelectedRow(report);
  };

  const openCategoryInfo = () => {
    setChartTitle(filter?.category?.name || '');
  };

  const onFilterChange = useCallback(
    (filterData: RatingFilterData) => {
      setFilter(filterData);
      if (
        filterData?.category &&
        filterData.shop?.id &&
        filterData.period &&
        filterData.type
      ) {
        setShowTable(true);
      } else if (!showTable) {
        setShowTable(false);
      }
    },
    [showTable]
  );

  return (
    <MainContainer title='Рейтинг продаж'>
      <RatingsFilter onChange={onFilterChange} />
      <Collapse
        orientation='vertical'
        in={!!filter?.category?.name}
        sx={{
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        {filter?.category?.name && (
          <Stack direction='row' alignItems='center'>
            <Typography>
              Отчёт по лидерам / аутсайдерам продаж товаров категории:&nbsp;
              <strong>{filter.category.name}</strong>
            </Typography>
            {filter.category.categories &&
              filter.category.categories.length > 0 && (
                <Link onClick={openCategoryInfo} ml='10px'>
                  см. информацию по категории
                </Link>
              )}
          </Stack>
        )}
      </Collapse>
      {showTable && (
        <RatingsTable
          selectedRow={selectedRow}
          onRowClick={onRowClick}
          data={data as any}
        />
      )}
      {filter && (
        <ProductsAndCategoriesCard
          open={!!chartTitle}
          name={chartTitle || ''}
          categoryId={null}
          productId={selectedRow?.productid || null}
          categoryLevel={0}
          onClose={closeDialog}
        />
      )}
    </MainContainer>
  );
};

export default Ratings;
