import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { baseUrl } from '../../../../redux/api/apiSlice';
import {
  useGetCameraPolygonQuery,
  useGetPlanPolygonQuery,
  useUpdateCameraPolygonMutation,
  useUpdatePlanPolygonMutation,
} from '../../../../redux/api/clientApi/cameras';
import { ImageComponent } from './ImageComponent';

type LinkingDialogPropsType = {
  close: () => void;
  camera?: CameraType;
  shopId?: number;
};

export const LinkingDialog = ({
  camera,
  close,
  shopId,
}: LinkingDialogPropsType) => {
  const [framePoints, setFramePoints] = useState('');
  const [planPoints, setPlanPoints] = useState('');

  const { data: frameData, refetch } = useGetCameraPolygonQuery(
    { id: camera?.id },
    { skip: !camera?.id }
  );
  const [updateCamera] = useUpdateCameraPolygonMutation();

  const { data: planData } = useGetPlanPolygonQuery(
    { id: camera?.id },
    { skip: !camera?.id }
  );
  const [updatePlan] = useUpdatePlanPolygonMutation();

  useEffect(() => {
    setFramePoints(frameData?.body?.polygon);
  }, [frameData]);

  useEffect(() => {
    setPlanPoints(planData?.body?.polygon);
  }, [planData]);

  const update = async () => {
    if (camera?.id) {
      await updateCamera({
        id: camera.id,
        body: { polygon: framePoints },
      }).unwrap();
      await updatePlan({
        id: camera.id,
        body: { polygon: planPoints },
      }).unwrap();
      close?.();
      refetch();
    }
  };

  return (
    (camera && (
      <Dialog open={!!camera} onClose={close} maxWidth={false}>
        <DialogTitle>Привязка зоны видимости камеры и плана</DialogTitle>
        <DialogContent style={{ width: 'min-content', height: 'min-content' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'min-content min-content',
              gridTemplateRows: 'min-content 1fr',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '800px',
              gap: '10px 30px',
            }}
          >
            <div>Зона видимости камеры</div>
            <div>План</div>
            <ImageComponent
              url={
                camera?.id
                  ? `${baseUrl}/clientservice/camera/${camera.id}/camera_frame`
                  : ''
              }
              title='Кадр с камеры'
              setPoints={setFramePoints}
              points={framePoints}
            />
            <ImageComponent
              url={
                shopId
                  ? `${baseUrl}/clientservice/tradePoint/${shopId}/plan_image`
                  : ''
              }
              title='План торговой точки'
              setPoints={setPlanPoints}
              points={planPoints}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Отмена
          </Button>
          <Button
            onClick={update}
            autoFocus
            disabled={!framePoints || !planPoints}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )) ||
    null
  );
};
