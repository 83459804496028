import { Container, Dialog, Typography } from '@mui/material';
import React from 'react';
import PairLiftChart from '../LiftChart/PairLiftChart';
import TripleLiftChart from '../LiftChart/TripleLiftChart';

interface Props {
  lift: any;
  isTriplets: boolean;
  onClose: () => void;
  period: 'day' | 'week' | 'month';
  type: 'sku' | 'cat';
}

const LiftInfo = ({ lift, isTriplets, onClose, period, type }: Props) => {

  const {id_1, id_2, id_3, name_1, name_2, name_3} = lift || {};

  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      open={!!lift}
      onClose={onClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <Container
        sx={{
          mt: 0,
          ml: 0,
          mr: 0,
          mb: 0,
          pt: '25px',
          px: '20px',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            fontWeight: 700,
            mb: '25px',
          }}
        >
          {'' /* title */}
        </Typography>
        {isTriplets ? (
          <>
            <TripleLiftChart
              id={id_1}
              period={period}
              type={type}
              title={name_1}
            />
            <TripleLiftChart
              id={id_2}
              period={period}
              type={type}
              title={name_2}
            />
            <TripleLiftChart
              id={id_3}
              period={period}
              type={type}
              title={name_3}
            />
          </>
        ) : (
          <>
            <PairLiftChart
              id={id_1}
              period={period}
              type={type}
              title={name_1}
            />
            <PairLiftChart
              id={id_2}
              period={period}
              type={type}
              title={name_2}
            />
          </>
        )}
      </Container>
    </Dialog>
  );
};

export default LiftInfo;
