import { Link } from '@mui/material';
import styled from 'styled-components';
import emotionStyled from '@emotion/styled';

export const Nav = styled.nav`
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 23px 55px 10px 22px;
  z-index: 12;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  /* Third Nav */
  /* justify-content: flex-start; */
`;

export const NavLink = emotionStyled(Link)`
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  margin-right: 33px;
  &.active {
    color: #000000;
    font-weight: 700;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;
