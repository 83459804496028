import { Task } from '../../../common/types';
import { apiSlice } from '../apiSlice';
import { CreateTaskRequestBodyType } from './types';

export const taskApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createTask: builder.query<Task, CreateTaskRequestBodyType>({
      query: (body: CreateTaskRequestBodyType) => ({
        url: 'asynctaskservice/create_task',
        method: 'POST',
        body,
      }),
    }),
    getTask: builder.query<Task, string>({
      query: (id: string) => ({
        url: `asynctaskservice/get_task?task_id=${id}`,
      }),
    }),
  }),
});

export const { useCreateTaskQuery, useGetTaskQuery } = taskApi;
