import React from 'react';
import BarChartPlanActualSales from './BarChartPlanActualSales';
import BarChartSales from './BarChartSales';
import BarChartStackedSales from './BarChartStackedSales';
import LineChart from './LineChart';

import styles from './Card.module.scss';
import { Button } from '@mui/material';

const testData: any = [
  { date: '2021-01-01', plan: 100, actual: 120 },
  { date: '2021-01-02', plan: 200, actual: 180 },
  { date: '2021-01-03', plan: 180, actual: 250 },
  { date: '2021-01-04', plan: 220, actual: 230 },
  { date: '2021-01-05', plan: 150, actual: 290 },
  { date: '2021-01-06', plan: 250, actual: 200 },
  { date: '2021-01-07', plan: 270, actual: 210 },
  { date: '2021-01-08', plan: 190, actual: 260 },
  { date: '2021-01-09', plan: 230, actual: 210 },
  { date: '2021-01-10', plan: 250, actual: 290 },
];

export default function Card({
  data,
  close,
}: {
  data: any;
  close: () => void;
}) {
  return data ? (
    <>
      <tr>
        <td></td>
        <td colSpan={24}>
          <div className={styles.head}>
            <div className={styles.title1}>{data.label || ''}</div>
            <Button variant='text' onClick={close}>
              Закрыть
            </Button>
          </div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={12} style={{ verticalAlign: 'top' }}>
          <div className={styles.title2}>Продажи, количество, план/факт</div>
          <div>
            <BarChartPlanActualSales
              data={testData}
              planColor='#8bca90'
              actualColor='#8ecfe9'
            />
          </div>
        </td>
        <td colSpan={12}>
          <div className={styles.title2}>Продажи, количество</div>
          <div>
            <BarChartSales />
          </div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={12}>
          <div className={styles.title2}>Продажи, выручка, план/факт</div>
          <div>
            <BarChartPlanActualSales
              data={testData}
              planColor='#8bca90'
              actualColor='#8ecfe9'
            />
          </div>
        </td>
        <td colSpan={12}>
          <div className={styles.title2}>Продажи, выручка</div>
          <div>
            <BarChartSales />
          </div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={12}>
          <div className={styles.title2}>
            Продажи, доля по ценовым диапазонам
          </div>
          <div>
            <BarChartStackedSales />
          </div>
        </td>
        <td colSpan={12}>
          <div className={styles.title2}>
            Продажи, доля по ценовым диапазонам
          </div>
          <div>
            <BarChartStackedSales />
          </div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={24}>
          <div className={styles.title2}>Эластичность спроса</div>
          <div>
            <LineChart />
          </div>
        </td>
      </tr>
    </>
  ) : null;
}
