import React, { useEffect, useState } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import Period from '../../components/Period/Period';
import Shop from '../../components/Shop/Shop';
import Types from '../../components/Types/Types';
import { ProductCategory } from '../../../../common/types';
import Categories from '../../components/Categories/RatingCategories';

export interface RatingFilterData {
  category: ProductCategory;
  shop: any;
  period: string;
  type: string | number;
  rating_type: string;
}

interface Props {
  onChange: (filterData: RatingFilterData) => void;
}

const ratingTypes: any = [
  {
    value: 'count',
    label: 'Количество',
  },
  {
    value: 'total_sum',
    label: 'Сумма продаж',
  },
  {
    value: 'profit',
    label: 'Доходность',
  },
];

const RatingsFilter = ({ onChange }: Props) => {
  const [category, setCategory] = useState<ProductCategory | undefined>();
  const [shop, setShop] = useState<any>();
  const [period, setPeriod] = useState<string>('');
  const [type, setType] = useState<string | number>('');
  const [rating_type, setRatingType] = useState<any>('profit');

  useEffect(() => {
    if (category) {
      onChange({ category, shop, period, type, rating_type });
    }
  }, [category, shop, period, type, onChange, rating_type]);

  return (
    <Stack
      direction='row'
      spacing={2}
      alignItems='flex-start'
      justifyContent='flex-start'
    >
      <Shop onChange={setShop} />
      <Categories onChange={setCategory} category={category} />

      <Autocomplete
        disablePortal
        defaultValue={ratingTypes[2]}
        id='rating-autocomplete'
        placeholder='Выберите тип рейтинга'
        getOptionLabel={(option: any) => option.label}
        options={ratingTypes}
        sx={{ minWidth: 150 }}
        onChange={(_, v) => setRatingType(v?.value)}
        renderInput={params => <TextField {...params} label='Тип рейтинга' />}
      />

      <Period
        period={period}
        onChange={setPeriod}
        values={[
          { label: 'Вчера', value: 'day' },
          { label: 'Прошлая неделя', value: 'week' },
          { label: 'Прошлый месяц', value: 'month' },
        ]}
      />
      <Types
        data={[
          { name: 'Лидеры', value: 'desc' },
          { name: 'Аутсайдеры', value: 'asc' },
        ]}
        type={type}
        onChange={setType}
      />
    </Stack>
  );
};

export default RatingsFilter;
