import React from 'react';

const PositionSVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='2'
      height='16'
      viewBox='0 0 2 16'
      fill='none'
    >
      <rect x='0.000610352' width='1.5' height='16' rx='0.75' fill='#999999' />
    </svg>
  );
};

export default PositionSVG;
