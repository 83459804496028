import { Box, TableCell, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import PositionDownSVG from '../../../../../../components/svg/PositionDownSVG';
import PositionSVG from '../../../../../../components/svg/PositionSVG';
import PositionUpSVG from '../../../../../../components/svg/PositionUpSVG';

interface Props {
  position: number;
  positionChange: number;
}

const PositionCell = ({ position, positionChange }: Props) => {
  const captionColor =
    positionChange < 0 ? '#D32F2F' : positionChange > 0 ? '#2DD752' : '#999999';

  const getTooltip = () => {
    if (positionChange > 0)
      return `Поднялся на ${positionChange} позици${
        positionChange === 1 ? 'ю' : 'й'
      } с прошлого месяца`;
    if (positionChange < 0)
      return `Упал на ${-positionChange} позици${
        positionChange === 1 ? 'ю' : 'й'
      } с прошлого месяца`;
    return 'Позиция не изменилась';
  };

  return (
    <TableCell>
      <Tooltip
        title={<Typography>{getTooltip()}</Typography>}
        placement='top-start'
      >
        <Stack
          direction='row'
          sx={{
            alignItems: 'center',
          }}
        >
          <Typography variant='caption' color={captionColor}>
            {Math.abs(positionChange)}
          </Typography>
          <Box
            width={15}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              ml: '2px',
              mr: '10px',
            }}
          >
            {positionChange === 0 && <PositionSVG />}
            {positionChange > 0 && <PositionUpSVG />}
            {positionChange < 0 && <PositionDownSVG />}
          </Box>
          <Typography>{position}</Typography>
        </Stack>
      </Tooltip>
    </TableCell>
  );
};

export default PositionCell;
