import { Breakpoint, Container, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  title?: string;
  children?: ReactNode;
  maxWidth?: false | Breakpoint;
}

const MainContainer = ({ title, children, maxWidth }: Props) => {
  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        mt: 0,
        ml: 0,
        mr: 0,
        mb: 0,
        pt: '25px',
        pb: '25px',
      }}
    >
      {!!title && (
        <Typography
          variant='h6'
          sx={{
            fontWeight: 700,
            mb: '25px',
          }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Container>
  );
};

export default MainContainer;
