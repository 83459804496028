import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import React, {useEffect, useRef, useState} from 'react';
import {baseUrl} from '../../../../../redux/api/apiSlice';

interface Props {
  data?: SubShelfType;
  onClose: () => void;
}

export default ({data, onClose}: Props) => {
  const [imgUrl, setImgUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setLoading(true);
    setImgUrl(data?.hasImage ? `${baseUrl}/clientservice/sub_shelf/${data?.id}/image` : '');
  }, [data]);

  const onLoad = () => {
    setLoading(false);
    const img = imgRef.current;
    if (img && img.height > 600) {
      img.style.height = '600px';
      img.style.width = 'auto';
    }
  };

  return (
    <Dialog
      open={!!data}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={false}
    >
      <DialogContent
        dividers
        sx={{width: 'max-content'}}
      >
        <Typography variant="h5">{data?.name}</Typography>
        <Box
          minHeight={200}
          minWidth={200}
          sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}
        >
          <img
            ref={imgRef}
            src={imgUrl}
            onLoad={onLoad}
            alt={data?.name}
            style={{display: loading ? 'none' : undefined}}
          />
          {loading ? <CircularProgress/> : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
};
