import { Response } from '../../../common/types';
import { apiSlice } from '../apiSlice';

export const productAnalyticsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCategoryStat: builder.query<any, any>({
      query: ({ id, cat_level }: { id: string; cat_level: string }) => ({
        url: `/associateruleservice/category_stat?id=${id}&cat_level=${cat_level}`,
      }),
    }),
    getProductAnalytics: builder.query<Response<any>, any>({
      query: ({
        id,
        period,
        type,
      }: {
        id: string;
        type: 'good' | 'category';
        period: string;
      }) => ({
        url: `/associateruleservice/joint_one?type=${type}&id=${id}&period=${period}`,
      }),
    }),
    getProductAnalytics2: builder.query<Response<any>, any>({
      query: ({
        id,
        period,
        type,
      }: {
        id: string;
        type: 'good' | 'category';
        period: string;
      }) => ({
        url: `/associateruleservice/joint_for_one?type=${type}&id=${id}&period=${period}`,
      }),
    }),
    getProductPairAnalytics: builder.query<Response<any>, any>({
      query: ({
        id,
        period,
        type,
      }: {
        id: string;
        type: 'good' | 'category';
        period: string;
      }) => ({
        url: `/associateruleservice/joint_pair?type=${type}&id=${id}&period=${period}`,
      }),
    }),
  }),
});

export const {
  useGetProductAnalytics2Query,
  useGetCategoryStatQuery,
} = productAnalyticsApi;
