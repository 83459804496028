import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { logOut, setCredentials } from '../../slices/authSlice';
import { AuthStateType } from '../../slices/authSlice/types';
import { RootState } from '../../store';

export const baseUrl = 'https://mcp.forcesite.ru/api';

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    // send refresh token to get new access token
    const credentials = (api.getState() as RootState).auth;
    const refreshResult = await baseQuery(
      {
        url: '/authservice/refreshToken',
        method: 'PUT',
        body: credentials,
      },
      api,
      extraOptions
    );
    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setCredentials(refreshResult.data as AuthStateType));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({}),
});
