import { createSlice } from '@reduxjs/toolkit';
import { productApi } from '../../api/productApi';
import { RootState } from '../../store';
import REDUCER_NAME from './name';
import { ProductStateType } from './types';
import { createDataTree } from './utils/helpers/createDataTree';

const initialState: ProductStateType = {
  categoriesTree: [],
  categories: [],
};

const productSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      productApi.endpoints.getProductCategories.matchFulfilled,
      (state, { payload }) => {
        const dataTree = createDataTree(payload.body);
        state.categoriesTree = dataTree;
        state.categories = payload.body;
      }
    );
  },
});

export default productSlice;

export const selectProductCategoriesTree = (state: RootState) =>
  state.product.categoriesTree;

export const selectProductCategories = (state: RootState) =>
  state.product.categories;
