import React, { useMemo, useState } from 'react';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  getTableHeaderAlign,
  headerCellSX,
} from '../../../../../utils/helpers/table';
import Lift, { LiftType } from '../../../../../common/types/Lift';
import LiftInfo from '../LiftInfo/LiftInfo';
import { grey } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';

const sku2 = ['Товар 1', 'Товар 2'];
const cat2 = ['Категория 1', 'Категория 2'];

const tableHeaders: any = {
  sku: {
    2: sku2,
    3: [...sku2, 'Товар 3'],
  },
  cat: {
    2: cat2,
    3: [...cat2, 'Категория 3'],
  },
};

const commonHeaders = ['Поддержка', 'Достоверность'];

const skuHeaders = [
  'Совместных продаж на сумму',
  'Чеков с совместными продажами',
];

interface Props {
  lifts: any[];
  type: 'sku' | 'cat';
  count: 'pair' | 'triple';
  period: 'day' | 'week' | 'month';
}

const getPercent = (value: number) => Math.round(value * 10000) / 100;

const LiftsTable = ({ count, lifts, period, type }: Props) => {
  const [lift, setLift] = useState<LiftType>();
  const triplets = count === 'triple';

  const headers = useMemo(() => {
    const c = count === 'triple' ? 3 : 2;
    let list = [...(tableHeaders[type]?.[c] || []), ...commonHeaders];
    if (type === 'sku') {
      list = [...list, ...skuHeaders];
    }
    return list;
  }, [count, type]);

  const getProducts = (lift: Lift) => {
    const products = [...lift.products];
    if (triplets) {
      products.splice(1, 0, '');

      return products;
    } else {
      return products.filter((_, index) => index < 2);
    }
  };

  const openLiftDialog = (
    event: React.MouseEvent<HTMLElement>,
    lift: LiftType
  ) => {
    event.stopPropagation();
    setLift(lift);
  };

  const closeLiftDialog = () => setLift(undefined);

  const getTooltip = (title: string) => {
    const tooltips: any = {
      Поддержка: 'Чеков с товаром/Всего чеков',
      Достоверность: 'Вероятность совместной покупки',
    };
    if (tooltips[title]) {
      return (
        <Tooltip title={tooltips[title]} placement='top'>
          <InfoOutlined fontSize='small' sx={{ ml: 1 }} />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          mt: '25px',
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              {headers.map((item, index) => (
                <TableCell
                  key={item}
                  sx={headerCellSX}
                  align={getTableHeaderAlign(index, triplets ? 3 : 2)}
                >
                  <Stack direction='row'>
                    <Typography sx={{ fontWeight: '700' }}>{item}</Typography>
                    {getTooltip(item)}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lifts?.map((lift: LiftType, i) => (
              <TableRow
                key={i}
                sx={{ cursor: 'pointer' }}
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  openLiftDialog(event, lift)
                }
              >
                {Array(triplets ? 3 : 2)
                  .fill(1)
                  .map((_, i) => {
                    const name = lift[`name_${i + 1}` as keyof LiftType];
                    const catPath = lift[`cat_${i + 1}_path` as keyof LiftType];
                    return (
                      <TableCell
                        key={i}
                        align='left'
                        sx={{
                          borderColor: grey[300],
                          minHeight: '60px',
                          py: 0,
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ fontSize: '10px', color: 'gray' }}>
                          {catPath}
                        </div>
                        {name && (i !== 2 || (i === 2 && triplets)) ? (
                          name
                        ) : (
                          <Box
                            sx={{
                              backgroundColor: grey[300],
                              maxWidth: '1px',
                              minHeight: '60px',
                              ml: 0.7,
                            }}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                <TableCell align='center'>{getPercent(lift.support)}</TableCell>
                <TableCell align='center'>
                  {getPercent(lift.confidence)}
                </TableCell>
                {type === 'sku' ? (
                  <>
                    <TableCell align='center'>
                      {Math.round(lift.total_sum || 0)}
                    </TableCell>
                    <TableCell align='center'>
                      {Math.round(lift.total_cnt || 0)}
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LiftInfo
        isTriplets={triplets}
        lift={lift}
        onClose={closeLiftDialog}
        period={period}
        type={type}
      />
    </>
  );
};

export default LiftsTable;
