import React from 'react';

export const Checked = ({ half }: { half?: boolean }) => {
  return (
    <svg
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: '14px', height: 'auto' }}
    >
      <rect
        x='0.00653076'
        width='15'
        height='15'
        fill={half ? '#97a7ff' : '#3f51b5'}
        style={{ width: '15px', height: '15px' }}
      />
      <line
        x1='12.6767'
        y1='4.20304'
        x2='5.87157'
        y2='11.4815'
        stroke='#ffffff'
        strokeWidth='2'
      />
      <line
        x1='1.96119'
        y1='6.58107'
        x2='7.26527'
        y2='11.4765'
        stroke='#ffffff'
        strokeWidth='2'
      />
    </svg>

    // <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect x="0.5" y="0.5" width="14" height="14" stroke="currentColor" />
    //   <line x1="12.6702" y1="4.20304" x2="5.86504" y2="11.4815" stroke="currentColor" strokeWidth="2" />
    //   <line x1="1.95466" y1="6.58107" x2="7.25874" y2="11.4765" stroke="currentColor" strokeWidth="2" />
    // </svg>

    // <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect x="0.5" y="0.5" width="13" height="13" stroke="currentColor" />
    //   <line x1="12.0797" y1="3.96813" x2="5.93603" y2="10.5652" stroke="currentColor" strokeWidth="2" />
    //   <line x1="2.05909" y1="5.62367" x2="7.36317" y2="10.5191" stroke="currentColor" strokeWidth="2" />
    //   </svg>
  );
};
