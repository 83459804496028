import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useLoginMutation } from '../../redux/api/authApi';
import { useNavigate } from 'react-router-dom';
import { FormControl, FormHelperText } from '@mui/material';
import AppLogoSVG from '../../components/svg/AppLogoSVG';

export const LoginPage = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [login] = useLoginMutation();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await login({ login: email, password }).unwrap();
      setEmail('');
      setPassword('');
      navigate('/analytics');
    } catch (err) {
      setError('Не удалось войти');
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/*<AppLogoSVG />*/}
        <Box
          component='form'
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 5, minWidth: 400 }}
        >
          <FormControl error={!!error} variant='standard' fullWidth>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Логин'
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onInput={(e: any) => setEmail(e.target.value)}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='Пароль'
              id='passwordd'
              label='Пароль'
              type='password'
              autoComplete='current-password'
              value={password}
              onInput={(e: any) => setPassword(e.target.value)}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Войти
            </Button>
            <FormHelperText>{error}</FormHelperText>
          </FormControl>
        </Box>
      </Box>
    </Container>
  );
};
