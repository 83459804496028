import React, { useCallback, useEffect, useState } from 'react';
import MainContainer from '../../components/MainContainer/MainContainer';
import {
  useFetchProductListMutation,
  useGetProductCategoriesQuery,
} from '../../redux/api/productApi';
import CheckboxTree from 'react-checkbox-tree';
import { useAppSelector } from '../../redux/store';
import { selectProductCategoriesTree } from '../../redux/slices/productSlice';
import { useGetTradePointListQuery } from '../../redux/api/clientApi';
import { Button, Stack, Typography } from '@mui/material';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './styles.scss';
import { Unchecked } from './icons/Unchecked';
import { Checked } from './icons/Checked';
import { Collapsed } from './icons/Collapsed';
import { Expanded } from './icons/Expanded';

const createCategoryTree = (data: any, map: any, level = 1) => {
  return data.map((item: any) => {
    const category: any = {
      // ...item,
      value: item.id.toString(),
      label: item.name,
      level,
    };
    map[item.id] = category;
    if (item.categories?.length) {
      category.children = createCategoryTree(item.categories, map, level + 1);
    }
    return category;
  });
};

const getCategoryTree = (data: any, map: any = {}) => {
  return { list: createCategoryTree(data, map), map };
};

const getShopTree = (data: any[]) => {
  const map: Record<string, any> = {};
  return {
    tree: [
      {
        value: 'shops',
        label: 'Все',
        children: data.map(item => {
          const shop = {
            ...item,
            value: item.id.toString(),
            label: item.name,
          };
          map[shop.value] = shop;
          return shop;
        }),
      },
    ],
    map,
  };
};

const levelClass: any = {
  1: 'l1',
  2: 'l2',
  3: 'l3',
  4: 'l4',
  5: 'l5',
  6: 'l6',
};

const productClass = 'p';

export default function AvailableProducts() {
  const [categoriesState, setCategoriesState] = useState<
    Record<string, string[]>
  >({
    checked: [],
    expanded: [],
  });
  const [shopState, setShopState] = useState<Record<string, string[]>>({
    checked: [],
    expanded: [],
  });
  const [categoriesTree, setCategoriesTree] = useState<{
    list: any[];
    map: any;
  }>({ list: [], map: {} });
  const [shops, setShops] = useState<{ tree: any[]; map: Record<string, any> }>(
    {
      tree: [],
      map: {},
    }
  );
  const [tableCategories, setTableCategories] = useState<{
    list: any[];
    map: any;
  }>({ list: [], map: {} });

  const [product, setProduct] = useState<any>();

  const { data: tradePoints } = useGetTradePointListQuery({
    limit: 100,
    offset: 0,
    clientId: 2,
  });

  useGetProductCategoriesQuery({
    limit: 100000,
    offset: 0,
    clientId: 2,
  });

  const productCategoriesTree = useAppSelector(selectProductCategoriesTree);

  useEffect(() => {
    if ((productCategoriesTree || []).length) {
      setCategoriesTree(getCategoryTree(productCategoriesTree));
    }
  }, [productCategoriesTree]);

  useEffect(() => {
    if ((tradePoints?.body || []).length) {
      setShops(getShopTree(tradePoints?.body || []));
    }
  }, [tradePoints?.body]);

  const [getProductsByCategory] = useFetchProductListMutation();

  const getProductsByCategoryId = (id: string, updateTable = false) => {
    const category = categoriesTree.map[id];
    if (category && !category.children) {
      if (category.pid) {
        setProduct(category);
        return;
      }
      categoriesTree.map[id].children = [];
      getProductsByCategory({
        limit: 1000,
        offset: 0,
        categoryId: +id,
      }).then((res: any) => {
        if (res?.data?.body?.length) {
          categoriesTree.map[id].children = res.data.body.map((item: any) => {
            const value = id + '_' + item.id.toString();
            const category = {
              ...item,
              value,
              label: item.name,
              showCheckbox: false,
              pid: item.id,
            };
            categoriesTree.map[value] = category;
            return category;
          });
          setCategoriesTree({ ...categoriesTree });
          setCategoriesState(prev => ({
            ...prev,
            expanded: [...prev.expanded, id],
          }));
          if (updateTable) {
            setCheckedTableCategories();
          }
        }
      });
    }
  };

  const updateCheckableCategories = (tree: any[], level?: number) => {
    tree.forEach((node: any) => {
      if (level === undefined) {
        node.disabled = false;
      } else {
        node.disabled = node.level !== level;
      }
      if (node.children) {
        updateCheckableCategories(node.children, level);
      }
    });
  };

  const clearCheckableCategories = (tree: any[]) => {
    tree.forEach((node: any) => {
      node.disabled = false;
      if (node.children) {
        clearCheckableCategories(node.children);
      }
    });
  };

  const updateCategoriesTree = (checked: any, node: any) => {
    if (checked?.length) {
      if (categoriesTree && categoriesTree.map[node.value]) {
        updateCheckableCategories(categoriesTree.list, node.treeDepth + 1);
        setCategoriesTree({ ...categoriesTree });
      }
    } else {
      updateCheckableCategories(categoriesTree.list);
    }
  };

  useEffect(() => {
    if (categoriesState?.checked?.length) {
      categoriesState?.checked?.forEach(id => {
        getProductsByCategoryId(id);
      });
    }
  }, [categoriesState?.checked]);

  const CategoryTreeCheckboxes = useCallback(() => {
    return (
      <CheckboxTree
        nodes={categoriesTree.list}
        checked={categoriesState.checked}
        expanded={categoriesState.expanded}
        onCheck={(checked, node) => {
          updateCategoriesTree(checked, node);
          setCategoriesState(prev => ({ ...prev, checked }));
        }}
        onExpand={expanded =>
          setCategoriesState(prev => ({ ...prev, expanded }))
        }
        noCascade
        expandOnClick
        onClick={cat => {
          getProductsByCategoryId(cat.value);
        }}
        icons={{
          check: <Checked />,
          uncheck: <Unchecked />,
          expandOpen: <Expanded />,
          expandClose: <Collapsed />,
        }}
      />
    );
  }, [categoriesTree, categoriesState]);

  const setCheckedTableCategories = () => {
    const prev = tableCategories;
    const list: any[] = [];
    const map: any = {};
    const addCategory = (id: any, padding = 10) => {
      if (id) {
        let category = prev.map[id] || categoriesTree.map[id];
        if (category) {
          category.padding = padding;
          // category = { ...category, padding };
          list.push(category);
          map[id] = category;
          if (category.expanded && category.children) {
            category.children.forEach((child: any) => {
              addCategory(child.value, padding + 20);
            });
          }
        }
      }
    };
    categoriesState?.checked?.forEach((id: string) => {
      addCategory(id);
    });
    setTableCategories({ list, map });
  };

  useEffect(() => {
    setCheckedTableCategories();
  }, [categoriesTree, categoriesState?.checked]);

  const showChildren = (id: number) => {
    const category = tableCategories.map[id];
    if (category?.pid) {
      setProduct(category);
      return;
    }
    setProduct(null);
    category.expanded = !category.expanded;
    if (category?.children) {
      setCheckedTableCategories();
    } else {
      getProductsByCategoryId(id.toString(), true);
    }
  };

  return (
    <MainContainer title='Анализ продаж' maxWidth={false}>
      <div className='main-grid'>
        <div>
          <Typography sx={{ fontSize: 20, marginY: 1 }}>
            Торговые точки
          </Typography>
          <CheckboxTree
            nodes={shops.tree}
            checked={shopState.checked}
            expanded={shopState.expanded}
            onCheck={checked => setShopState(prev => ({ ...prev, checked }))}
            onExpand={expanded => setShopState(prev => ({ ...prev, expanded }))}
            expandOnClick
            onClick={() => {}}
            icons={{
              halfCheck: <Checked half />,
              check: <Checked />,
              uncheck: <Unchecked />,
              expandOpen: <Expanded />,
              expandClose: <Collapsed />,
            }}
          />
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ fontSize: 20, marginY: 1 }}
          >
            <Typography sx={{ fontSize: 20, marginY: 1 }}>Категории</Typography>
            {categoriesState.checked.length > 0 && (
              <Button
                variant='text'
                onClick={() => {
                  clearCheckableCategories(categoriesTree.list);
                  setCategoriesState(prev => ({
                    expanded: prev.expanded,
                    checked: [],
                  }));
                }}
                size='small'
              >
                Очистить
              </Button>
            )}
          </Stack>
          <CategoryTreeCheckboxes />
        </div>
        <div>
          <table>
            <thead>
              <tr className='header'>
                <th>Категории</th>
                <th colSpan={7}></th>
              </tr>
            </thead>
            <tbody>
              <>
                {tableCategories.list.map((cat: any) => {
                  return product === cat ? (
                    <>
                      <tr
                        key={cat.value}
                        style={{ backgroundColor: '#f5f5f5' }}
                      >
                        <td
                          style={{
                            paddingLeft: cat.padding + 'px',
                            cursor: cat.children?.length && 'pointer',
                          }}
                          rowSpan={
                            shopState.checked.length
                              ? shopState.checked.length + 1
                              : undefined
                          }
                          className={levelClass[cat.level] || productClass}
                        >
                          {cat.label}
                        </td>
                        {shopState.checked.length ? (
                          <>
                            <td>Торговая точка</td>
                            <td>Средние продажи, шт/день</td>
                            <td>Осталось, шт</td>
                            <td>Осталось на период, дни</td>
                            <td>Дата поставки</td>
                            <td>Осталось дней до поставки</td>
                            <td>Потенциальная упущенная выгода</td>
                          </>
                        ) : (
                          <td colSpan={7}></td>
                        )}
                      </tr>
                      {shopState.checked.map((shopId: string) => {
                        return (
                          <tr style={{ backgroundColor: '#f5f5f5' }}>
                            <td>{shops.map[shopId].label}</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr key={cat.value}>
                      <td
                        onClick={() => showChildren(cat.value)}
                        style={{
                          paddingLeft: cat.padding + 'px',
                          cursor:
                            !!cat.children?.length || cat !== product
                              ? 'pointer'
                              : undefined,
                        }}
                        className={levelClass[cat.level] || productClass}
                      >
                        {cat.label}
                      </td>
                      {cat.children && <td colSpan={7}></td>}
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>
        </div>
      </div>
    </MainContainer>
  );
}
