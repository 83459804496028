import {Box, Dialog, DialogContent, Typography} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {baseUrl} from '../../../../../redux/api/apiSlice';
import EmptyShelfItem from './EmptyShelfItem';

interface Props {
  shelve: EmptyShelf;
  open: boolean;
  onClose: () => void;
}

const EmptyShelfInfo = ({shelve, open, onClose}: Props) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    setSrc(shelve?.id ? `${baseUrl}/clientservice/shelf/${shelve?.id}/image` : '');
  }, [shelve]);

  if (!shelve) return <></>;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent
        dividers
        sx={{
          maxWidth: '400px',
        }}
      >
        <Typography>{shelve.tradePointName || ''}</Typography>
        <EmptyShelfItem title="Полка" subTitle={shelve.name || ''}/>
        {shelve.fixationDate ? (
          <EmptyShelfItem
            title="Дата фиксации"
            subTitle={moment(shelve.fixationDate).format('DD.MM.YYYY hh:mm:ss')}
          />
        ) : null}
        {src ? (
          <Box sx={{mt: 2, maxWidth: '500px'}}>
            <img
              src={src}
              alt="фото"
              style={{maxWidth: '100%'}}
              onError={() => setSrc('')}
            />
          </Box>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default EmptyShelfInfo;
