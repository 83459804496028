export const getPointsFromStr = (
  coords: string,
  ratio: number
): PointType[] => {
  return (
    coords && ratio
      ? coords
          .replace('((', '')
          .replace('))', '')
          .split('),(')
          .map(str => {
            const [x, y] = str.split(',');
            return { x: +x * ratio, y: +y * ratio };
          })
      : []
  ) as PointType[];
};

export const pointsToStr = (points: PointType[], ratio: number): string => {
  if (points?.length && ratio) {
    return `(${points.map(p => `(${p.x / ratio},${p.y / ratio})`).join(',')})`;
  }
  return '';
};
