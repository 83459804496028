import { SxProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

const styles: { [k: string]: SxProps<Theme> } = {
  wrapper: {
    position: 'relative',
  },
  container: {
    maxWidth: 80,
    maxHeight: 80,
    borderRadius: 2,
    overflow: 'hidden',
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    fontSize: '17px',
    top: -5,
    right: -5,
    backgroundColor: grey[400],
    borderRadius: 100,
  },
};

export default styles;
