import { Response } from '../../../common/types';
import { apiSlice } from '../apiSlice';

export const productsRatingsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCategoryReport: builder.query<Response<any>, any>({
      query: ({
        tradePointId,
        categoryId,
        categoryLevel,
        period,
        order,
        rating_type,
      }: {
        tradePointId: number;
        categoryId: number;
        categoryLevel: number;
        period: 'day' | 'week' | 'month';
        order: 'asc' | 'desc';
        rating_type?: string;
      }) => ({
        url: `/ratingservice/get_rating?id_tradepoint=${tradePointId}&id_category=${categoryId}&category_level=${categoryLevel}&period=${period}&order=${order}${
          rating_type ? `&rating_type=${rating_type}` : ''
        }`,
      }),
    }),
  }),
});

export const { useGetCategoryReportQuery } = productsRatingsApi;
