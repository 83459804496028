import React from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import CategoriesTable from './CategoriesTable';

const CategoryClusters = () => {
  return (
    <MainContainer title="Анализ ассортимента">
      <CategoriesTable products={[]}/>
    </MainContainer>
  );
};

export default CategoryClusters;
