import { Response, ProductCategory, Product } from '../../../common/types';
import { apiSlice } from '../apiSlice';
import { CategoriesRequestBodyType, ProductsRequestBodyType } from './types';

export const productApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getProductCategories: builder.query<
      Response<ProductCategory[]>,
      CategoriesRequestBodyType
    >({
      query: (body: CategoriesRequestBodyType) => ({
        url: '/productservice/category/list',
        method: 'POST',
        body,
      }),
    }),
    getProductCategoryById: builder.query<Response<ProductCategory>, string>({
      query: (id: string) => ({
        url: `/productservice/category/${id}`,
      }),
    }),
    getExcludedCategoryList: builder.query<Response<any[]>, any>({
      query: (body: any) => ({
        url: '/productservice/category/exclude/list',
        method: 'POST',
        body,
      }),
    }),
    excludeCategoryById: builder.query<Response<any>, any>({
      query: (body: any) => ({
        url: '/productservice/category/exclude',
        method: 'POST',
        body,
      }),
    }),
    removeCategoryFromExcludedById: builder.query<Response<any>, any>({
      query: (body: any) => ({
        url: '/productservice/category/exclude',
        method: 'DELETE',
        body,
      }),
    }),
    getProductList: builder.query<Response<Product[]>, ProductsRequestBodyType>(
      {
        query: (body: ProductsRequestBodyType) => ({
          url: '/productservice/product/list',
          method: 'POST',
          body,
        }),
      }
    ),
    fetchProductList: builder.mutation<Response<Product[]>, any>({
      query: (body: ProductsRequestBodyType) => ({
        url: '/productservice/product/list',
        method: 'POST',
        body,
      }),
    }),
    getProductById: builder.query<Response<any>, any>({
      query: (id: number) => ({
        url: `/productservice/product/${id}`,
      }),
    }),
    excludeProductById: builder.query<Response<any>, any>({
      query: (body: any) => ({
        url: '/productservice/product/exclude',
        method: 'POST',
        body,
      }),
    }),
    removeProductFromExcludedById: builder.query<Response<any>, any>({
      query: (body: any) => ({
        url: '/productservice/product/exclude',
        method: 'DELETE',
        body,
      }),
    }),
    getExcludedProductList: builder.query<Response<any[]>, any>({
      query: (body: any) => ({
        url: '/productservice/product/exclude/list',
        method: 'POST',
        body,
      }),
    }),
    getProductCharacteristic: builder.query<Response<any>, any>({
      query: (id: number) => ({
        url: `/productservice/product_characteristic/${id}`,
      }),
    }),
  }),
});

export const {
  useGetProductCategoriesQuery,
  useGetProductCategoryByIdQuery,
  useGetProductListQuery,
  useFetchProductListMutation,
  useGetExcludedProductListQuery,
  useGetExcludedCategoryListQuery,
  useExcludeProductByIdQuery,
  useExcludeCategoryByIdQuery,
  useGetProductByIdQuery,
  useRemoveProductFromExcludedByIdQuery,
  useRemoveCategoryFromExcludedByIdQuery,
  useGetProductCharacteristicQuery,
} = productApi;
