import React, { ChangeEvent, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import {
  useDeleteShelfMutation,
  useUploadTestShelfImageMutation,
} from '../../../../../redux/api/clientApi/shelves';
import { headerCellSX } from '../../../../../utils/helpers/table';
import { DeleteForever, Image, Upload } from '@mui/icons-material';
import moment from 'moment';
import { Shelf } from '../shelf/Shelf';
import './styles.css';

interface Props {
  list?: ShelfType[];
  edit: (v: number) => void;
  refetch: () => void;
}

export const ShelvesTable = ({ edit, list, refetch }: Props) => {
  const [shelfId, setShelfId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [fileWarning, setFileWarning] = useState(false);
  const [testImgShelfId, setTestImgShelfId] = useState(0);
  const [remove] = useDeleteShelfMutation();
  const [upload, { isSuccess, isError, reset }] =
    useUploadTestShelfImageMutation();
  const inputRef = useRef<HTMLInputElement>(null);

  const closeEmptyShelveDialog = () => setShelfId(0);

  const deleteShelf = async () => {
    if (deleteId) {
      await remove({ id: deleteId }).unwrap();
    }
    setDeleteId(0);
    refetch?.();
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file && testImgShelfId) {
      if (file.type !== 'image/jpeg') {
        setFileWarning(true);
      } else if (testImgShelfId) {
        const formData = new FormData();
        formData.set('file', file);
        formData.set('id', testImgShelfId.toString());
        await upload(formData).unwrap();
        refetch?.();
        setTestImgShelfId(0);
      }
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: '25px' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellSX} width='40' align='center'>
                ID
              </TableCell>
              <TableCell sx={headerCellSX} width='auto' align='left'>
                Торговая точка
              </TableCell>
              <TableCell sx={headerCellSX} width='auto' align='left'>
                Полка
              </TableCell>
              <TableCell sx={headerCellSX} width='auto' align='center'>
                Дата фиксации
              </TableCell>
              <TableCell sx={headerCellSX} width='auto' align='center'>
                Тестовое изображение
              </TableCell>
              <TableCell sx={headerCellSX} width='30' align='center'>
                Фото
              </TableCell>
              <TableCell sx={headerCellSX} width='30' />
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map(shelf => (
              <TableRow
                key={shelf.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  backgroundColor: shelf?.emptyPlaces ? '#ffcece' : '',
                }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  edit(shelf?.id);
                }}
              >
                <TableCell align='center'>{shelf.id}</TableCell>
                <TableCell align='left'>{shelf.tradePointName}</TableCell>
                <TableCell align='left'>{shelf.name}</TableCell>
                <TableCell align='center'>
                  {shelf.fixationDate
                    ? moment(shelf.fixationDate).format('DD.MM.YYYY hh:mm:ss')
                    : '-'}
                </TableCell>
                <TableCell align='center'>
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={inputRef}
                    onChange={e => onFileChange(e)}
                    onClick={e => e.stopPropagation()}
                  />
                  <Tooltip title='Загрузить изображение' placement='top'>
                    <IconButton
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        setTestImgShelfId(shelf.id);
                        inputRef.current?.click();
                      }}
                    >
                      <Upload fontSize='small' className='cell-button' />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                {shelf?.hasShelfImage ? (
                  <TableCell
                    align='center'
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      setShelfId(shelf?.id);
                    }}
                  >
                    <Tooltip title='Открыть карточку' placement='top'>
                      <IconButton>
                        <Image fontSize='small' className='cell-button' />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell />
                )}
                <TableCell
                  align='center'
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setDeleteId(shelf.id);
                  }}
                >
                  <Tooltip title='Удалить' placement='top'>
                    <IconButton>
                      <DeleteForever fontSize='small' className='cell-button' />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Shelf id={shelfId} open={!!shelfId} onClose={closeEmptyShelveDialog} />
      <Dialog open={!!deleteId} onClose={() => setDeleteId(0)}>
        <DialogTitle>Удалить полку?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteId(0)}>Отмена</Button>
          <Button onClick={deleteShelf} autoFocus>
            Да
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={fileWarning} onClose={() => setFileWarning(false)}>
        <DialogTitle>Изображение должно быть в формате jpeg (jpg)</DialogTitle>
        <DialogActions>
          <Button onClick={() => setFileWarning(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isError || isSuccess} onClose={reset}>
        <DialogTitle>
          {isError ? 'Ошибка загрузки изображения' : ''}
          {isSuccess ? 'Изображение успешно загружено' : ''}
        </DialogTitle>
        <DialogActions>
          <Button onClick={reset}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
