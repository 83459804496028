import { AddPhotoAlternate } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Shelve } from '../../../../common/types';
import ImagePreview from '../ImagePreview/ImagePreview';

const style: SxProps<Theme> = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

interface Props {
  open: boolean;
  onClose: () => void;
  addShelve: (shelve: Shelve) => void;
}

const CreateShelves = ({ open, addShelve, onClose }: Props) => {
  const [name, setName] = useState('');
  const [length, setLength] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [images, setImages] = useState<string[]>([]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    addShelve({
      id: (Math.random() * 10).toString(),
      name,
      length: length as number,
      width: width as number,
      images,
    });
  };

  const handleImages = ({ target }: any) => {
    const additionalImages: any[] = [];
    for (let file of target.files) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result) {
          additionalImages.push(e.target.result);
        }
        if (additionalImages.length === target.files.length)
          setImages([...images, ...additionalImages]);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Stack spacing={2} component='form' onSubmit={handleSubmit} noValidate>
          <Typography variant='h6' component='h2'>
            Новая полка
          </Typography>
          <TextField
            required
            label='Название'
            id='name'
            variant='outlined'
            value={name}
            onChange={event => setName(event.target.value)}
          />
          <TextField
            required
            label='Длина'
            id='length'
            variant='outlined'
            type='number'
            value={length}
            onChange={event => setLength(+event.target.value)}
          />
          <TextField
            required
            label='Ширина'
            id='width'
            variant='outlined'
            type='number'
            value={width}
            onChange={event => setWidth(+event.target.value)}
          />
          <IconButton color='primary' component='label'>
            <input
              type='file'
              accept='image/*'
              hidden
              onChange={handleImages}
              multiple
            />
            <AddPhotoAlternate fontSize='medium' />
          </IconButton>
          {images.length > 0 && (
            <ImagePreview images={images} onChange={setImages} />
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disabled={!name || !length || !width}
          >
            Создать
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CreateShelves;
