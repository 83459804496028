import React, {useMemo} from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  BarControllerChartOptions,
} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {_DeepPartialObject} from 'chart.js/types/utils';
import {Box, Grid, Stack, Typography} from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

const colors = [
  '#0f360e',
  '#0349fc',
  '#fc8403',
  '#A3A3A3',
  '#fce703',
  '#03c6fc',
  '#67fc03',
  '#201f80',
  '#591616',
  '#a24c4c',
];

const MarketChart = ({data: categoriesInfo}: {data?: ShopClusterCategoryInfoType[]}) => {

  const data = useMemo(() => ({
    labels: (categoriesInfo || []).map((cat) => cat.category_name),
    datasets: [
      {
        label: '',
        data: (categoriesInfo || []).map((cat) => cat.category_sales_num),
        backgroundColor: (categoriesInfo || []).map((_, i) => colors[i] || colors[i % 10] || colors[0]),
        borderColor: '#ffffff',
        borderWidth: 1,
      },
    ],
  }), [categoriesInfo]);

  const options: _DeepPartialObject<CoreChartOptions<'pie'> &
    ElementChartOptions<'pie'> &
    PluginChartOptions<'pie'> &
    DatasetChartOptions<'pie'> &
    ScaleChartOptions<'pie'> &
    BarControllerChartOptions> = useMemo(() => {

    const sum = (categoriesInfo || [])?.reduce((sum, cat) => {
      return sum + (cat?.category_sales_num || 0);
    }, 0) || 0;

    return {
      layout: {
        padding: 35,
      },
      indexAxis: 'y',
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          align: 'end',
          anchor: 'end',
          font: {
            weight: 'bold',
          },
          formatter: (value, context) => `${Math.round((value || 0) / sum * 10000) / 100}%`,
          padding: 2,
        },
      },
      scales: {
        y: {display: false},
        x: {display: false},
      },
    };
  }, [categoriesInfo]);

  return (
    <Box>
      <Pie data={data} options={options}/>
      <Grid
        container
        spacing={{xs: 2, md: 0}}
        columns={{xs: 4, sm: 8, md: 12}}
      >
        {categoriesInfo?.map((item, index) => (
          <Grid xs={2} sm={4} md={4} key={index} my={1} item>
            <Stack
              spacing={2}
              direction="row"
              sx={{alignItems: 'center'}}
            >
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: colors[index],
                }}
              />
              <Typography>{item?.category_name || ''}</Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MarketChart;
