import React from 'react';
import { Navigate } from 'react-router-dom';
import { selectAccessToken } from '../../redux/slices/authSlice';
import { useAppSelector } from '../../redux/store';

const Public = () => {
  const token = useAppSelector(selectAccessToken);

  return token ? <Navigate to='/analytics' /> : <Navigate to='/login' />;
};

export default Public;
