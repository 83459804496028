import React from 'react';

const PositionDownSVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='17'
      viewBox='0 0 11 17'
      fill='none'
    >
      <path
        d='M5.2515 0L5.1497 0.00684977C4.7836 0.0565098 4.5015 0.3703 4.5015 0.75L4.5006 13.442L1.2807 10.2244L1.1966 10.1518C0.902898 9.9341 0.486198 9.9585 0.219998 10.2248C-0.0727019 10.5179 -0.0725022 10.9927 0.220498 11.2855L4.7243 15.7855L4.8084 15.8581C5.1021 16.0758 5.5188 16.0514 5.7849 15.7851L10.2812 11.2851L10.3537 11.2009C10.5715 10.9072 10.5471 10.4905 10.2807 10.2244L10.1966 10.1518C9.9029 9.9341 9.4862 9.9585 9.22 10.2248L6.0006 13.446L6.0015 0.75L5.9946 0.64823C5.945 0.28215 5.6312 0 5.2515 0Z'
        fill='#D32F2F'
      />
    </svg>
  );
};

export default PositionDownSVG;
