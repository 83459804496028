import {Box, Dialog, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import React from 'react';
import MainContainer from '../../../../../components/MainContainer/MainContainer';
import {
  useGetShopClusterCategoriesInfoQuery,
  useGetShopClusterCategoryLiftsQuery,
} from '../../../../../redux/api/productApi/shop';
import MarketChart from '../MarketChart/MarketChart';
import MarketLifts from '../MarketLifts/MarketLifts';
import ClusterStatTable from '../StatiscticsTable/ClusterStatTable';

interface Props {
  cluster?: ShopClusterType;
  onClose: () => void;
}

const MarketInfo = ({cluster, onClose}: Props) => {

  const {data: categoryData} = useGetShopClusterCategoriesInfoQuery({id: cluster?.id}, {skip: !cluster?.id});
  const categoriesInfo = (categoryData as unknown as ShopClusterCategoryInfoType[]);

  const {data: categoryLiftResponse} = useGetShopClusterCategoryLiftsQuery(
    {categories: categoriesInfo?.map((cat) => cat.category_id) || []},
    {skip: !categoriesInfo?.length},
  );
  const categoryLifts = (categoryLiftResponse as unknown as CategoryLiftType[]);

  return cluster && (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={!!cluster}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <MainContainer title="Профиль магазина">
        <Stack direction="row" spacing={5}>
          <Box minWidth="45%" p={2} sx={{border: 1, borderColor: grey[300]}}>
            <MarketChart data={categoriesInfo}/>
          </Box>
          <Box minWidth="40%" maxWidth="45%" pt={2}>
            <ClusterStatTable id={cluster?.id}/>
          </Box>
        </Stack>
        <Typography sx={{my: 5}}>
          Лифты категорий
        </Typography>
        <MarketLifts lifts={categoryLifts} />
      </MainContainer>
    </Dialog>
  ) || <></>;
};

export default MarketInfo;
