import React, { useEffect, useRef } from 'react';
import { select, scaleBand, scaleLinear, max, axisBottom, axisLeft } from 'd3';

const data: Bar[] = [
  { date: '2021-01-01', value: 100 },
  { date: '2021-01-02', value: 200 },
  { date: '2021-01-03', value: 180 },
  { date: '2021-01-04', value: 220 },
  { date: '2021-01-05', value: 150 },
  { date: '2021-01-06', value: 250 },
  { date: '2021-01-07', value: 270 },
  { date: '2021-01-08', value: 190 },
  { date: '2021-01-09', value: 230 },
  { date: '2021-01-10', value: 250 },
];

const defaultColor = '#d497d1';

type Bar = {
  date: string;
  value: number;
};

export default function BarChartSales({
  color = defaultColor,
}: {
  color?: string;
}) {
  const ref = useRef<any>(null);

  useEffect(() => {
    const svg = select(ref.current);
    svg.selectAll('*').remove();
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const width = 960 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    const x = scaleBand().range([0, width]).padding(0.1);
    const y = scaleLinear().range([height, 0]);

    const g = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    x.domain(data.map((d: Bar) => d.date));
    y.domain([0, max(data, (d: Bar) => d.value)!]);

    g.append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(axisBottom(x));

    g.append('g').call(axisLeft(y));

    g.selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d: Bar) => x(d.date)!)
      .attr('y', (d: Bar) => y(d.value))
      .attr('width', x.bandwidth())
      .attr('height', (d: Bar) => height - y(d.value))
      .attr('fill', color);
  }, [data]);

  return <svg ref={ref} viewBox='0 0 960 500' />;
}
