import { Response } from '../../../common/types';
import { AuthStateType } from '../../slices/authSlice/types';
import { apiSlice } from '../apiSlice';
import { AuthBody } from './types';

export const authApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<Response<AuthStateType>, AuthBody>({
      query: (body: AuthBody) => ({
        url: '/authservice/login',
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation<Response<void>, void>({
      query: () => ({
        url: '/authservice/logout',
        method: 'POST',
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
