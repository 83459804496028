import {Response} from '../../../common/types';
import {apiSlice} from '../apiSlice';

export const {
  useGetHeatmapsQuery,
} = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getHeatmaps: builder.query<Response<any>, any>({
      query: (body: {tradePointId: number}) => ({
        url: '/clientservice/trade_point_heatmap/list',
        method: 'POST',
        body,
      }),
    }),
    getShelf: builder.query<Response<any>, any>({
      query: ({id}: {id: number}) => ({url: `/clientservice/shelf/${id}`}),
    }),
    getSubShelves: builder.query<Response<any>, any>({
      query: ({id}: {id: number}) => ({
        url: `/clientservice/shelf/${id}/sub_shelfs`,
      }),
    }),
    createShelf: builder.mutation<Response<any>, any>({
      query: (body: {
        name?: string;
        maxHorizontalSpace?: number;
        tradePointId?: number;
      }) => ({
        url: '/clientservice/shelf',
        method: 'POST',
        body,
      }),
    }),
    createSubShelf: builder.mutation<Response<any>, any>({
      query: (body: FormData) => ({
        url: '/clientservice/sub_shelf',
        method: 'POST',
        body,
      }),
    }),
    uploadShelfImage: builder.mutation<Response<any>, any>({
      query: ({id, body}: {id: number, body: FormData}) => ({
        url: `/clientservice/shelf/${id}/shelf_image`,
        method: 'POST',
        body,
      }),
    }),
    deleteShelfImage: builder.mutation<Response<any>, any>({
      query: ({id}: {id: number}) => ({
        url: `/clientservice/shelf/${id}/shelf_image`,
        method: 'DELETE'
      }),
    }),
    updateShelf: builder.mutation<Response<any>, any>({
      query: ({id, body}: {
        id: number;
        body: {
          name: string;
          maxHorizontalSpace: number;
          tradePointId: number;
        };
      }) => ({
        url: `/clientservice/shelf/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteShelf: builder.mutation<Response<any>, any>({
      query: ({id}: {id: number}) => ({
        url: `/clientservice/shelf/${id}`,
        method: 'DELETE'
      }),
    }),
    deleteSubShelf: builder.mutation<Response<any>, any>({
      query: ({id}: {id: number}) => ({
        url: `/clientservice/sub_shelf/${id}`,
        method: 'DELETE'
      }),
    }),
  }),
});
