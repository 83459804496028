import {Button, Stack} from '@mui/material';
import React, {useState} from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import {useGetShelvesQuery} from '../../../redux/api/clientApi/shelves';
import Shop from '../components/Shop/Shop';
import EmptyShelvesTable from './components/EmptyShelvesTable/EmptyShelvesTable';

const EmptyShelve = () => {
  const [shop, setShop] = useState<any>();

  const {data} = useGetShelvesQuery(
    {limit: 10, offset: 0, tradePointId: shop?.id},
    {skip: !shop?.id},
  );

  return (
    <>
      <MainContainer title="Пустые полки">
        <Stack direction="row" spacing={3}>
          <Shop onChange={setShop}/>
        </Stack>
        {!!shop && <EmptyShelvesTable list={data?.body} />}
      </MainContainer>
    </>
  );
};

export default EmptyShelve;
