import { ProductCategory } from '../../../../../common/types';

export const createDataTree = (
  categories: ProductCategory[]
): ProductCategory[] => {
  const hashTable = Object.create(null);
  categories.forEach(data => {
    hashTable[data.categoryId] = { ...data, categories: [] };
  });
  const dataTree: ProductCategory[] = [];
  categories.forEach(data => {
    if (data.parentCategoryId) {
      if (hashTable[data.parentCategoryId]) {
        hashTable[data.parentCategoryId].categories.push(
          hashTable[data.categoryId]
        );
      } else {
        console.error('not found parentCategoryId', data.parentCategoryId);
      }
    } else {
      dataTree.push(hashTable[data.categoryId]);
    }
  });
  return dataTree;
};
