import React from 'react';

export const Unchecked = () => {
  return (
    // <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect x="0.5" y="0.5" width="13" height="13" stroke="currentColor" />
    // </svg>

    // <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect x="0.5" y="0.5" width="12" height="12" stroke="currentColor" />
    // </svg>

    // <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: '14px', height: 'auto'}}>
    //   <rect x="0.5" y="0.5" width="14" height="14" stroke="currentColor" />
    // </svg>

    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: '14px', height: 'auto' }}
    >
      <rect
        x='0.5'
        y='0.5'
        width='13'
        height='13'
        stroke='currentColor'
        style={{ width: '13px', height: '13px' }}
      />
    </svg>
  );
};
