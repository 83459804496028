import React, { useRef, useEffect } from 'react';
import { select, scaleLinear, line, max, min, axisBottom, axisLeft } from 'd3';

type DataPoint = {
  price: number;
  count: number;
};

type LineChartProps = {
  data: DataPoint[];
};

// const data: DataPoint[] = [
//     {price: 100, count: 120},
//     {price: 120, count: 180},
//     {price: 180, count: 250},
//     {price: 220, count: 230},
//     {price: 150, count: 290},
//     {price: 250, count: 200},
//     {price: 270, count: 210},
//     {price: 190, count: 260},
//     {price: 230, count: 210},
//     {price: 250, count: 290},
// ];

const data: DataPoint[] = [
  { price: 100, count: 120 },
  { price: 120, count: 180 },
  { price: 130, count: 250 },
  { price: 140, count: 230 },
  { price: 150, count: 290 },
  { price: 160, count: 200 },
  { price: 170, count: 210 },
  { price: 180, count: 260 },
  { price: 190, count: 210 },
  { price: 200, count: 290 },
];

const LineChart = () => {
  // const LineChart: React.FC<LineChartProps> = ({data}) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const svg = select(ref.current);
    svg.selectAll('*').remove();

    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const width = 960 - margin.left - margin.right;
    const height = 200 - margin.top - margin.bottom;

    const x = scaleLinear().range([0, width]);
    const y = scaleLinear().range([height, 0]);

    const lineGenerator = line<DataPoint>()
      .x(d => x(d.price)!)
      .y(d => y(d.count)!);

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // x.domain([0, max(data, d => d.price)!]);
    x.domain([min(data, d => d.price)!, max(data, d => d.price)!]);
    y.domain([0, max(data, d => d.count)!]);

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(axisBottom(x));

    g.append('g').call(axisLeft(y));

    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1.5)
      .attr('d', lineGenerator);
  }, [data]);

  return <svg ref={ref} viewBox='0 0 960 200' />;
};

export default LineChart;
