import {Box, Tabs, Tab, Typography, Button} from '@mui/material';
import React, {useState} from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import CategoriesTable from './CategoriesTable';
import ProductsTable from './ProductsTable';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const ExcludedGoods = () => {
  const [dialog, setDialog] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainContainer title="Исключенные товары">
      <Box sx={{borderBottom: 1, borderColor: 'divider'}} style={{marginTop: '10px'}}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Исключенные товары" {...a11yProps(0)} />
          <Tab label="Исключенные категории" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography><strong>Исключенные товары</strong></Typography>
        <Button
          sx={{mt: '15px'}}
          onClick={() => setDialog(true)}
        >
          Исключить товар
        </Button>
        <ProductsTable close={() => setDialog(false)} dialog={dialog}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography><strong>Исключенные категории</strong></Typography>
        <Button
          sx={{mt: '15px'}}
          onClick={() => setDialog(true)}
        >
          Исключить категорию
        </Button>
        <CategoriesTable close={() => setDialog(false)} dialog={dialog}/>
      </TabPanel>

    </MainContainer>
  );
};

export default ExcludedGoods;
