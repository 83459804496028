import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';

import styles from './Sorting.module.scss';

const ASC = 'asc';
const DESC = 'desc';

const sortingOptions: { label: string; dir: string }[] = [
  { label: 'По порядку иерархии', dir: ASC },
  { label: 'По убыванию проданного количества штук', dir: DESC },
  { label: 'По возрастанию проданного количества штук', dir: ASC },
  { label: 'По убыванию выручки', dir: DESC },
  { label: 'По возрастанию выручки', dir: ASC },
  { label: 'По убыванию маржи', dir: DESC },
  { label: 'По возрастанию маржи', dir: ASC },
];

export default function Sorting() {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState<number>();
  return (
    <>
      <IconButton
        aria-label='sort'
        size='small'
        onClick={() => setOpen(!open)}
        className={styles.wrapper}
      >
        <SwapVertIcon fontSize='inherit' />
        {open && (
          <div className={styles.container}>
            {sortingOptions.map((option, index) => (
              <div
                key={index}
                className={`${styles.item} ${
                  (current === index && styles.active) || ''
                }`}
                onClick={() => {
                  setCurrent(index);
                  setOpen(false);
                }}
              >
                {(option.dir === DESC && <span>▼</span>) || <span>▲</span>}
                {option.label}
              </div>
            ))}
          </div>
        )}
      </IconButton>
    </>
  );
}
