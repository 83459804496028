import React from 'react';

type TableProps = {
  data: any;
  update: (id: any) => void;
};

const levelClass: any = {
  1: 'l1',
  2: 'l2',
  3: 'l3',
  4: 'l4',
  5: 'l5',
  6: 'l6',
};

const productClass = 'p';

export default function Table({ data, update }: TableProps) {
  return (
    (data?.list?.length && (
      <>
        <tr>
          <td></td>

          <td>Количество</td>
          <td>Доля количества</td>
          <td>Выручка</td>
          <td>Доля выручки</td>
          <td>Маржа</td>
          <td>Доля маржи</td>

          <td>Количество</td>
          <td>Доля количества</td>
          <td>Выручка</td>
          <td>Доля выручки</td>
          <td>Маржа</td>
          <td>Доля маржи</td>

          <td>Количество</td>
          <td>Доля количества</td>
          <td>Выручка</td>
          <td>Доля выручки</td>
          <td>Маржа</td>
          <td>Доля маржи</td>

          <td>Количество</td>
          <td>Доля количества</td>
          <td>Выручка</td>
          <td>Доля выручки</td>
          <td>Маржа</td>
          <td>Доля маржи</td>
        </tr>
        {data.list.map((cat: any) => {
          return (
            <tr
              key={cat.value}
              className={levelClass[cat.level] || productClass}
            >
              <td
                onClick={() => update(cat.value)}
                style={{
                  paddingLeft: cat.padding + 'px',
                  cursor: cat.children?.length && 'pointer',
                }}
              >
                {cat.label}
              </td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>

              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>

              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>

              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>
            </tr>
          );
        })}
      </>
    )) ||
    null
  );
}
