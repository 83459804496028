import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import getMiddleware from './middlewares';
import reducers from './reducers';

export const store = configureStore({
  reducer: reducers,
  middleware: getMiddleware,
});

const reducersForType = combineReducers(reducers);
export type RootState = ReturnType<typeof reducersForType>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
