import React, { useCallback, useEffect, useRef, useState } from 'react';
import MainContainer from '../../components/MainContainer/MainContainer';
import CheckboxTree from 'react-checkbox-tree';
import { useGetTradePointListQuery } from '../../redux/api/clientApi';
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Unchecked } from './icons/Unchecked';
import { Checked } from './icons/Checked';
import { Collapsed } from './icons/Collapsed';
import { Expanded } from './icons/Expanded';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Controls from './components/Controls';

import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './styles.scss';
import TradePointsTable from './components/TradePointsTable';
import {
  useFetchProductListMutation,
  useGetProductCategoriesQuery,
} from '../../redux/api/productApi';
import { useAppSelector } from '../../redux/store';
import { selectProductCategoriesTree } from '../../redux/slices/productSlice';

const createCategoryTree = (data: any, map: any, level = 1) => {
  return data.map((item: any) => {
    const category: any = {
      // ...item,
      value: item.id.toString(),
      label: item.name,
      level,
    };
    map[item.id] = category;
    if (item.categories?.length) {
      category.children = createCategoryTree(item.categories, map, level + 1);
    }
    return category;
  });
};

const getCategoryTree = (data: any, map: any = {}) => {
  return { list: createCategoryTree(data, map), map };
};

const createShopsTree = (data: any, map: any, level = 1) => {
  return [
    {
      value: 'shops',
      label: 'Все',
      showCheckbox: false,
      children: data.map((item: any) => {
        const shop: any = {
          value: item.id.toString(),
          label: item.name,
          level,
        };
        map[item.id] = shop;
        return shop;
      }),
    },
  ];
};

const getShopsTree = (data: any, map: any = {}) => {
  return { list: createShopsTree(data, map), map };
};

const getRandomDif = () => {
  return Math.floor(Math.random() * 21) - 10;
};

const getRandomPercent = () => {
  return Math.floor(Math.random() * 31) + 30;
};

const Bar = ({
  color,
  showDiff = false,
}: {
  color: string;
  showDiff?: boolean;
}) => {
  const diff = getRandomDif();
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: `${getRandomPercent()}%`,
          height: '15px',
          backgroundColor: color,
        }}
      />
      {showDiff && (
        <div style={{ color: diff > 0 ? '#0fce80' : '#b43434' }}>{diff}%</div>
      )}
    </div>
  );
};

const BAR = 1;
const TABLE = 2;

const TradePointsSales = () => {
  const [shopsState, setShopsState] = useState<Record<string, string[]>>({
    checked: [],
    expanded: [],
  });
  const [shopsTree, setShopsTree] = useState<{
    list: any[];
    map: any;
  }>({ list: [], map: {} });
  const [tableShops, setTableShops] = useState<{
    list: any[];
    map: any;
  }>({ list: [], map: {} });
  const [viewType, setViewType] = useState(BAR);

  const [analysis, setAnalysis] = useState<{ start?: any; end?: any }>({});
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [comparison, setComparison] = useState<{ start?: any; end?: any }>({});
  const [showComparison, setShowComparison] = useState(false);
  const [tableWidth, setTableWidth] = useState(0);
  const [thWidth, setThWidth] = useState(0);

  const tableRef = useRef<HTMLTableElement | null>(null);
  const thRef = useRef<HTMLTableHeaderCellElement | null>(null);

  useEffect(() => {
    if (tableRef.current && !tableWidth) {
      setTableWidth(tableRef.current.clientWidth);
    }
  }, [tableWidth]);

  useEffect(() => {
    if (thRef.current && !thWidth) {
      setThWidth(thRef.current.clientWidth);
    }
  }, [thWidth]);

  useEffect(() => {
    if (!analysis.start || !analysis.end) {
      setShowAnalysis(false);
    }
  }, [analysis]);

  useEffect(() => {
    if (!comparison.start || !comparison.end) {
      setShowComparison(false);
    }
  }, [comparison]);

  const { data: tradePoints, isError } = useGetTradePointListQuery({
    limit: 100,
    offset: 0,
    clientId: 2,
  });

  useEffect(() => {
    if (tradePoints?.body?.length) {
      setShopsTree(getShopsTree(tradePoints.body));
    }
  }, [tradePoints?.body]);

  const updateShopsTree = (checked: any, node: any) => {
    if (checked?.length) {
      if (shopsTree && shopsTree.map[node.value]) {
        setShopsTree({ ...shopsTree });
      }
    }
  };

  const TradePointsTree = useCallback(() => {
    return (
      <CheckboxTree
        nodes={shopsTree.list}
        checked={shopsState.checked}
        expanded={shopsState.expanded}
        onCheck={(checked, node) => {
          updateShopsTree(checked, node);
          setShopsState(prev => ({ ...prev, checked }));
        }}
        onExpand={expanded => setShopsState(prev => ({ ...prev, expanded }))}
        noCascade
        icons={{
          check: <Checked />,
          uncheck: <Unchecked />,
          expandOpen: <Expanded />,
          expandClose: <Collapsed />,
        }}
      />
    );
  }, [shopsTree, shopsState]);

  const [categoriesTree, setCategoriesTree] = useState<{
    list: any[];
    map: any;
  }>({ list: [], map: {} });
  const [categoriesState, setCategoriesState] = useState<
    Record<string, string[]>
  >({
    checked: [],
    expanded: [],
  });
  const [tableCategories, setTableCategories] = useState<{
    list: any[];
    map: any;
  }>({ list: [], map: {} });
  const [product, setProduct] = useState<any>();

  useGetProductCategoriesQuery({
    limit: 100000,
    offset: 0,
    clientId: 2,
  });

  const productCategoriesTree = useAppSelector(selectProductCategoriesTree);

  useEffect(() => {
    if ((productCategoriesTree || []).length) {
      setCategoriesTree(getCategoryTree(productCategoriesTree));
    }
  }, [productCategoriesTree]);

  const updateCategoriesTree = (checked: any, node: any) => {
    if (checked?.length) {
      if (categoriesTree && categoriesTree.map[node.value]) {
        updateCheckableCategories(categoriesTree.list, node.treeDepth + 1);
        setCategoriesTree({ ...categoriesTree });
      }
    } else {
      updateCheckableCategories(categoriesTree.list);
    }
  };

  const [getProductsByCategory] = useFetchProductListMutation();

  const getProductsByCategoryId = (id: string, updateTable = false) => {
    const category = categoriesTree.map[id];
    if (category && !category.children) {
      if (category.pid) {
        setProduct(category);
        return;
      }
      categoriesTree.map[id].children = [];
      getProductsByCategory({
        limit: 1000,
        offset: 0,
        categoryId: +id,
      }).then((res: any) => {
        if (res?.data?.body?.length) {
          categoriesTree.map[id].children = res.data.body.map((item: any) => {
            const value = id + '_' + item.id.toString();
            const category = {
              ...item,
              value,
              label: item.name,
              showCheckbox: false,
              pid: item.id,
            };
            categoriesTree.map[value] = category;
            return category;
          });
          setCategoriesTree({ ...categoriesTree });
          setCategoriesState(prev => ({
            ...prev,
            expanded: [...prev.expanded, id],
          }));
          if (updateTable) {
            setCheckedTableCategories();
          }
        }
      });
    }
  };

  const updateCheckableCategories = (tree: any[], level?: number) => {
    tree.forEach((node: any) => {
      if (level === undefined) {
        node.disabled = false;
      } else {
        node.disabled = node.level !== level;
      }
      if (node.children) {
        updateCheckableCategories(node.children, level);
      }
    });
  };

  const clearCheckableCategories = (tree: any[]) => {
    tree.forEach((node: any) => {
      node.disabled = false;
      if (node.children) {
        clearCheckableCategories(node.children);
      }
    });
  };

  const setCheckedTableCategories = () => {
    const prev = tableCategories;
    const list: any[] = [];
    const map: any = {};
    const addCategory = (id: any, padding = 10) => {
      if (id) {
        let category = prev.map[id] || categoriesTree.map[id];
        if (category) {
          category.padding = padding;
          // category = { ...category, padding };
          list.push(category);
          map[id] = category;
          if (category.expanded && category.children) {
            category.children.forEach((child: any) => {
              addCategory(child.value, padding + 20);
            });
          }
        }
      }
    };
    categoriesState?.checked?.forEach((id: string) => {
      addCategory(id);
    });
    setTableCategories({ list, map });
  };

  useEffect(() => {
    setCheckedTableCategories();
  }, [categoriesTree, categoriesState?.checked]);

  const CategoryTreeCheckboxes = useCallback(() => {
    return (
      <CheckboxTree
        nodes={categoriesTree.list}
        checked={categoriesState.checked}
        expanded={categoriesState.expanded}
        onCheck={(checked, node) => {
          updateCategoriesTree(checked, node);
          setCategoriesState(prev => ({ ...prev, checked }));
        }}
        onExpand={expanded =>
          setCategoriesState(prev => ({ ...prev, expanded }))
        }
        noCascade
        expandOnClick
        onClick={cat => {
          getProductsByCategoryId(cat.value);
        }}
        icons={{
          check: <Checked />,
          uncheck: <Unchecked />,
          expandOpen: <Expanded />,
          expandClose: <Collapsed />,
        }}
      />
    );
  }, [categoriesTree, categoriesState]);

  const setCheckedTableShops = () => {
    const prev = tableShops;
    const list: any[] = [];
    const map: any = {};
    const addCategory = (id: any, padding = 10) => {
      if (id) {
        let category = prev.map[id] || shopsTree.map[id];
        if (category) {
          category.padding = padding;
          list.push(category);
          map[id] = category;
        }
      }
    };
    shopsState?.checked?.forEach((id: string) => {
      addCategory(id);
    });
    setTableShops({ list, map });
  };

  useEffect(() => {
    setCheckedTableShops();
  }, [shopsTree, shopsState?.checked]);

  const width = '150px';

  return (
    <MainContainer title='Анализ продаж' maxWidth={false}>
      <div className='main-grid trade-points'>
        <div>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ fontSize: 20, marginY: 1 }}
          >
            <Typography sx={{ fontSize: 20, marginY: 1 }}>Категории</Typography>
            {categoriesState.checked.length > 0 && (
              <Button
                variant='text'
                onClick={() => {
                  clearCheckableCategories(categoriesTree.list);
                  setCategoriesState(prev => ({
                    expanded: prev.expanded,
                    checked: [],
                  }));
                }}
                size='small'
              >
                Очистить
              </Button>
            )}
          </Stack>
          <CategoryTreeCheckboxes />

          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ fontSize: 20, marginY: 1 }}
          >
            <Typography sx={{ fontSize: 20, marginY: 1 }}>
              Торговые точки
            </Typography>
            {shopsState.checked.length > 0 && (
              <Button
                variant='text'
                onClick={() => {
                  setShopsState(prev => ({
                    expanded: prev.expanded,
                    checked: [],
                  }));
                }}
                size='small'
              >
                Очистить
              </Button>
            )}
          </Stack>
          <TradePointsTree />
        </div>
        <div>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>
                  <IconButton
                    onClick={() => {
                      setViewType(BAR);
                    }}
                    aria-label='Столбчатые диаграммы'
                    size='small'
                    color={viewType === BAR ? 'primary' : 'default'}
                    title='Столбчатые диаграммы'
                  >
                    <BarChartIcon fontSize='inherit' />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setViewType(TABLE);
                    }}
                    aria-label='Табличные данные'
                    size='small'
                    color={viewType === TABLE ? 'primary' : 'default'}
                    title='Табличные данные'
                  >
                    <TableChartIcon fontSize='inherit' />
                  </IconButton>
                </th>
                <th colSpan={12}>
                  <Stack
                    spacing={1}
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Typography>Период анализа</Typography>
                    <DesktopDatePicker
                      label='Период с'
                      inputFormat='DD.MM.YYYY'
                      value={analysis.start || ''}
                      onChange={value =>
                        setAnalysis(prev => ({ ...prev, start: value }))
                      }
                      renderInput={params => (
                        <TextField {...params} size='small' sx={{ width }} />
                      )}
                    />
                    <DesktopDatePicker
                      minDate={analysis.start || ''}
                      label='Период по'
                      inputFormat='DD.MM.YYYY'
                      value={analysis.end || ''}
                      onChange={value =>
                        setAnalysis(prev => ({ ...prev, end: value }))
                      }
                      renderInput={params => (
                        <TextField {...params} size='small' sx={{ width }} />
                      )}
                    />
                    <Button
                      variant='outlined'
                      onClick={() => {
                        analysis.start && analysis.end && setShowAnalysis(true);
                      }}
                      size='large'
                    >
                      Применить
                    </Button>
                  </Stack>
                </th>
                <th colSpan={12}>
                  <Stack
                    spacing={1}
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Typography>Период сопоставления</Typography>
                    <DesktopDatePicker
                      label='Период с'
                      inputFormat='DD.MM.YYYY'
                      value={comparison.start || ''}
                      onChange={value =>
                        setComparison(prev => ({ ...prev, start: value }))
                      }
                      renderInput={params => (
                        <TextField {...params} size='small' sx={{ width }} />
                      )}
                    />
                    <DesktopDatePicker
                      minDate={comparison.start || ''}
                      label='Период по'
                      inputFormat='DD.MM.YYYY'
                      value={comparison.end || ''}
                      onChange={value =>
                        setComparison(prev => ({ ...prev, end: value }))
                      }
                      renderInput={params => (
                        <TextField {...params} size='small' sx={{ width }} />
                      )}
                    />
                    <Button
                      variant='outlined'
                      onClick={() => {
                        comparison.start &&
                          comparison.end &&
                          setShowComparison(true);
                      }}
                      size='large'
                    >
                      Применить
                    </Button>
                  </Stack>
                </th>
              </tr>
              <tr className='header'>
                <th style={{ whiteSpace: 'nowrap' }}>Торговые точки</th>
                <th colSpan={6} ref={thRef}>
                  <Controls />
                </th>
                <th colSpan={6}>
                  <Controls isActual />
                </th>
                <th colSpan={6}>
                  <Controls />
                </th>
                <th colSpan={6}>
                  <Controls isActual />
                </th>
              </tr>
            </thead>
            <tbody>
              {viewType === TABLE && (
                <TradePointsTable
                  data={tableShops}
                  checkedCategories={categoriesState.checked}
                  categoriesTree={categoriesTree}
                  width={thWidth}
                />
              )}
              {viewType === BAR &&
                tableShops.list.map((cat: any) => {
                  return (
                    <tr key={cat.value}>
                      <td style={{ paddingLeft: cat.padding + 'px' }}>
                        {cat.label}
                      </td>
                      <td colSpan={6}>
                        {showAnalysis && (
                          <>
                            <Bar color='#788cff' />
                            <Bar color='#b2ffd9' />
                            <Bar color='#ff9ba5' />
                          </>
                        )}
                      </td>
                      <td colSpan={6}>
                        {showAnalysis && (
                          <>
                            <Bar color='#788cff' />
                            <Bar color='#b2ffd9' />
                            <Bar color='#ff9ba5' />
                          </>
                        )}
                      </td>
                      <td colSpan={6}>
                        {showComparison && (
                          <>
                            <Bar color='#788cff' />
                            <Bar color='#b2ffd9' />
                            <Bar color='#ff9ba5' />
                          </>
                        )}
                      </td>
                      <td colSpan={6}>
                        {showComparison && (
                          <>
                            <Bar color='#788cff' />
                            <Bar color='#b2ffd9' />
                            <Bar color='#ff9ba5' />
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </MainContainer>
  );
};

export default TradePointsSales;
