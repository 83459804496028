import React from 'react';
import { Button, ButtonGroup } from '@mui/material';

interface Props {
  data: { value: string | number; name: string }[];
  type: string | number;
  onChange: (type: string | number) => void;
}

const Types = ({ data, type, onChange }: Props) => {
  return (
    <ButtonGroup
      variant='outlined'
      aria-label='outlined button group'
      size='large'
      sx={{
        height: 56,
      }}
    >
      {data.map(item => (
        <Button
          key={item.value}
          sx={{ color: type === item.value ? '#295EF8' : '#000000' }}
          onClick={() => onChange(item.value)}
        >
          {item.name}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default Types;
