import React from 'react';

const TrendDownSVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
    >
      <path
        d='M0.713548 0.711684L0.646408 0.788511C0.422651 1.0825 0.44506 1.50386 0.713548 1.77234L9.68751 10.7476L5.13551 10.7492L5.02471 10.7573C4.66309 10.8111 4.3857 11.123 4.38577 11.4995C4.38605 11.9137 4.72192 12.2493 5.13615 12.2492L11.5028 12.2465L11.6136 12.2384C11.9752 12.1846 12.2526 11.8727 12.2525 11.4963L12.2499 5.13492L12.2416 5.02412C12.1879 4.66243 11.876 4.38503 11.4995 4.38524L11.3887 4.39338C11.0271 4.44712 10.7497 4.75902 10.7497 5.13556L10.751 9.68975L1.77421 0.711684L1.69737 0.644602C1.40344 0.420817 0.982037 0.443195 0.713548 0.711684Z'
        fill='#D32F2F'
      />
    </svg>
  );
};

export default TrendDownSVG;
