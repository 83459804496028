import {Button, Stack} from '@mui/material';
import React, {useState} from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import {useGetShelvesQuery} from '../../../redux/api/clientApi/shelves';
import Shop from '../../Reports/components/Shop/Shop';
import {NewShelfDialog} from './components/shelf/NewShelfDialog';
import {Shelf} from './components/shelf/Shelf';
import {ShelvesTable} from './components/table/ShelvesTable';

export const ShelfManager = () => {
  const [shop, setShop] = useState<any>();
  const [newShelf, setNewShelf] = useState<Partial<ShelfType>>();
  const [shelfId, setShelfId] = useState(0);

  const {data, refetch} = useGetShelvesQuery(
    {limit: 10, offset: 0, tradePointId: shop?.id},
    {skip: !shop?.id},
  );

  return (
    <>
      <MainContainer title="Управление полками">
        <Stack direction="row" spacing={3}>
          <Shop onChange={setShop}/>
          <Button
            variant="outlined"
            size="large"
            sx={{mt: '15px', ml: '15px', height: 56}}
            onClick={() => setNewShelf({tradePointId: shop?.id})}
            disabled={!shop}
          >
            Добавить полку
          </Button>
        </Stack>
        {!!shop && <ShelvesTable list={data?.body} edit={setShelfId} refetch={refetch} />}
      </MainContainer>
      <Shelf
        id={shelfId}
        open={!!shelfId}
        onClose={() => setShelfId(0)}
      />
      <NewShelfDialog shelf={newShelf} onClose={() => setNewShelf(undefined)} refetch={refetch} shop={shop}/>
    </>
  );
};
