import React from 'react';

const PositionUpSVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='17'
      viewBox='0 0 11 17'
      fill='none'
    >
      <path
        d='M5.24972 16.0049L5.35152 15.9981C5.71762 15.9484 5.99972 15.6346 5.99972 15.2549L6.00062 2.56294L9.22052 5.78054L9.30462 5.85314C9.59832 6.07084 10.015 6.04644 10.2812 5.78014C10.5739 5.48704 10.5737 5.01224 10.2807 4.71944L5.77692 0.219444L5.69282 0.146843C5.39912 -0.070857 4.98242 -0.0464571 4.71632 0.219843L0.220024 4.71984L0.147524 4.80404C-0.0702761 5.09774 -0.0458762 5.51444 0.220524 5.78054L0.304625 5.85314C0.598325 6.07084 1.01502 6.04644 1.28122 5.78014L4.50062 2.55894L4.49972 15.2549L4.50662 15.3567C4.55622 15.7228 4.87002 16.0049 5.24972 16.0049Z'
        fill='#2DD752'
      />
    </svg>
  );
};

export default PositionUpSVG;
