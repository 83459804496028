import { Response } from '../../../common/types';
import { apiSlice } from '../apiSlice';

export const {
  useGetProductAttributeListQuery,
  useUpdateProductAttributeListMutation,
} = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getProductAttributeList: builder.query<Response<any>, any>({
      query: () => ({
        url: '/productservice/product_attribute/list',
      }),
    }),
    updateProductAttributeList: builder.mutation<Response<any>, any>({
      query: (body: ProductAttributeType[]) => ({
        url: '/productservice/product_attribute/list',
        method: 'PUT',
        body,
      }),
    }),
  }),
});
