import { Response } from '../../../common/types';
import { apiSlice } from '../apiSlice';

export const {
  useGetCategoriesQuery,
  useGetCategoryClustersQuery,
  useGetClusterProductsQuery,
  useGetNonClusterProductsQuery,
  useGetCalcOneCategoryMutation,
  useFetchCategoryClustersMutation,
} = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCategories: builder.query<Response<any>, any>({
      query: () => ({
        url: '/clusterservice/assort_categories?id_client=2',
      }),
    }),
    getCategoryClusters: builder.query<Response<any>, any>({
      query: ({ id }: { id: number }) => ({
        url: `/clusterservice/assort_category_clusters?id_category=${id}`,
      }),
    }),
    getClusterProducts: builder.query<Response<any>, any>({
      query: ({ id }: { id: number }) => ({
        url: `/clusterservice/assort_cluster_card?id_cluster=${id}`,
      }),
    }),
    // getCalcOneCategory: builder.query<Response<any>, any>({
    //   query: ({ id }: { id: number }) => ({
    //     url: `/clusterservice/calc_one_category?id_category=${id}`,
    //   }),
    // }),
    fetchCategoryClusters: builder.mutation<Response<any>, any>({
      query: (id: number) => ({
        url: `/clusterservice/assort_category_clusters?id_category=${id}`,
        method: 'GET',
      }),
    }),
    getCalcOneCategory: builder.mutation<Response<any>, any>({
      query: (id: number) => ({
        url: `/clusterservice/calc_one_category?id_category=${id}`,
        method: 'GET',
      }),
    }),
    getNonClusterProducts: builder.query<Response<any>, any>({
      query: ({ id }: { id: number }) => ({
        url: `/clusterservice/assort_category_clusters_goods_not_in_cluster?id_category=${id}`,
      }),
    }),
  }),
});
