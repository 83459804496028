import { DeleteOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import {
  useExcludeProductByIdQuery,
  useGetExcludedProductListQuery,
  useGetProductByIdQuery,
  useRemoveProductFromExcludedByIdQuery,
} from '../../../redux/api/productApi';
import {
  getTableHeaderAlign,
  headerCellSX,
} from '../../../utils/helpers/table';

const headers = ['ID', 'Название', 'Удалить'];

const ProductsTable = ({
  close,
  dialog,
}: {
  close: () => void;
  dialog: boolean;
}) => {
  const [product, setProduct] = useState<any>();
  const [productId, setProductId] = useState<any>();
  const [productIdToExclude, setProductIdToExclude] = useState<any>();
  const [error, setError] = useState('');
  const [fieldValue, setFieldValue] = useState('');
  const [removeFromExcluded, setRemoveFromExcluded] = useState<any>();
  const [removingId, setRemovingId] = useState<any>();

  const { data, refetch } = useGetExcludedProductListQuery({
    limit: 1000,
    offset: 0,
  });
  const { data: loadedProduct, isError: productError } = useGetProductByIdQuery(
    productId,
    { skip: typeof productId !== 'number' }
  );

  const { data: excludeResult, isError: excludingError } =
    useExcludeProductByIdQuery(
      { clientId: 2, productId: productIdToExclude },
      { skip: typeof productIdToExclude !== 'number' }
    );

  const { data: removeResult, isError: removingError } =
    useRemoveProductFromExcludedByIdQuery(
      { productId: removingId },
      { skip: typeof removingId !== 'number' }
    );

  useEffect(() => {
    // @ts-ignore
    if (excludeResult?.msg === 'ok') {
      setProduct(undefined);
      setProductId(undefined);
      setProductIdToExclude(undefined);
      setFieldValue('');
      refetch();
      close();
    }
  }, [excludeResult]);

  useEffect(() => {
    // @ts-ignore
    if (removeResult?.msg === 'ok') {
      setRemovingId(undefined);
      setRemoveFromExcluded(undefined);
      refetch();
    }
  }, [removeResult]);

  useEffect(() => {
    if (loadedProduct) {
      setProduct(loadedProduct.body);
    }
  }, [loadedProduct]);

  useEffect(() => {
    if (!productId) {
      setProduct(undefined);
    }
  }, [productId]);

  useEffect(() => {
    if (productError) {
      setError('Продукт с заданным ID не найден');
    }
  }, [productError]);

  useEffect(() => {
    if (!fieldValue) {
      setProductId(undefined);
      setProduct(undefined);
    }
  }, [productError]);

  const products = useMemo(() => data?.body || [], [data]);

  const exclude = () => {
    const value = fieldValue?.trim();
    const id = value ? +value : undefined;
    if (typeof id === 'number') {
      setProductId(id);
    }
  };

  const confirmExclude = () => {
    if (productId) {
      setProductIdToExclude(productId);
    }
  };

  const cancel = () => {
    setFieldValue('');
    setProductId(undefined);
    setProduct(undefined);
    setProductIdToExclude(undefined);
  };

  const confirmRemoveFromExclude = () => {
    setRemovingId(removeFromExcluded?.productId);
  };

  const cancelRemove = () => {
    setRemoveFromExcluded(undefined);
  };

  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer
          sx={{
            mt: '25px',
            height: '75vh',
          }}
        >
          <div>
            <div style={{ position: 'sticky', top: 0 }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    {headers.map((item, index) => (
                      <TableCell
                        key={item}
                        sx={headerCellSX}
                        align={getTableHeaderAlign(index, 1)}
                      >
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              </Table>
            </div>
            <div style={{ height: '68vh', overflow: 'auto' }}>
              <Table sx={{ minWidth: 650 }} style={{ tableLayout: 'fixed' }}>
                <TableBody>
                  {products.map((item: any) => (
                    <TableRow key={item.id}>
                      <TableCell align='left'>{item.productId}</TableCell>
                      <TableCell align='left'>{item.productName}</TableCell>
                      <TableCell
                        align='center'
                        onClick={() => setRemoveFromExcluded(item)}
                      >
                        <DeleteOutlined sx={{ cursor: 'pointer' }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </TableContainer>
      </Paper>
      {dialog ? (
        <Dialog
          fullWidth
          open={true}
          onClose={close}
          scroll='paper'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <MainContainer>
            <Typography sx={{ textAlign: 'center' }}>
              <strong>Добавление товара в список исключенных</strong>
            </Typography>
            <div>
              <TextField
                type='number'
                label='ID товара'
                variant='outlined'
                sx={{ mt: '15px' }}
                onChange={e => {
                  setError('');
                  setFieldValue(e?.target?.value);
                }}
              />
              <Button
                variant='outlined'
                size='large'
                sx={{ mt: '15px', ml: '15px', height: 56 }}
                onClick={exclude}
              >
                Добавить
              </Button>
            </div>
            {productId && productId === product?.id ? (
              <div style={{ marginTop: '20px' }}>
                Действительно желаете исключить товар?
                <br />
                <br />
                {product?.name || ''}
                <div>
                  <Button
                    variant='outlined'
                    color='error'
                    size='large'
                    sx={{ mt: '15px', height: 56 }}
                    onClick={confirmExclude}
                  >
                    Подтвердить
                  </Button>
                  <Button
                    variant='outlined'
                    size='large'
                    sx={{ mt: '15px', ml: '15px', height: 56 }}
                    onClick={cancel}
                  >
                    Отмена
                  </Button>
                </div>
              </div>
            ) : null}
            {error ? <div style={{ marginTop: '20px' }}>{error}</div> : null}
          </MainContainer>
        </Dialog>
      ) : null}
      {removeFromExcluded?.productId ? (
        <Dialog
          fullWidth
          open={true}
          onClose={close}
          scroll='paper'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <MainContainer>
            <Typography sx={{ textAlign: 'center' }}>
              <strong>
                Действительно желаете удалить из списка исключенных?
              </strong>
            </Typography>
            <div style={{ marginTop: '20px' }}>
              {removeFromExcluded.productName || ''}
              <div>
                <Button
                  variant='outlined'
                  color='error'
                  size='large'
                  sx={{ mt: '15px', height: 56 }}
                  onClick={confirmRemoveFromExclude}
                >
                  Подтвердить
                </Button>
                <Button
                  variant='outlined'
                  size='large'
                  sx={{ mt: '15px', ml: '15px', height: 56 }}
                  onClick={cancelRemove}
                >
                  Отмена
                </Button>
              </div>
            </div>
          </MainContainer>
        </Dialog>
      ) : null}
    </>
  );
};

export default ProductsTable;
