import { Alert, Button, Snackbar, Stack, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { useState } from 'react';

interface Props {
  dateFrom?: Date;
  dateTo?: Date;
  onChange?: (from: Date, to: Date) => void;
}

const PeriodPicker = ({
  dateFrom = new Date(),
  dateTo = new Date(),
  onChange,
}: Props) => {
  const [from, setFrom] = React.useState<Date>(dateFrom);
  const [to, setTo] = React.useState<Date>(dateTo);
  const [error, setError] = useState(false);

  const handleFrom = (newValue: Date | null) => {
    if (newValue) {
      setFrom(newValue);
    }
  };

  const handleTo = (newValue: Date | null) => {
    if (newValue) {
      setTo(newValue);
    }
  };

  const getData = () => {
    if (from && to && onChange) {
      if (+to - +from > 13 * 24 * 3_600_000) {
        onChange(from, to);
      } else {
        setError(true);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={3} direction='row' sx={{ mt: '20px' }}>
        <DesktopDatePicker
          label='Период с'
          inputFormat='DD.MM.YYYY'
          value={from}
          onChange={handleFrom}
          renderInput={params => <TextField {...params} />}
        />
        <DesktopDatePicker
          minDate={from}
          label='Период по'
          inputFormat='DD.MM.YYYY'
          value={to}
          onChange={handleTo}
          renderInput={params => <TextField {...params} />}
        />
        <Button variant='outlined' onClick={getData}>
          Применить
        </Button>
      </Stack>
      {error ? (
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={error}
          onClose={() => setError(false)}
        >
          <Alert severity='error' sx={{ width: '100%' }}>
            Период должен быть не менее двух недель
          </Alert>
        </Snackbar>
      ) : null}
    </LocalizationProvider>
  );
};

export default PeriodPicker;
