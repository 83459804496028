import React, { Fragment } from 'react';

type TableProps = {
  data: any;
  checkedCategories: string[];
  categoriesTree: { list: any[]; map: Record<string, any> };
  width: number;
};

export default function TradePointsTable({
  data,
  checkedCategories,
  categoriesTree,
  width,
}: TableProps) {
  const maxWidth = width / 2 + 'px';
  return (
    <>
      {(data?.list?.length && (
        <>
          {data.list.map((cat: any, i: number) => {
            if (i === 0) {
              return (
                <Fragment key={cat.value}>
                  <tr key={cat.value}>
                    <td style={{ height: '64px' }}></td>
                    <td
                      rowSpan={data.list.length + 1}
                      colSpan={6}
                      style={{
                        position: 'relative',
                        overflowX: 'auto',
                        maxWidth,
                      }}
                    >
                      <table style={{ position: 'relative' }}>
                        <thead>
                          <tr>
                            {checkedCategories?.map((cat: string) => {
                              return (
                                <th key={cat} colSpan={3}>
                                  {categoriesTree.map[cat].label}
                                </th>
                              );
                            })}
                          </tr>
                          <tr>
                            {checkedCategories?.map((cat: string) => (
                              <Fragment key={cat}>
                                <th>Количество</th>
                                <th>Выручка</th>
                                <th>Прибыль</th>
                              </Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.list.map((cat: any, i: number) => {
                            return (
                              <tr key={cat.value}>
                                {checkedCategories?.map(
                                  (cat: string, j: number) => (
                                    <Fragment key={cat}>
                                      <th>{(i + 1) * (j + 1)}</th>
                                      <th>{(i + 2) * (j + 2)}</th>
                                      <th>{(i + 3) * (j + 3)}</th>
                                    </Fragment>
                                  )
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                    <td
                      rowSpan={data.list.length + 1}
                      colSpan={6}
                      style={{
                        position: 'relative',
                        overflowX: 'auto',
                        maxWidth,
                      }}
                    >
                      <table style={{ position: 'relative' }}>
                        <thead>
                          <tr>
                            {checkedCategories?.map((cat: string) => {
                              return (
                                <th key={cat} colSpan={3}>
                                  {categoriesTree.map[cat].label}
                                </th>
                              );
                            })}
                          </tr>
                          <tr>
                            {checkedCategories?.map((cat: string) => (
                              <Fragment key={cat}>
                                <th>Количество</th>
                                <th>Выручка</th>
                                <th>Прибыль</th>
                              </Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.list.map((cat: any, i: number) => {
                            return (
                              <tr key={cat.value}>
                                {checkedCategories?.map(
                                  (cat: string, j: number) => (
                                    <Fragment key={cat}>
                                      <th>{(i + 1) * (j + 1)}</th>
                                      <th>{(i + 2) * (j + 2)}</th>
                                      <th>{(i + 3) * (j + 3)}</th>
                                    </Fragment>
                                  )
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                    <td
                      rowSpan={data.list.length + 1}
                      colSpan={6}
                      style={{
                        position: 'relative',
                        overflowX: 'auto',
                        maxWidth,
                      }}
                    >
                      <table style={{ position: 'relative' }}>
                        <thead>
                          <tr>
                            {checkedCategories?.map((cat: string) => {
                              return (
                                <th key={cat} colSpan={3}>
                                  {categoriesTree.map[cat].label}
                                </th>
                              );
                            })}
                          </tr>
                          <tr>
                            {checkedCategories?.map((cat: string) => (
                              <Fragment key={cat}>
                                <th>Количество</th>
                                <th>Выручка</th>
                                <th>Прибыль</th>
                              </Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.list.map((cat: any, i: number) => {
                            return (
                              <tr key={cat.value}>
                                {checkedCategories?.map(
                                  (cat: string, j: number) => (
                                    <Fragment key={cat}>
                                      <th>{(i + 1) * (j + 1)}</th>
                                      <th>{(i + 2) * (j + 2)}</th>
                                      <th>{(i + 3) * (j + 3)}</th>
                                    </Fragment>
                                  )
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                    <td
                      rowSpan={data.list.length + 1}
                      colSpan={6}
                      style={{
                        position: 'relative',
                        overflowX: 'auto',
                        maxWidth,
                      }}
                    >
                      <table style={{ position: 'relative' }}>
                        <thead>
                          <tr>
                            {checkedCategories?.map((cat: string) => {
                              return (
                                <th key={cat} colSpan={3}>
                                  {categoriesTree.map[cat].label}
                                </th>
                              );
                            })}
                          </tr>
                          <tr>
                            {checkedCategories?.map((cat: string) => (
                              <Fragment key={cat}>
                                <th>Количество</th>
                                <th>Выручка</th>
                                <th>Прибыль</th>
                              </Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data.list.map((cat: any, i: number) => {
                            return (
                              <tr key={cat.value}>
                                {checkedCategories?.map(
                                  (cat: string, j: number) => (
                                    <Fragment key={cat}>
                                      <th>{(i + 1) * (j + 1)}</th>
                                      <th>{(i + 2) * (j + 2)}</th>
                                      <th>{(i + 3) * (j + 3)}</th>
                                    </Fragment>
                                  )
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>{cat.label}</td>
                  </tr>
                </Fragment>
              );
            }
            return (
              <tr key={cat.value}>
                <td>{cat.label}</td>
              </tr>
            );
          })}
        </>
      )) ||
        null}
    </>
  );
}
