import {Button, Stack, Typography} from '@mui/material';
import React, { useState } from 'react';
import {useGetProductAnalytics2Query} from '../../../../../../redux/api/productApi/analytics';
import Period from '../../../../../Reports/components/Period/Period';
import PairInfoTable from './InfoTable/PairInfoTable';
import TripleInfoTable from './InfoTable/TripleInfoTable';

const ProductsAndCategoriesRatingInfo = ({
  id,
  type,
}: {
  id: number | null;
  type: string;
}) => {
  const [periodValue, setPeriodValue] = useState<string>('1');
  const [period, setPeriod] = useState<string>('');

  const skip = {skip: !id || !type || !period};
  const {data: products} = useGetProductAnalytics2Query(
    {id, type, period},
    skip
  );

  return (
    <>
      <Typography sx={{ textAlign: 'center', mt: '40px' }}>
        <strong>Совместные продажи</strong>
      </Typography>
      <Stack spacing={1} direction='row' sx={{ mt: '30px' }}>
        <Period period={periodValue} onChange={setPeriodValue} />
        <Button variant='outlined' onClick={() => setPeriod(periodValue)} sx={{height: '57px'}}>
          Применить
        </Button>
      </Stack>
      {products ? (
        <>
          <PairInfoTable info={(products as any)?.pairSupport || []} />
          <TripleInfoTable info={(products as any)?.tripleSupport || []} />
        </>
      ) : null}
    </>
  );
};

export default ProductsAndCategoriesRatingInfo;
