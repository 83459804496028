import React from 'react';
import { styled } from '@mui/material/styles';
import TreeItem, {
  TreeItemProps,
  treeItemClasses,
  TreeItemContentProps,
} from '@mui/lab/TreeItem';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useTreeItem } from '@mui/lab';

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} />
))(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    '&.Mui-expanded': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      color: '#295EF8',
    },
    '&.Mui-selected.Mui-focused': {},
  },
}));

const CustomContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps & { simpleExpand: boolean },
  ref: any
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    simpleExpand,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.SyntheticEvent<Element, Event>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (
    event: React.SyntheticEvent<Element, Event>
  ) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (
    event: React.SyntheticEvent<Element, Event>
  ) => {
    handleSelection(event);
    if (simpleExpand) {
      handleExpansion(event);
    }
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
      style={{ padding: '3px 0' }}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component='div'
        className={classes.label}
      >
        {label}
      </Typography>
    </div>
  );
});
const CustomTreeItem = (props: any) => (
  <StyledTreeItem
    ContentComponent={CustomContent}
    ContentProps={{
      simpleExpand: props.simpleExpand,
    }}
    {...props}
  />
);

export default CustomTreeItem;
