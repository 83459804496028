export const headerCellSX = {
  fontWeight: 700,
  minWidth: '20px',
};

export const getTableHeaderAlign = (
  index: number,
  maxLeftIndex: number = 2
) => {
  if (index < maxLeftIndex) return 'left';
  return 'center';
};
