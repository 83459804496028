import { Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';

import './App.css';
import Router from './navigation';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className='demo-version-view'>
        <Typography
          sx={{
            color: 'white',
            fontSize: 14,
            textAlign: 'center',
          }}
        >
          Демонстрационная версия
        </Typography>
      </div>
      <Router />
    </LocalizationProvider>
  );
}

export default App;
