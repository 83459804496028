import React, { useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import styles from './Filters.module.scss';

const filtersByValue: string[] = [
  'Продано, шт.',
  'Выручка, руб.',
  'Маржа, руб.',
];

const filtersByDiff: string[] = [
  'от проданного количества',
  'от выручки',
  'от маржи',
];

export default function Filters() {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<Record<number, Record<string, number>>>(
    {}
  );

  const updateValue = (i: number, key: string, value: string) => {
    setState({
      ...state,
      [i]: {
        ...state[i],
        [key]: value?.length > 10 ? +value.slice(0, 10) : +value,
      },
    });
  };

  const updateDiff = (i: number, key: string, value: string) => {
    setState({
      ...state,
      [i]: {
        ...state[i],
        [key]: value?.length > 3 ? +value.slice(0, 3) : +value,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <IconButton
        aria-label='sort'
        size='small'
        onClick={e => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <FilterAltIcon fontSize='inherit' />
      </IconButton>
      {open && (
        <div className={styles.container}>
          <fieldset>
            <legend>По значению</legend>
            {filtersByValue.map((filter, i) => (
              <fieldset key={i}>
                <legend>{filter}</legend>
                <TextField
                  type='number'
                  label='Не менее чем'
                  variant='standard'
                  onChange={e => updateValue(i, 'minV', e.target.value)}
                  value={state[i]?.minV || ''}
                />
                <TextField
                  type='number'
                  label='Не более чем'
                  variant='standard'
                  onChange={e => updateValue(i, 'maxV', e.target.value)}
                  value={state[i]?.maxV || ''}
                />
              </fieldset>
            ))}
          </fieldset>

          <fieldset>
            <legend>По отклонению в период сопоставления, %</legend>
            {filtersByDiff.map((filter, i) => (
              <fieldset key={i}>
                <legend>{filter}</legend>
                <TextField
                  type='number'
                  label='Не менее чем'
                  variant='standard'
                  onChange={e => updateDiff(i, 'minD', e.target.value)}
                  value={state[i]?.minD || ''}
                />
                <TextField
                  type='number'
                  label='Не более чем'
                  variant='standard'
                  onChange={e => updateDiff(i, 'maxD', e.target.value)}
                  value={state[i]?.maxD || ''}
                />
              </fieldset>
            ))}
          </fieldset>
        </div>
      )}
    </div>
  );
}
