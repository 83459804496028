import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export type PeriodType = {
  label: string;
  value: string;
};

const periods: PeriodType[] = [
  {
    label: 'Вчера',
    value: '1',
  },
  {
    label: 'Прошлая неделя',
    value: '7',
  },
  {
    label: 'Прошлый месяц',
    value: '30',
  },
];

interface Props {
  period: string;
  onChange: (period: string) => void;
  values?: PeriodType[];
}

const Period = ({ period, onChange, values = periods }: Props) => {
  const handlePeriod = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <Box
      sx={{
        minWidth: 180,
        maxWidth: 180,
      }}
    >
      <FormControl fullWidth>
        <InputLabel id='period-select-label'>Период</InputLabel>
        <Select
          labelId='period-select'
          id='period-select'
          value={period}
          label='Период'
          onChange={handlePeriod}
        >
          {values.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Period;
