import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { RatingType } from '../../../../../common/types/Rating';
import { ratings } from './mockData';
import { Rating } from '../../../../../common/types';
import PositionCell from './components/PositionCell';
import {
  getTableHeaderAlign,
  headerCellSX,
} from '../../../../../utils/helpers/table';

const headers = [
  'Позиция',
  'Товар',
  'Продано штук',
  'Продано на сумму',
  'Выгода',
];

interface Props {
  selectedRow?: RatingType;
  onRowClick: (
    event: React.MouseEvent<HTMLElement>,
    report: RatingType
  ) => void;
  data?: any[];
}

const RatingsTable = ({ data, onRowClick, selectedRow }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            {headers.map((item, index) => (
              <TableCell
                key={item}
                sx={headerCellSX}
                align={getTableHeaderAlign(index)}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((report: RatingType) => (
            <TableRow
              key={report.productid}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: 'pointer',
              }}
              selected={selectedRow?.productid === report.productid}
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                onRowClick(event, report)
              }
            >
              <PositionCell
                position={report.position}
                positionChange={report.deltaposition}
              />
              <TableCell
                align='left'
                sx={{
                  color:
                    selectedRow?.productid === report.productid
                      ? '#295EF8'
                      : '#000000',
                }}
              >
                {report.productname}
              </TableCell>
              <TableCell align='center'>
                {report.goodsold.toLocaleString()}
              </TableCell>
              <TableCell align='center'>
                {report.sum.toLocaleString()}
              </TableCell>
              <TableCell align='center'>
                {report.benefit.toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RatingsTable;
