import {Button, Dialog, DialogContent, Stack, TextField, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
  useCreateShelfMutation,
  useUpdateShelfMutation,
} from '../../../../../redux/api/clientApi/shelves';

interface Props {
  shelf?: Partial<ShelfType>;
  onClose: () => void;
  refetch: () => void;
  shop: any;
}

export const NewShelfDialog = ({shelf, onClose, shop, refetch}: Props) => {
  const [value, setValue] = useState<Partial<ShelfType>>();
  const [create] = useCreateShelfMutation();
  const [update] = useUpdateShelfMutation();

  const save = async () => {
    if (value?.id) {
      await update({
        id: value.id,
        body: {
          name: value?.name,
          tradePointId: shop?.id,
        },
      }).unwrap();
    } else {
      await create({
        name: value?.name,
        tradePointId: shop?.id,
      });
    }
    refetch?.();
    onClose?.();
  };

  useEffect(() => {
    if (shelf) {
      setValue({...shelf});
    }
  }, [shelf]);

  return (
    <Dialog
      open={!!shelf}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent
        dividers
        sx={{
          width: 'max-content',
        }}
      >
        <Typography variant="h5">{shelf?.tradePointName || shop?.name || ''}</Typography>
        <TextField
          error={!value?.name}
          helperText={value?.name ? '' : 'Укажите название'}
          type="text"
          label="Название полки"
          value={value?.name || ''}
          onChange={(e) => {
            setValue({
              ...value,
              name: e.target.value,
            });
          }}
          variant="outlined"
          sx={{mt: '15px'}}
          fullWidth
        />
        <Stack direction="row" spacing={1} alignContent="center" justifyContent="center" width="100%" style={{marginTop: '15px'}}>
          <Button
            color="warning"
            variant="outlined"
            size="small"
            onClick={onClose}
          >
            Отмена
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={save}
            disabled={!value?.name}
          >
            Сохранить
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
