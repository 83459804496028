import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { baseUrl } from '../../../../../redux/api/apiSlice';
import {
  useCreateSubShelfMutation,
  useGetShelfQuery,
} from '../../../../../redux/api/clientApi/shelves';
import './Shelf.scss';
import { getPointsFromStr } from '../../../../../utils/coords';

interface Props {
  shelfId: number;
  open: boolean;
  onClose: () => void;
  list: SubShelfType[];
}

type SubShelfData = Partial<{
  name: string;
  idShelf: number;
  coords: string;
  emptySpaceCoords: string;
}>;

const imageHeight = 600;
let ctx: CanvasRenderingContext2D | undefined;
let imageRatio = 1;
let sectionStartPoint = new Path2D();
let section: PointType[] = [];
let emptyPlace: PointType[] = [];

const sectionColor = '#55ff00';
const emptyPlaceColor = '#00a6ff';
const newColor = '#d400e1';
const endColor = '#ff0000';

// const getPoints = (coords: string, ratio: number): PointType[] => {
//   return (
//     coords && ratio
//       ? coords
//           .replace('((', '')
//           .replace('))', '')
//           .split('),(')
//           .map(str => {
//             const [x, y] = str.split(',');
//             return { x: +x * ratio, y: +y * ratio };
//           })
//       : []
//   ) as PointType[];
// };
//
const drawPolygon = (
  ctx: CanvasRenderingContext2D,
  polygon: PointType[],
  color: string
) => {
  if (polygon?.length) {
    ctx.lineWidth = 3;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(polygon[0].x, polygon[0].y);
    for (let i = 1; i < polygon.length; i++) {
      ctx.lineTo(polygon[i].x, polygon[i].y);
    }
    ctx.lineTo(polygon[0].x, polygon[0].y);
    ctx.closePath();
    ctx.stroke();
  }
};

export const NewEmptyPlace = ({ shelfId, open, onClose, list }: Props) => {
  const [imgUrl, setImgUrl] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [complete, setComplete] = useState(false);
  const [subShelf, setSubShelf] = useState<SubShelfData>();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvas = canvasRef.current;

  const { data: shelfData } = useGetShelfQuery(
    { id: shelfId },
    { skip: !shelfId }
  );
  const shelf = shelfData?.body as ShelfType;

  const [create] = useCreateSubShelfMutation();

  useEffect(() => {
    if (shelfId) {
      setImgUrl(`${baseUrl}/clientservice/shelf/${shelfId}/shelf_image`);
      setSubShelf(prev => ({
        ...((prev || {}) as SubShelfData),
        idShelf: shelfId,
      }));
    }
  }, [shelfId]);

  const updateSubShelf = (key: keyof SubShelfData, value: string) => {
    setSubShelf(prev => ({
      ...((prev || { idShelf: shelfId }) as SubShelfData),
      [key]: value?.trim(),
    }));
  };

  const close = () => {
    setComplete(false);
    section = [];
    emptyPlace = [];
    setSubShelf(undefined);
    onClose?.();
  };

  const createSubShelf = async () => {
    await create(subShelf).unwrap();
    close();
  };

  const lineIntersects = (
    p0: PointType,
    p1: PointType,
    p2: PointType,
    p3: PointType
  ) => {
    let s1_x, s1_y, s2_x, s2_y: number;
    s1_x = p1.x - p0.x;
    s1_y = p1.y - p0.y;
    s2_x = p3.x - p2.x;
    s2_y = p3.y - p2.y;
    let s, t: number;
    s =
      (-s1_y * (p0.x - p2.x) + s1_x * (p0.y - p2.y)) /
      (-s2_x * s1_y + s1_x * s2_y);
    t =
      (s2_x * (p0.y - p2.y) - s2_y * (p0.x - p2.x)) /
      (-s2_x * s1_y + s1_x * s2_y);
    return s >= 0 && s <= 1 && t >= 0 && t <= 1;
  };

  const point = (x: number, y: number) => {
    if (ctx) {
      // const color = subShelf?.coords ? emptyPlaceColor : sectionColor;
      ctx.fillStyle = newColor;
      ctx.strokeStyle = newColor;
      ctx.fillRect(x - 2, y - 2, 4, 4);
      ctx.moveTo(x, y);
    }
  };

  const clearCanvas = (e?: any) => {
    ctx = undefined;
    section = [];
    emptyPlace = [];
    setComplete(false);
    setSubShelf({ idShelf: shelfId });
    sectionStartPoint = new Path2D();
    startDraw(true, e);
  };

  const clearEmptyPlace = () => {
    ctx = undefined;
    emptyPlace = [];
    setComplete(false);
    delete subShelf?.emptySpaceCoords;
    setSubShelf({ ...(subShelf || { idShelf: shelfId }) });
    sectionStartPoint = new Path2D();
    startDraw(true);
  };

  useEffect(() => {
    setComplete(!!(subShelf?.coords && subShelf.emptySpaceCoords));
  }, [subShelf]);

  const draw = (end = false, points?: PointType[]) => {
    if (!ctx) return;
    // const color = subShelf?.coords ? emptyPlaceColor : sectionColor;
    // let color: string;
    // if (subShelf?.coords) {
    //   color = emptyPlaceColor;
    //   if (!polygon.length) {
    //     polygon = getPoints(subShelf.coords, imageRatio);
    //   }
    // } else {
    //   color = sectionColor;
    //   if (!polygon.length) {
    //     polygon = getPoints(subShelf?.emptySpaceCoords || '', imageRatio);
    //   }
    // }

    ctx.lineWidth = 3;
    ctx.strokeStyle = newColor;
    ctx.lineCap = 'square';
    ctx.beginPath();

    const list = subShelf?.coords ? emptyPlace : section;

    for (let i = 0; i < list.length; i++) {
      if (i == 0) {
        ctx.moveTo(list[i].x, list[i].y);
        end || point(list[i].x, list[i].y);
      } else {
        ctx.lineTo(list[i].x, list[i].y);
        end || point(list[i].x, list[i].y);
      }
    }
    if (end) {
      ctx.lineTo(list[0].x, list[0].y);
      ctx.closePath();
      ctx.strokeStyle = newColor;
    }
    ctx.stroke();
  };

  const isValidPointsDistance = (polygon: PointType[]) => {
    if (!polygon) {
      return false;
    }
    if (polygon.length < 2) {
      return true;
    }
    for (let i = 0; i < polygon.length - 1; i++) {
      const p1 = {
        x: polygon[i].x,
        y: polygon[i].y,
      };
      let p2: PointType | undefined = undefined;
      if (i + 1 < polygon.length) {
        p2 = {
          x: polygon[i + 1].x,
          y: polygon[i + 1].y,
        };
      }
      if (p2) {
        const xDiff = Math.abs(p1.x - p2.x);
        const yDiff = Math.abs(p1.y - p2.y);
        if (xDiff > yDiff) {
          if (xDiff < 30) {
            return false;
          }
        } else {
          if (yDiff < 30) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const checkIntersect = (polygon: PointType[], x: number, y: number) => {
    if (polygon.length < 3) {
      return false;
    }
    const p2 = {
      x: polygon[polygon.length - 1].x,
      y: polygon[polygon.length - 1].y,
    } as PointType;
    const p3 = { x, y } as PointType;

    for (let i = 0; i < polygon.length - 1; i++) {
      const p0 = { x: polygon[i].x, y: polygon[i].y };
      const p1 = {
        x: polygon[i + 1].x,
        y: polygon[i + 1].y,
      };
      if (p1.x == p2.x && p1.y == p2.y) {
        continue;
      }
      if (p0.x == p3.x && p0.y == p3.y) {
        continue;
      }
      if (lineIntersects(p0, p1, p2, p3)) {
        return true;
      }
    }
    return false;
  };

  const onClick = (event: any, start = false) => {
    if (!start && complete) {
      clearEmptyPlace();
      return false;
    }
    let rect, x, y;
    if (!canvas) return;
    const polygon = subShelf?.coords ? emptyPlace : section;
    rect = canvas.getBoundingClientRect();
    x = event.clientX - rect.left;
    y = event.clientY - rect.top;
    if (polygon.length === 0) {
      sectionStartPoint.arc(x, y, 7, 0, 2 * Math.PI);
      if (ctx) {
        ctx.fillStyle = newColor;
        ctx.fill(sectionStartPoint);
      }
    }
    if (polygon.length > 1) {
      const isPointInPath = !!ctx?.isPointInPath(sectionStartPoint, x, y);
      if (isPointInPath) {
        if (polygon.length === 4) {
          // draw(true);
          const points = polygon.map(p => ({
            x: p.x / imageRatio,
            y: p.y / imageRatio,
          }));
          let name: keyof SubShelfData;
          if (subShelf?.coords) {
            name = 'emptySpaceCoords';
          } else {
            name = 'coords';
          }
          startDraw(!!(section.length && !emptyPlace.length));
          updateSubShelf(
            name,
            `(${points.map(p => `(${p.x},${p.y})`).join(',')})`
          );
          // if (subShelf?.coords) {
          //   draw(true);
          // } else {
          //   startDraw(!!(section.length && !emptyPlace.length));
          // }
          return;
        } else {
          setAlertMessage('Должно быть четыре точки для построения полигона');
          return;
        }
      }
    }
    if (!isValidPointsDistance([...polygon, { x, y }])) {
      setAlertMessage('Слишком маленькое расстояние между точками полигона');
      return;
    }
    if (checkIntersect(polygon, x, y)) {
      setAlertMessage('Стороны полигона не должны пересекаться');
      return;
    }
    polygon.push({ x, y });
    draw(false);
  };

  const drawSubShelves = () => {
    if (list?.length) {
      list
        .map(shelf => [
          getPointsFromStr(shelf.coords, imageRatio),
          getPointsFromStr(shelf.emptySpaceCoords, imageRatio),
        ])
        .forEach(([section, emptyPlace]) => {
          if (ctx) {
            drawPolygon(ctx, section, sectionColor);
            drawPolygon(ctx, emptyPlace, emptyPlaceColor);
          }
        });
    }
    if (ctx) {
      drawPolygon(ctx, section, sectionColor);
      drawPolygon(ctx, emptyPlace, emptyPlaceColor);
    }
  };

  const startDraw = (withDraw: boolean, e?: MouseEvent) => {
    if (canvas && imgUrl) {
      const img = new Image();
      img.src = imgUrl;
      img.onload = () => {
        sectionStartPoint = new Path2D();

        if (img.width > 1200) {
          canvas.width = 1200;
          imageRatio = 1200 / img.width;
          canvas.height = img.height * imageRatio;
        } else {
          canvas.height = imageHeight;
          imageRatio = imageHeight / img.height;
          canvas.width = img.width * imageRatio;
        }

        // canvas.height = imageHeight;
        // imageRatio = imageHeight / img.height;
        // canvas.width = img.width * imageRatio; // 600 fixed canvas height
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        drawSubShelves();
        if (withDraw) {
          // draw(false);
          if (e) {
            onClick(e, true);
          }
        }
      };
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (!canvas) return;
    const polygon = subShelf?.coords ? emptyPlace : section;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    if (ctx && polygon.length > 2) {
      const isPointInPath = !!ctx?.isPointInPath(sectionStartPoint, x, y);
      if (isPointInPath) {
        ctx.fillStyle = endColor;
        ctx.fill(sectionStartPoint);
      } else {
        ctx.fillStyle = newColor;
        ctx.fill(sectionStartPoint);
      }
    }
  };

  useEffect(() => {
    if (imgUrl) {
      startDraw(true);
    }
  }, [imgUrl]);

  if (!shelf) return <></>;

  return (
    <>
      <Dialog open={open} onClose={onClose} scroll='paper' maxWidth={false}>
        <DialogContent dividers>
          <TextField
            error={!subShelf?.name}
            helperText={subShelf?.name ? '' : 'Укажите название'}
            type='text'
            label='Название секции полки'
            value={subShelf?.name || ''}
            onChange={e => updateSubShelf('name', e.target.value)}
            variant='outlined'
            sx={{ mt: '15px' }}
            fullWidth
          />
          <Stack
            direction='row'
            spacing={1}
            width='100%'
            style={{ marginTop: '15px' }}
          >
            <Stack
              direction='row'
              alignContent='center'
              justifyContent='center'
              width='100%'
              minHeight={200}
            >
              <Box
                className='shelf-image'
                sx={{ display: imgUrl ? undefined : 'none' }}
              >
                Фото полки
                <canvas
                  ref={canvasRef}
                  onClick={onClick}
                  onMouseMove={onMouseMove}
                />
              </Box>
            </Stack>
            {imgUrl ? (
              <Paper sx={{ width: '100%', padding: '10px' }}>
                {subShelf?.coords ? (
                  <div>
                    <div>
                      <Button
                        variant='outlined'
                        onClick={() => clearCanvas()}
                        size='small'
                      >
                        Удалить секцию
                      </Button>
                      <div style={{ marginTop: '15px' }}>
                        Обведите пустое место в секции
                      </div>
                    </div>
                  </div>
                ) : (
                  'Обведите секцию на полке'
                )}
              </Paper>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Отмена</Button>
          <Button
            onClick={createSubShelf}
            autoFocus
            disabled={!complete || !subShelf?.name}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!alertMessage}
        autoHideDuration={3000}
        onClose={() => setAlertMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setAlertMessage('')}
          severity='error'
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
