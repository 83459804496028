import { Response } from '../../../common/types';
import { apiSlice } from '../apiSlice';

export const {
  useGetCameraPolygonQuery,
  useUpdateCameraPolygonMutation,
  useGetPlanPolygonQuery,
  useUpdatePlanPolygonMutation,
  useGetBlockingPolygonsQuery,
  useUpdateBlockingPolygonsMutation,
  useUploadVideoMutation,
} = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCameraPolygon: builder.query<Response<any>, any>({
      query: ({ id }: { id: number }) => ({
        url: `/clientservice/camera/${id}/polygon_frame`,
      }),
    }),
    updateCameraPolygon: builder.mutation<Response<any>, any>({
      query: ({ id, body }: { id: number; body: { polygon: string } }) => ({
        url: `/clientservice/camera/${id}/polygon_frame`,
        method: 'PUT',
        body,
      }),
    }),
    getPlanPolygon: builder.query<Response<any>, any>({
      query: ({ id }: { id: number }) => ({
        url: `/clientservice/camera/${id}/polygon_plan`,
      }),
    }),
    updatePlanPolygon: builder.mutation<Response<any>, any>({
      query: ({ id, body }: { id: number; body: { polygon: string } }) => ({
        url: `/clientservice/camera/${id}/polygon_plan`,
        method: 'PUT',
        body,
      }),
    }),
    getBlockingPolygons: builder.query<Response<any>, any>({
      query: ({ id }: { id: number }) => ({
        url: `/clientservice/camera/${id}/polygon_map`,
      }),
    }),
    updateBlockingPolygons: builder.mutation<Response<any>, any>({
      query: ({ id, body }: { id: number; body: { polygons: string[] } }) => ({
        url: `/clientservice/camera/${id}/polygon_map`,
        method: 'PUT',
        body,
      }),
    }),
    uploadVideo: builder.mutation<Response<any>, any>({
      query: (body: FormData) => ({
        url: '/heatmapservice/send_video',
        method: 'POST',
        body,
      }),
    }),
  }),
});
