import { Response } from '../../../common/types';
import { apiSlice } from '../apiSlice';

export const productsSalesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSalesReport: builder.query<Response<any>, any>({
      query: ({
        count,
        period,
        type,
      }: {
        count: 'pair' | 'triple';
        period: 'day' | 'week' | 'month';
        type: 'scu' | 'cat';
      }) => ({
        url: `/associateruleservice/joint_top?period=${period}&obj_type=${type}&obj_count=${count}`,
      }),
    }),
    getPairReport: builder.query<Response<any>, any>({
      query: ({
        id1,
        id2,
        period,
        type,
      }: {
        id1: any,
        id2: any,
        period: 'day' | 'week' | 'month';
        type: 'scu' | 'cat';
      }) => ({
        url: `/associateruleservice/joint_one2one_card?id_good_1=${id1}&id_good_2=${id2}&period=${period}&obj_type=${type}`,
      }),
    }),
    getTripleReport: builder.query<Response<any>, any>({
      query: ({
        id1,
        id2,
        id3,
        period,
        type,
      }: {
        id1: any,
        id2: any,
        id3: any,
        period: 'day' | 'week' | 'month';
        type: 'scu' | 'cat';
      }) => ({
        url: `associateruleservice/joint_one2pair_card?id_good_1=${id1}&id_good_2=${id2}&id_good_3=${id3}&period=${period}&obj_type=${type}`,
      }),
    }),
  }),
});

export const {
  useGetSalesReportQuery,
  useGetPairReportQuery,
  useGetTripleReportQuery
} = productsSalesApi;
