export const mockShelves: any[] = [
  {
    id: '1',
    name: 'Торговая точка 1135 на Ленинградском проспекте',
    width: 100,
    length: 20,
    images: [
      'https://previews.123rf.com/images/rozum/rozum1812/rozum181200018/113329401-empty-shelves-in-shop-market-element-of-design-.jpg',
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcnimzePGay5rn8y6O3YxILEX5j3sf2p7NG7K9TulFUTKVDm2Pfd2athMBZZspTeIhKjo&usqp=CAU',
      'https://isteam.wsimg.com/ip/66258722-235a-4ebd-b325-58a3d7360c5d/uyg1.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25',
    ],
  },
  {
    id: '2',
    name: 'Торговая точка 1122 на Ленинградском проспекте',
    width: 120,
    length: 20,
    images: [
      'https://previews.123rf.com/images/rozum/rozum1812/rozum181200018/113329401-empty-shelves-in-shop-market-element-of-design-.jpg',
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcnimzePGay5rn8y6O3YxILEX5j3sf2p7NG7K9TulFUTKVDm2Pfd2athMBZZspTeIhKjo&usqp=CAU',
      'https://isteam.wsimg.com/ip/66258722-235a-4ebd-b325-58a3d7360c5d/uyg1.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25',
    ],
  },
  {
    id: '3',
    name: 'Торговая точка 133 на Ленинградском проспекте',
    width: 110,
    length: 20,
    images: [
      'https://previews.123rf.com/images/rozum/rozum1812/rozum181200018/113329401-empty-shelves-in-shop-market-element-of-design-.jpg',
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcnimzePGay5rn8y6O3YxILEX5j3sf2p7NG7K9TulFUTKVDm2Pfd2athMBZZspTeIhKjo&usqp=CAU',
      'https://isteam.wsimg.com/ip/66258722-235a-4ebd-b325-58a3d7360c5d/uyg1.jpg/:/cr=t:0%25,l:0%25,w:100%25,h:100%25',
    ],
  },
  {
    id: '4',
    name: 'Торговая точка 2231 на Ленинградском проспекте',
    width: 140,
    length: 40,
  },
];
