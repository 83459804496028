import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {headerCellSX} from '../../../../../utils/helpers/table';

const MarketLifts = ({lifts}: {lifts: CategoryLiftType[]}) => {
  return (
    <TableContainer component={Paper} sx={{mt: '25px'}}>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={headerCellSX} align="left">Категория</TableCell>
            <TableCell sx={headerCellSX} align="left">Категория</TableCell>
            <TableCell sx={headerCellSX} align="center">Достоверность</TableCell>
            <TableCell sx={headerCellSX} align="center">Лифт</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lifts?.length ? lifts?.map((lift, index: number) => (
            <TableRow key={index}>
              <TableCell align="left">{lift.cat_1_name}</TableCell>
              <TableCell align="left">{lift.cat_2_name}</TableCell>
              <TableCell align="center">{Math.round((lift.confidence || 0) * 10000) / 100}</TableCell>
              <TableCell align="center">{Math.round((lift.support || 0) * 10000) / 100}</TableCell>
            </TableRow>
          )) : (
            <TableRow>
              <TableCell align="center" colSpan={4}>Нет данных</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MarketLifts;
