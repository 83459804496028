import { Menu, MenuItem, MenuProps } from '@mui/material';
import React from 'react';

interface Props extends MenuProps {
  onItemPress: React.MouseEventHandler<HTMLLIElement>;
}

const AdminMenu = ({ onItemPress, ...otherProps }: Props) => {
  return (
    <Menu {...otherProps}>
      <MenuItem data-route='/admin-trade-points' onClick={onItemPress}>
        Торговые точки
      </MenuItem>
      <MenuItem data-route='/admin-shelves' onClick={onItemPress}>
        Управление полками
      </MenuItem>
      <MenuItem data-route='/admin-cameras' onClick={onItemPress}>
        Управление камерами
      </MenuItem>
      <MenuItem data-route='/excluded-goods' onClick={onItemPress}>
        Исключенные товары
      </MenuItem>
      <MenuItem data-route='/admin-clustering' onClick={onItemPress}>
        Кластеризация
      </MenuItem>
    </Menu>
  );
};

export default AdminMenu;
