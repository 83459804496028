import { Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import { baseUrl } from '../../../redux/api/apiSlice';
import {
  useGetTradePointListQuery,
  useUpdateTradePointMutation,
  useUploadTradePointPlanMutation,
} from '../../../redux/api/clientApi';
import { headerCellSX } from '../../../utils/helpers/table';

const TradePoints = () => {
  const [shop, setShop] = useState<ShopType>();
  const [edit, setEdit] = useState<ShopType>();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [format, setFormat] = useState('');
  const [floor, setFloor] = useState<any>('');
  const [cashDeskQuantity, setCashDeskQuantity] = useState<any>('');
  const [planImage, setPlanImage] = useState('');
  const [updatedPlanImage, setUpdatedPlanImage] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [upload] = useUploadTradePointPlanMutation();
  const [update] = useUpdateTradePointMutation();

  const { data, refetch } = useGetTradePointListQuery({
    clientId: 2,
    limit: 100,
    offset: 0,
  });

  useEffect(() => {
    if (edit) {
      setName(edit?.name);
      setAddress(edit?.address);
      setFormat(edit?.format);
      setFloor(edit?.floor);
      setCashDeskQuantity(edit?.cashDeskQuantity);
      if (edit?.hasPlanImage) {
        setPlanImage(
          `${baseUrl}/clientservice/tradePoint/${edit?.id}/plan_image`
        );
      }
    } else {
      setName('');
      setAddress('');
      setFormat('');
      setFloor('');
      setCashDeskQuantity('');
      setPlanImage('');
      setUpdatedPlanImage('');
    }
  }, [edit]);

  const fileDialog = () => {
    inputRef.current?.click();
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      setUpdatedPlanImage(URL.createObjectURL(file));
      setPlanImage('');
      const body = new FormData();
      body.set('planImage', file);
      await upload({ id: edit?.id, body }).unwrap();
      refetch?.();
    }
  };

  const removePlanImage = () => {
    setUpdatedPlanImage('');
    setPlanImage('');
  };

  const updateTradePoint = async () => {
    await update({
      id: edit?.id,
      body: { name, address, format, floor, cashDeskQuantity },
    }).unwrap();
    refetch?.();
    setName('');
    setAddress('');
    setFormat('');
    setFloor('');
    setCashDeskQuantity('');
    setPlanImage('');
    setUpdatedPlanImage('');
    setEdit(undefined);
  };

  const savingDisabled = useMemo(
    () =>
      !edit?.id ||
      !name ||
      !address ||
      !format ||
      !floor ||
      !cashDeskQuantity ||
      (edit.name === name &&
        edit.address === address &&
        edit.format === format &&
        edit.floor === floor &&
        edit.cashDeskQuantity === cashDeskQuantity),
    [edit, name, address, format, floor, cashDeskQuantity]
  );

  const list = data?.body as ShopType[];

  return (
    <>
      <MainContainer title='Торговые точки'>
        <TableContainer component={Paper} sx={{ mt: '25px' }}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellSX} width='40' align='center'>
                  ID
                </TableCell>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  Название
                </TableCell>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  Адрес
                </TableCell>
                <TableCell sx={headerCellSX} width='40' align='center' />
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.length ? (
                list.map((shop: ShopType) => (
                  <TableRow
                    key={shop.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer',
                    }}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      setShop(shop);
                    }}
                  >
                    <TableCell align='center'>{shop.id}</TableCell>
                    <TableCell align='left'>{shop.name}</TableCell>
                    <TableCell align='left'>{shop.address}</TableCell>
                    <TableCell align='center'>
                      <Tooltip title='Редактировать' placement='top'>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            setEdit(shop);
                          }}
                        >
                          <Edit fontSize='small' className='cell-button' />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align='center' colSpan={4}>
                    Нет данных
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </MainContainer>
      <Dialog open={!!shop} onClose={() => setShop(undefined)} maxWidth={false}>
        <DialogTitle>Торговая точка</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} sx={{ mt: '25px' }}>
            <Table
              sx={{ minWidth: 400, maxWidth: 600 }}
              aria-label='simple table'
            >
              <TableBody>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left' width='120'>
                    ID
                  </TableCell>
                  <TableCell align='left'>{shop?.id}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Название</TableCell>
                  <TableCell align='left'>{shop?.name}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Адрес</TableCell>
                  <TableCell align='left'>{shop?.address}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Формат</TableCell>
                  <TableCell align='left'>{shop?.format}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Этаж</TableCell>
                  <TableCell align='left'>{shop?.floor}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Количество касс</TableCell>
                  <TableCell align='left'>{shop?.cashDeskQuantity}</TableCell>
                </TableRow>
                {shop?.hasPlanImage ? (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell align='center' colSpan={2}>
                      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                        План торговой точки
                      </div>
                      <img
                        src={`${baseUrl}/clientservice/tradePoint/${shop?.id}/plan_image`}
                        alt='План торговой точки'
                        style={{ maxWidth: '600px', height: 'auto' }}
                      />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShop(undefined)} autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!edit} onClose={() => setEdit(undefined)} maxWidth={false}>
        <DialogTitle>Торговая точка</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} sx={{ mt: '25px' }}>
            <Table
              sx={{ minWidth: 400, maxWidth: 600 }}
              aria-label='simple table'
            >
              <TableBody>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left' width='120'>
                    ID
                  </TableCell>
                  <TableCell align='left'>{edit?.id}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Название</TableCell>
                  <TableCell align='left'>
                    <TextField
                      hiddenLabel
                      variant='standard'
                      size='small'
                      value={name}
                      onChange={e => setName(e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Адрес</TableCell>
                  <TableCell align='left'>
                    <TextField
                      hiddenLabel
                      variant='standard'
                      size='small'
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Формат</TableCell>
                  <TableCell align='left'>
                    <TextField
                      hiddenLabel
                      variant='standard'
                      size='small'
                      value={format}
                      onChange={e => setFormat(e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Этаж</TableCell>
                  <TableCell align='left'>
                    <TextField
                      hiddenLabel
                      variant='standard'
                      size='small'
                      value={floor}
                      onChange={e => setFloor(e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Количество касс</TableCell>
                  <TableCell align='left'>
                    <TextField
                      hiddenLabel
                      variant='standard'
                      size='small'
                      value={cashDeskQuantity}
                      onChange={e => setCashDeskQuantity(e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='center' colSpan={2}>
                    <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                      План торговой точки
                    </div>
                    {planImage || updatedPlanImage ? (
                      <Box
                        className='shelf-image'
                        sx={{ width: 'max-content' }}
                      >
                        <img
                          src={planImage || updatedPlanImage}
                          alt='план торговой точки'
                          style={{ maxWidth: '600px' }}
                          onError={() => setUpdatedPlanImage('')}
                        />
                        <Tooltip title='Удалить' placement='top'>
                          <button
                            className='remove-button'
                            onClick={removePlanImage}
                          >
                            &times;
                          </button>
                        </Tooltip>
                      </Box>
                    ) : (
                      <>
                        <input
                          type='file'
                          style={{ display: 'none' }}
                          ref={inputRef}
                          onChange={e => onFileChange(e)}
                        />
                        <Button
                          sx={{ height: 'max-content', width: 'max-content' }}
                          variant='outlined'
                          onClick={fileDialog}
                          size='small'
                        >
                          Добавить план торговой точки
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEdit(undefined)} autoFocus>
            Отмена
          </Button>
          <Button
            onClick={updateTradePoint}
            autoFocus
            disabled={savingDisabled}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TradePoints;
