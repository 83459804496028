import React from 'react';

const TrendSVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='11'
      viewBox='0 0 17 11'
      fill='none'
    >
      <path
        d='M0.369049 5.37002L0.375899 5.47182C0.425559 5.83792 0.739349 6.12002 1.11905 6.12002L13.811 6.12092L10.5934 9.34082L10.5208 9.42492C10.3031 9.71862 10.3275 10.1353 10.5938 10.4015C10.8869 10.6942 11.3617 10.694 11.6545 10.401L16.1545 5.89722L16.2271 5.81312C16.4449 5.51942 16.4205 5.10272 16.1542 4.83662L11.6541 0.340324L11.5699 0.267824C11.2762 0.050024 10.8595 0.0744238 10.5934 0.340824L10.5208 0.424925C10.3031 0.718625 10.3275 1.13532 10.5938 1.40152L13.815 4.62092L1.11905 4.62002L1.01728 4.62692C0.651199 4.67653 0.369049 4.99032 0.369049 5.37002Z'
        fill='#999999'
      />
    </svg>
  );
};

export default TrendSVG;
