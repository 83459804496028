import React, { useEffect, useRef } from 'react';
import { select, scaleBand, scaleLinear, max, axisBottom, axisLeft } from 'd3';

const data: any = [
  { date: '2021-01-01', plan: 100, actual: 120 },
  { date: '2021-01-02', plan: 200, actual: 180 },
  { date: '2021-01-03', plan: 180, actual: 250 },
  { date: '2021-01-04', plan: 220, actual: 230 },
  { date: '2021-01-05', plan: 150, actual: 290 },
  { date: '2021-01-06', plan: 250, actual: 200 },
  { date: '2021-01-07', plan: 270, actual: 210 },
  { date: '2021-01-08', plan: 190, actual: 260 },
  { date: '2021-01-09', plan: 230, actual: 210 },
  { date: '2021-01-10', plan: 250, actual: 290 },
];

const planColor = 'red';
const actualColor = 'blue';

type BarData = {
  date: string;
  plan: number;
  actual: number;
};

type BarChartPlanActualSalesProps = {
  data: BarData[];
  planColor: string;
  actualColor: string;
};

export default function BarChartPlanActualSales({
  data,
  planColor,
  actualColor,
}: BarChartPlanActualSalesProps) {
  const ref = useRef<any>(null);

  useEffect(() => {
    const svg = select(ref.current);
    svg.selectAll('*').remove();
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const width = 960 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    const x = scaleBand().range([0, width]).padding(0.1);
    const y = scaleLinear().range([height, 0]);

    const g = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    x.domain(data.map(d => d.date));
    y.domain([0, max(data, (d: BarData) => Math.max(d.plan, d.actual))!]);

    g.append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(axisBottom(x));

    g.append('g').call(axisLeft(y));

    g.selectAll('.plan')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'plan')
      .attr('x', (d: BarData) => x(d.date)!)
      .attr('y', (d: BarData) => y(d.plan))
      .attr('width', x.bandwidth() / 2)
      .attr('height', (d: BarData) => height - y(d.plan))
      .attr('fill', planColor);

    g.selectAll('.actual')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'actual')
      .attr('x', (d: BarData) => x(d.date)! + x.bandwidth() / 2)
      .attr('y', (d: BarData) => y(d.actual))
      .attr('width', x.bandwidth() / 2)
      .attr('height', (d: BarData) => height - y(d.actual))
      .attr('fill', actualColor);
  }, [data]);

  return <svg ref={ref} viewBox='0 0 960 500' />;
}
