import moment from 'moment/moment';
import React, { useMemo, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Stack } from '@mui/system';
import { Box, Button, Container, Typography } from '@mui/material';
import { TaskResult } from '../../common/types/Task';
import TrendDownSVG from '../svg/TrendDownSVG';
import TrendSVG from '../svg/TrendSVG';
import TrendUpSVG from '../svg/TrendUpSVG';
import LegendRow from './LegendRow/LegendRow';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right' as const,
    },
    title: {
      display: true,
      text: 'Продажи / тренд / прогноз',
    },
    datalabels: {
      display: false,
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        drag: {
          enabled: true,
        },
        mode: 'xy',
      },
    },
  },
};

const labels = ((): number[] => {
  const array = [];
  const startElement = 1.6;
  let numOfElements = 0;
  while (numOfElements < 33) {
    array.push(startElement + numOfElements);
    numOfElements += 1;
  }
  return array;
})();

export const data = {
  labels,
  datasets: [],
};

type Props = {
  taskResult: TaskResult;
  period: {
    dateFrom: Date | null;
    dateTo: Date | null;
  };
};

const TrendsChart = ({ taskResult, period }: Props) => {
  const [salesEnabled, setSalesEnabled] = useState(true);
  const [salesWithoutNoiseEnabled, setSalesWithoutNoiseEnabled] =
    useState(true);
  const [forecastEnabled, setForecastEnabled] = useState(true);

  const dataset = useMemo(() => {
    const datasets = [];
    if (salesEnabled) {
      datasets.push({
        label: 'Продажи',
        data: taskResult.base_array,
        pointRadius: taskResult.base_array.map((_, index) =>
          index % 14 === 0 ? 3 : 0
        ),
        borderColor: '#4472C4',
        backgroundColor: '#4472C4',
        tension: 0.4,
      });
    }
    if (salesWithoutNoiseEnabled) {
      const data = [
        ...taskResult.data_array.slice(0, taskResult.forecast_index),
        ...Array(taskResult.data_array.length - taskResult.forecast_index).fill(
          undefined
        ),
      ];
      datasets.push({
        label: 'Продажи без шума',
        data,
        pointRadius: data.map((_, index) => (index % 14 === 0 ? 3 : 0)),
        borderColor: '#ED7D31',
        backgroundColor: '#ED7D31',
        tension: 0.4,
      });
    }
    if (forecastEnabled) {
      const data = [
        ...Array(taskResult.forecast_index - 1).fill(undefined),
        ...taskResult.data_array.slice(taskResult.forecast_index - 1),
      ];
      datasets.push({
        label: 'Прогноз',
        data,
        pointRadius: data.map((_, index) => (index % 14 === 0 ? 3 : 0)),
        borderColor: '#FFC000',
        backgroundColor: '#FFC000',
        tension: 0.4,
      });
    }
    return datasets;
  }, [salesEnabled, salesWithoutNoiseEnabled, forecastEnabled]);

  const data: ChartData<'line', any, string> = useMemo(() => {
    return {
      labels: Array.from(Array(taskResult.data_array.length), (_, index) =>
        moment(period.dateFrom).add(index, 'day').format('D.MM')
      ),
      datasets: dataset,
    };
  }, [taskResult, period, dataset]);

  const chart = useRef<any>();

  return (
    <Stack direction='row' spacing={0}>
      <Container sx={{ ml: 0, pl: 0, mr: 0, pr: 0 }}>
        <Line
          options={options as any}
          data={data}
          ref={chart}
          title='Кликните на графике чтоб сбросить зум'
        />
      </Container>
      <Box pt='30px'>
        <LegendRow
          title='Продажи без шума'
          value={salesWithoutNoiseEnabled}
          color='#ED7D31'
          onChange={setSalesWithoutNoiseEnabled}
        />
        <LegendRow
          title='Продажи'
          value={salesEnabled}
          color='#4472C4'
          onChange={setSalesEnabled}
        />
        <LegendRow
          title='Прогноз'
          value={forecastEnabled}
          color='#FFC000'
          onChange={setForecastEnabled}
        />
        <Stack direction='row' spacing={1} alignItems='center' ml={1}>
          {taskResult.trend === -1 ? (
            <TrendDownSVG />
          ) : taskResult.trend === 0 ? (
            <TrendSVG />
          ) : taskResult.trend === 1 ? (
            <TrendUpSVG />
          ) : null}
          <Typography>Тренд</Typography>
        </Stack>
        <Button
          onClick={() => chart.current?.resetZoom()}
          style={{ marginTop: '20px' }}
          size='small'
          variant='outlined'
        >
          Сбросить зум
        </Button>
      </Box>
    </Stack>
  );
};

export default TrendsChart;
