import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import {
  useGetProductAttributeListQuery,
  useUpdateProductAttributeListMutation,
} from '../../../redux/api/productApi/product-attribute';
import { headerCellSX } from '../../../utils/helpers/table';

const Clustering = () => {
  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [list, setList] = useState<
    (ProductAttributeType & { error?: boolean })[]
  >([]);

  const { data, refetch } = useGetProductAttributeListQuery({});
  const [update] = useUpdateProductAttributeListMutation();

  useEffect(() => {
    setList(data?.body as ProductAttributeType[]);
  }, [data]);

  const toggleAttribute = (isActive: boolean, i: number) => {
    const next = [...list];
    if (next?.[i]) {
      next[i] = { ...next[i], isActive };
      setList(next);
      setUpdated(true);
    }
  };

  const setWeight = (weight: number, i: number) => {
    const next = [...list];
    if (next?.[i]) {
      next[i] = { ...next[i], weight, error: !(+weight > 0) };
      setList(next);
      setUpdated(true);
    }
  };

  const save = async () => {
    await update(
      list.map(a => {
        const { error, ...rest } = a;
        return rest;
      })
    ).unwrap();
    refetch();
    setSuccess(true);
    setTimeout(() => setSuccess(false), 1000);
    setUpdated(false);
  };

  const savingDisabled = useMemo(
    () => !updated || list?.some(a => a.error),
    [updated, list]
  );

  return (
    <>
      <MainContainer title='Кластеризация'>
        <TableContainer component={Paper} sx={{ mt: '25px' }}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  Код
                </TableCell>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  Название
                </TableCell>
                <TableCell sx={headerCellSX} width='auto' align='center'>
                  Важность
                </TableCell>
                <TableCell sx={headerCellSX} width='auto' align='center'>
                  Включить
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.length ? (
                list.map((attr, i) => (
                  <TableRow
                    key={attr.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer',
                    }}
                  >
                    <TableCell align='left'>{attr.key}</TableCell>
                    <TableCell align='left'>{attr.value}</TableCell>
                    <TableCell align='center'>
                      <Input
                        error={attr.error}
                        type='number'
                        value={attr.weight}
                        onInput={(e: any) => setWeight(e.target.value, i)}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <Checkbox
                        checked={attr.isActive}
                        onChange={e => toggleAttribute(e.target.checked, i)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align='center' colSpan={4}>
                    Нет данных
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{ marginTop: '20px', display: 'flex', justifyContent: 'end' }}
        >
          <Button variant='outlined' disabled={savingDisabled} onClick={save}>
            Сохранить
          </Button>
        </div>
      </MainContainer>
      <Dialog open={success} onClose={() => setSuccess(false)} maxWidth={false}>
        <DialogTitle>Успешно обновлено!</DialogTitle>
        <DialogActions>
          <Button onClick={() => setSuccess(false)} autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Clustering;
