import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import Filters from './Filters';
import Sorting from './Sorting';

export default function Controls({ isActual = false }: { isActual?: boolean }) {
  return (
    <div>
      <div>Продажи, {isActual ? 'факт' : 'план'}</div>
      <div>
        <IconButton aria-label='settings' size='small'>
          <SettingsIcon fontSize='inherit' />
        </IconButton>
        <Sorting />
        <Filters />
      </div>
    </div>
  );
}
