import { Edit, Upload } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import {
  useAddCameraMutation,
  useGetCameraListQuery,
  useUpdateCameraMutation,
} from '../../../redux/api/clientApi';
import { useUploadVideoMutation } from '../../../redux/api/clientApi/cameras';
import { headerCellSX } from '../../../utils/helpers/table';
import Shop from '../../Reports/components/Shop/Shop';
import './styles.scss';
import { LinkingDialog } from './LinkingDialog';
import { ZoneDialog } from './ZoneDialog';

const AdminCameras = () => {
  const [shop, setShop] = useState<ShopType>();
  const [camera, setCamera] = useState<CameraType>();
  const [prev, setPrev] = useState<CameraType>();
  const [edit, setEdit] = useState<CameraType>();
  const [linking, setLinking] = useState<CameraType>();
  const [zones, setZones] = useState<CameraType>();
  const [cameraId, setCameraId] = useState(0);
  const [fileWarning, setFileWarning] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [add] = useAddCameraMutation();
  const [update] = useUpdateCameraMutation();
  const [upload, { isError, isLoading, isSuccess, reset }] =
    useUploadVideoMutation();

  const { data, refetch } = useGetCameraListQuery(
    { limit: 10, offset: 0, tradePointId: shop?.id },
    { skip: !shop?.id }
  );

  const list = data?.body as CameraType[];

  useEffect(() => {
    if (!prev && edit?.id) {
      setPrev(edit);
    }
  }, [edit, prev]);

  const disabled = useMemo(
    () =>
      !edit ||
      !edit.cameraName ||
      !edit.cameraModel ||
      (prev &&
        edit.cameraName === prev.cameraName &&
        edit.cameraModel === prev.cameraModel),
    [edit, prev]
  );

  const cancel = () => {
    setEdit(undefined);
    setPrev(undefined);
  };

  const save = async () => {
    if (edit) {
      if (edit.id) {
        await update({ id: edit.id, body: edit }).unwrap();
      } else {
        await add(edit).unwrap();
      }
      refetch?.();
      cancel();
    }
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file && cameraId) {
      if (file.type === 'video/mp4' || file.type === 'video/quicktime') {
        const formData = new FormData();
        formData.set('file', file);
        formData.set('camera_id', cameraId.toString());
        await upload(formData).unwrap();
        refetch?.();
        setCameraId(0);
      } else if (cameraId) {
        setFileWarning(true);
      }
    }
  };

  return (
    <>
      <MainContainer title='Управление камерами'>
        <Stack direction='row' spacing={3}>
          <Shop onChange={setShop} />
          <Button
            variant='outlined'
            size='large'
            sx={{ mt: '15px', ml: '15px', height: 56 }}
            onClick={() => setEdit({ tradePointId: shop?.id } as CameraType)}
            disabled={!shop}
          >
            Добавить камеру
          </Button>
        </Stack>
        {!!shop ? (
          <TableContainer component={Paper} sx={{ mt: '25px' }}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellSX} width='40' align='center'>
                    ID
                  </TableCell>
                  <TableCell sx={headerCellSX} width='auto' align='left'>
                    Название
                  </TableCell>
                  <TableCell sx={headerCellSX} width='auto' align='left'>
                    Тип
                  </TableCell>
                  <TableCell sx={headerCellSX} width='60' />
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.length ? (
                  list.map((camera: CameraType) => (
                    <TableRow
                      key={camera.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        cursor: 'pointer',
                      }}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        setCamera(camera);
                      }}
                    >
                      <TableCell align='center'>{camera.id}</TableCell>
                      <TableCell align='left'>{camera.cameraName}</TableCell>
                      <TableCell align='left'>{camera.cameraModel}</TableCell>
                      <TableCell
                        align='center'
                        style={{
                          display: 'flex',
                          flexFlow: 'row nowrap',
                          justifyContent: 'center',
                        }}
                      >
                        <input
                          type='file'
                          style={{ display: 'none' }}
                          ref={inputRef}
                          onChange={e => onFileChange(e)}
                          onClick={e => e.stopPropagation()}
                        />
                        {cameraId === camera?.id && isLoading ? (
                          <Tooltip title='Загрузка' placement='top'>
                            <IconButton onClick={e => e.stopPropagation()}>
                              <CircularProgress color='inherit' size={15} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title='Загрузить видео' placement='top'>
                            <IconButton
                              onClick={e => {
                                e.stopPropagation();
                                setCameraId(camera.id);
                                inputRef.current?.click();
                              }}
                            >
                              <Upload
                                fontSize='small'
                                className='cell-button'
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title='Редактировать' placement='top'>
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              setEdit(camera);
                            }}
                          >
                            <Edit fontSize='small' className='cell-button' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title='Привязка зоны видимости камеры и плана'
                          placement='top'
                        >
                          <Button
                            onClick={e => {
                              e.stopPropagation();
                              setLinking(camera);
                            }}
                            size='small'
                          >
                            Привязка
                          </Button>
                        </Tooltip>
                        <Tooltip title='Зоны непроходимости' placement='top'>
                          <Button
                            onClick={e => {
                              e.stopPropagation();
                              setZones(camera);
                            }}
                            size='small'
                          >
                            Зоны
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align='center' colSpan={4}>
                      Нет данных
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </MainContainer>
      <Dialog
        open={!!camera}
        onClose={() => setCamera(undefined)}
        maxWidth={false}
      >
        <DialogTitle>Камера</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} sx={{ mt: '25px' }}>
            <Table
              sx={{ minWidth: 400, maxWidth: 600 }}
              aria-label='simple table'
            >
              <TableBody>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left' width='120'>
                    Торговая точка
                  </TableCell>
                  <TableCell align='left'>{shop?.name}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left' width='60'>
                    ID торговой точки
                  </TableCell>
                  <TableCell align='left'>{shop?.id}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left' width='60'>
                    ID камеры
                  </TableCell>
                  <TableCell align='left'>{camera?.id}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Название камеры</TableCell>
                  <TableCell align='left'>{camera?.cameraName}</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Тип камеры</TableCell>
                  <TableCell align='left'>{camera?.cameraModel}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCamera(undefined)} autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!edit} onClose={() => setEdit(undefined)} maxWidth={false}>
        <DialogTitle>
          {edit?.id ? 'Редактирование камеры' : 'Добавление камеры'}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} sx={{ mt: '25px' }}>
            <Table
              sx={{ minWidth: 400, maxWidth: 600 }}
              aria-label='simple table'
            >
              <TableBody>
                {edit?.id ? (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell align='left' width='120'>
                      ID камеры
                    </TableCell>
                    <TableCell align='left'>{edit?.id}</TableCell>
                  </TableRow>
                ) : null}
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left' width='120'>
                    Название камеры
                  </TableCell>
                  <TableCell align='left'>
                    <TextField
                      hiddenLabel
                      variant='standard'
                      size='small'
                      value={edit?.cameraName}
                      onChange={e => {
                        setEdit(prev => ({
                          ...(prev || ({} as CameraType)),
                          cameraName: e.target.value,
                        }));
                      }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align='left'>Тип камеры</TableCell>
                  <TableCell align='left'>
                    <TextField
                      hiddenLabel
                      variant='standard'
                      size='small'
                      value={edit?.cameraModel}
                      onChange={e => {
                        setEdit(prev => ({
                          ...(prev || ({} as CameraType)),
                          cameraModel: e.target.value,
                        }));
                      }}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} autoFocus>
            Отмена
          </Button>
          <Button onClick={save} autoFocus disabled={disabled}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <LinkingDialog
        camera={linking}
        close={() => setLinking(undefined)}
        shopId={shop?.id}
      />
      {zones && (
        <ZoneDialog
          camera={zones}
          close={() => setZones(undefined)}
          shopId={shop?.id}
        />
      )}
      <Dialog open={fileWarning} onClose={() => setFileWarning(false)}>
        <DialogTitle>Видео должно быть в формате mp4 или vob</DialogTitle>
        <DialogActions>
          <Button onClick={() => setFileWarning(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isError || isSuccess} onClose={reset}>
        <DialogTitle>
          {isError ? 'Ошибка загрузки видео' : ''}
          {isSuccess ? 'Видео успешно загружено' : ''}
        </DialogTitle>
        <DialogActions>
          <Button onClick={reset}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminCameras;
