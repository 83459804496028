import { apiSlice } from './api/apiSlice';
import { productApi } from './api/productApi';
import { taskApi } from './api/taskApi';
import authSlice from './slices/authSlice';
import productSlice from './slices/productSlice';

const reducers = {
  [apiSlice.reducerPath]: apiSlice.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [taskApi.reducerPath]: taskApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [productSlice.name]: productSlice.reducer,
};

export default reducers;
