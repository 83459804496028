import React from 'react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import { useGetTradePointListQuery } from '../../../../redux/api/clientApi';

interface Props {
  onChange: (shop: any) => void;
}

const Shop = ({ onChange }: Props) => {
  const { data: tradePoints, isError } = useGetTradePointListQuery({
    limit: 100,
    offset: 0,
    clientId: 2,
  });

  const options = tradePoints?.body || [];
  return (
    <FormControl error={isError} sx={{ minWidth: 200 }}>
      <Autocomplete
        disablePortal
        id='shop-autocomplete'
        placeholder='Торговая точка'
        getOptionLabel={option => option?.name}
        options={options}
        sx={{ minWidth: 200 }}
        onChange={(_, value) => onChange(value)}
        renderInput={params => <TextField {...params} label='Торговая точка' />}
      />
      {isError && (
        <FormHelperText error={isError}>
          Ошибка получения данных о торговых точках
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Shop;
