import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { authApi } from './api/authApi';
import { clientApi } from './api/clientApi';
import { productApi } from './api/productApi';
import { taskApi } from './api/taskApi';

const getMiddleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
  getDefaultMiddleware({}).concat([
    authApi.middleware,
    clientApi.middleware,
    productApi.middleware,
    taskApi.middleware,
  ]);

export default getMiddleware;
