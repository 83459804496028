import React, { useEffect, useRef } from 'react';
import {
  select,
  scaleBand,
  scaleLinear,
  max,
  axisBottom,
  axisLeft,
  stack,
} from 'd3';

const data: Bar[] = [
  { date: '01-01-2024', expensive: 300, middle: 300, cheap: 300 },
  { date: '02-01-2024', expensive: 240, middle: 400, cheap: 190 },
  { date: '03-01-2024', expensive: 310, middle: 250, cheap: 220 },
  { date: '04-01-2024', expensive: 250, middle: 310, cheap: 290 },
  { date: '05-01-2024', expensive: 270, middle: 260, cheap: 310 },
  { date: '06-01-2024', expensive: 190, middle: 230, cheap: 100 },
  { date: '07-01-2024', expensive: 220, middle: 240, cheap: 250 },
  { date: '08-01-2024', expensive: 130, middle: 190, cheap: 260 },
  { date: '09-01-2024', expensive: 160, middle: 210, cheap: 270 },
  { date: '10-01-2024', expensive: 190, middle: 230, cheap: 280 },
];

const color = '#d497d1';

type Bar = {
  date: string;
  expensive: number;
  middle: number;
  cheap: number;
};

// type BarChartPlanActualSalesProps = {
//     data: BarData[];
//     planColor: string;
//     actualColor: string;
// };

// export default function BarChartPlanActualSales({data, planColor, actualColor}: BarChartPlanActualSalesProps) {
export default function BarChartStackedSales() {
  const ref = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const svg = select(ref.current);
    svg.selectAll('*').remove();

    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const width = 960 - margin.left - margin.right;
    const height = 500 - margin.top - margin.bottom;

    const x = scaleBand().range([0, width]).padding(0.1);
    const y = scaleLinear().range([height, 0]);

    const g = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const stackGenerator = stack().keys(['expensive', 'middle', 'cheap']);
    const stackedData = stackGenerator(data as any);

    x.domain(data.map(d => d.date));
    y.domain([0, max(stackedData, d => max(d, d => d[1]))!]);

    g.append('g')
      .attr('transform', 'translate(0,' + height + ')')
      .call(axisBottom(x));

    g.append('g').call(axisLeft(y));

    g.selectAll('.series')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('class', 'series')
      .attr('fill', (d, i) => ['#d73027', '#fc8d59', '#fee08b'][i])
      .selectAll('rect')
      .data(d => d)
      .enter()
      .append('rect')
      .attr('x', (d, i) => x(d.data.date as unknown as string)!)
      .attr('y', d => y(d[1]))
      .attr('height', d => y(d[0]) - y(d[1]))
      .attr('width', x.bandwidth());
  }, [data]);

  return <svg ref={ref} viewBox='0 0 960 500' />;
}
