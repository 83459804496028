import { Delete, Edit } from '@mui/icons-material';
import { Alert, IconButton, Snackbar, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { getPointsFromStr, pointsToStr } from '../../../../utils/coords';
import styles from './ImageComponent.module.scss';

const maxWidth = window.innerWidth * 0.4;
const maxHeight = window.innerHeight * 0.4;

const cornerNames = ['A', 'B', 'C', 'D'];

const newColor = '#55ff00';
const newLetterFillColor = '#ffffff';
const newLetterStrokeColor = '#1f1f1f';

type ImageComponentProps = {
  setPoints: (v: string) => void;
  title: string;
  url: string;
  points: string;
};

export const ImageComponent = ({
  points,
  setPoints,
  title,
  url,
}: ImageComponentProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [drawing, setDrawing] = useState(false);
  const [polygon, setPolygon] = useState<PointType[]>();
  const [ratio, setRatio] = useState(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvas = canvasRef.current;
  const ctxRef = useRef<CanvasRenderingContext2D | undefined>();
  const imgRef = useRef<HTMLImageElement>();
  const ctx = ctxRef.current;
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (imageLoaded && ratio && !polygon) {
      const list = getPointsFromStr(points, ratio);
      if (list.length) {
        setPolygon(list);
        draw(list, false);
      }
    }
  }, [points, imageLoaded, ratio, polygon]);

  useEffect(() => {
    if (url) {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        imgRef.current = img;
        drawImage();
        setImageLoaded(true);
      };
      img.onerror = () => {
        setImageLoaded(false);
        setPoints?.('');
        imgRef.current = undefined;
      };
    }
    return () => {
      setImageLoaded(false);
    };
  }, [url]);

  const drawImage = () => {
    const img = imgRef.current;
    if (!img) {
      return;
    }
    let width: number;
    let height: number;
    let ratio = 1;
    // if (img.width > maxWidth) {
    //   width = maxWidth;
    //   ratio = width / img.width;
    //   height = ratio * img.height;
    // } else if (img.height > maxHeight) {
    //   height = maxHeight;
    //   ratio = height / img.height;
    //   width = ratio * img.width;
    // } else {
    //   height = img.height;
    //   width = img.width;
    // }
    height = img.height;
    width = img.width;
    if (img.width > maxWidth || img.height > maxHeight) {
      if (img.width > maxWidth) {
        width = maxWidth;
        ratio = width / img.width;
        height = ratio * img.height;
      } else if (img.height > maxHeight) {
        height = maxHeight;
        ratio = height / img.height;
        width = ratio * img.width;
      }
      // } else {
      //   height = img.height;
      //   width = img.width;
    }
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = width;
      canvas.height = height;
      setRatio(ratio);
      ctxRef.current?.clearRect(0, 0, canvas.width, canvas.height);
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctxRef.current = ctx;
    }
  };

  const lineIntersects = (
    p0: PointType,
    p1: PointType,
    p2: PointType,
    p3: PointType
  ) => {
    let s1_x, s1_y, s2_x, s2_y: number;
    s1_x = p1.x - p0.x;
    s1_y = p1.y - p0.y;
    s2_x = p3.x - p2.x;
    s2_y = p3.y - p2.y;
    let s, t: number;
    s =
      (-s1_y * (p0.x - p2.x) + s1_x * (p0.y - p2.y)) /
      (-s2_x * s1_y + s1_x * s2_y);
    t =
      (s2_x * (p0.y - p2.y) - s2_y * (p0.x - p2.x)) /
      (-s2_x * s1_y + s1_x * s2_y);
    return s >= 0 && s <= 1 && t >= 0 && t <= 1;
  };

  const point = (x: number, y: number) => {
    if (ctx) {
      // const color = subShelf?.coords ? emptyPlaceColor : sectionColor;
      ctx.fillStyle = newColor;
      ctx.strokeStyle = newColor;
      ctx.fillRect(x - 2, y - 2, 4, 4);
      ctx.moveTo(x, y);
    }
  };

  const draw = (list: PointType[], update = true) => {
    if (!ctx || !ratio) {
      return;
    }
    const end = list?.length === 4;

    ctx.lineWidth = 3;
    ctx.strokeStyle = newColor;
    ctx.lineCap = 'square';
    ctx.beginPath();

    for (let i = 0; i < list.length; i++) {
      if (i == 0) {
        ctx.moveTo(list[i].x, list[i].y);
        end || point(list[i].x, list[i].y);
      } else {
        ctx.lineTo(list[i].x, list[i].y);
        end || point(list[i].x, list[i].y);
      }
    }
    if (end) {
      ctx.lineTo(list[0].x, list[0].y);
      ctx.closePath();
      ctx.strokeStyle = newColor;
      setDrawing(false);
      if (update) {
        setPoints(pointsToStr(list, ratio));
      }
    }
    ctx.stroke();

    if (ctx && list.length <= 4) {
      ctx.font = 'bold 30px verdana, sans-serif';
      ctx.textAlign = 'center';
      ctx.lineWidth = 1;
      ctx.fillStyle = newLetterFillColor;
      ctx.strokeStyle = newLetterStrokeColor;
      for (let i = 0; i < list.length; i++) {
        const x = list[i].x;
        const y = list[i].y;
        ctx.fillText(cornerNames[i], x, y - 20);

        ctx.strokeText(cornerNames[i], x, y - 20);
      }
    }
  };

  const checkIntersect = (polygon: PointType[], x: number, y: number) => {
    if (polygon.length < 3) {
      return false;
    }
    const p2 = {
      x: polygon[polygon.length - 1].x,
      y: polygon[polygon.length - 1].y,
    } as PointType;
    const p3 = { x, y } as PointType;

    for (let i = 0; i < polygon.length - 1; i++) {
      const p0 = { x: polygon[i].x, y: polygon[i].y };
      const p1 = {
        x: polygon[i + 1].x,
        y: polygon[i + 1].y,
      };
      if (p1.x == p2.x && p1.y == p2.y) {
        continue;
      }
      if (p0.x == p3.x && p0.y == p3.y) {
        continue;
      }
      if (lineIntersects(p0, p1, p2, p3)) {
        return true;
      }
    }
    return false;
  };

  const onClick = (event: any) => {
    if (!drawing) {
      return;
    }
    let rect, x, y;
    if (!canvas) return;
    const list = polygon || [];
    rect = canvas.getBoundingClientRect();
    x = event.clientX - rect.left;
    y = event.clientY - rect.top;
    if (checkIntersect(list, x, y)) {
      setAlertMessage('Стороны полигона не должны пересекаться');
      return;
    }
    list.push({ x, y });
    setPolygon([...list]);
    draw(list);
  };

  const create = () => {
    setDrawing(true);
  };

  const remove = () => {
    setPolygon(undefined);
    setPoints('');
    drawImage();
  };

  return (
    <div style={{ position: 'relative', minWidth: '300px' }}>
      {drawing || !imageLoaded ? null : polygon ? (
        <Tooltip title='Удалить' placement='top'>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              remove();
            }}
            className={styles.button}
          >
            <Delete fontSize='small' />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Создать' placement='top'>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              create();
            }}
            className={styles.button}
          >
            <Edit fontSize='small' />
          </IconButton>
        </Tooltip>
      )}
      <canvas
        style={{ display: url && imageLoaded ? 'block' : 'none' }}
        title={title}
        ref={canvasRef}
        onClick={onClick}
      />
      {!url || (!imageLoaded && 'Изображение отсутствует')}
      <Snackbar
        open={!!alertMessage}
        autoHideDuration={3000}
        onClose={() => setAlertMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setAlertMessage('')}
          severity='error'
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
