import { Delete } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Tooltip,
} from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { baseUrl } from '../../../../redux/api/apiSlice';
import {
  useGetBlockingPolygonsQuery,
  useGetCameraPolygonQuery,
  useUpdateBlockingPolygonsMutation,
} from '../../../../redux/api/clientApi/cameras';
import { getPointsFromStr, pointsToStr } from '../../../../utils/coords';

type LinkingDialogPropsType = {
  close: () => void;
  camera?: CameraType;
  shopId?: number;
};

const maxWidth = window.innerWidth * 0.6;
const maxHeight = window.innerHeight * 0.7;

const cornerNames = ['A', 'B', 'C', 'D'];

const color = '#07e4ff';
const newColor = '#55ff00';
const newLetterFillColor = '#ffffff';
const newLetterStrokeColor = '#1f1f1f';

export const ZoneDialog = ({ camera, close }: LinkingDialogPropsType) => {
  const [cameraFrame, setCameraFrame] = useState<PointType[]>();
  const [blockingZones, setBlockingZones] = useState<PointType[][]>();
  const [newZone, setNewZone] = useState<PointType[]>();
  const [drawing, setDrawing] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [ratio, setRatio] = useState(0);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvas = canvasRef.current;
  const ctxRef = useRef<CanvasRenderingContext2D | undefined>();
  const imgRef = useRef<HTMLImageElement>();
  const ctx = ctxRef.current;

  const { data, refetch } = useGetBlockingPolygonsQuery(
    { id: camera?.id },
    { skip: !camera?.id }
  );
  const [update] = useUpdateBlockingPolygonsMutation();

  const { data: frameData } = useGetCameraPolygonQuery(
    { id: camera?.id },
    { skip: !camera?.id }
  );

  useEffect(() => {
    if (imageLoaded && ratio && frameData?.body?.polygon) {
      const polygon = getPointsFromStr(frameData.body.polygon, ratio);
      drawCameraFrame(polygon);
      setCameraFrame(polygon);
    }
  }, [imageLoaded, ratio, frameData]);

  useEffect(() => {
    if (imageLoaded && ratio && data?.body?.polygons?.length) {
      setBlockingZones(
        data.body.polygons.map((str: string) => {
          const points = getPointsFromStr(str, ratio);
          drawZones(points);
          return points;
        })
      );
    }
  }, [imageLoaded, ratio, frameData]);

  useEffect(() => {
    const url = camera?.id
      ? `${baseUrl}/clientservice/camera/${camera.id}/camera_frame`
      : '';
    if (url) {
      setImageLoading(true);
      const img = new Image();
      img.src = url;
      img.onload = () => {
        imgRef.current = img;
        setImageLoading(false);
        setImageLoaded(true);
        drawImage();
      };
      img.onerror = () => {
        setImageLoading(false);
        setImageLoaded(false);
      };
    } else {
      setImageLoading(false);
      setImageLoaded(false);
    }
    return () => {
      setImageLoading(false);
      setImageLoaded(false);
    };
  }, [camera?.id]);

  const drawImage = () => {
    const img = imgRef.current;
    if (!img) {
      return;
    }
    let width: number;
    let height: number;
    let ratio = 1;
    // if (img.width > maxWidth) {
    //   width = maxWidth;
    //   ratio = width / img.width;
    //   height = ratio * img.height;
    // } else if (img.height > maxHeight) {
    //   height = maxHeight;
    //   ratio = height / img.height;
    //   width = ratio * img.width;
    // } else {
    //   height = img.height;
    //   width = img.width;
    // }
    height = img.height;
    width = img.width;
    if (img.width > maxWidth || img.height > maxHeight) {
      if (img.width > maxWidth) {
        width = maxWidth;
        ratio = width / img.width;
        height = ratio * img.height;
      }
      if (img.height > maxHeight) {
        height = maxHeight;
        ratio = height / img.height;
        width = ratio * img.width;
      }
      // } else {
      //   height = img.height;
      //   width = img.width;
    }

    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = width;
      canvas.height = height;
      setRatio(ratio);
      // ratioRef.current = ratio;
      ctxRef.current?.clearRect(0, 0, canvas.width, canvas.height);
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctxRef.current = ctx;
    }
  };

  const lineIntersects = (
    p0: PointType,
    p1: PointType,
    p2: PointType,
    p3: PointType
  ) => {
    let s1_x, s1_y, s2_x, s2_y: number;
    s1_x = p1.x - p0.x;
    s1_y = p1.y - p0.y;
    s2_x = p3.x - p2.x;
    s2_y = p3.y - p2.y;
    let s, t: number;
    s =
      (-s1_y * (p0.x - p2.x) + s1_x * (p0.y - p2.y)) /
      (-s2_x * s1_y + s1_x * s2_y);
    t =
      (s2_x * (p0.y - p2.y) - s2_y * (p0.x - p2.x)) /
      (-s2_x * s1_y + s1_x * s2_y);
    return s >= 0 && s <= 1 && t >= 0 && t <= 1;
  };

  const point = (x: number, y: number, color: string) => {
    if (ctx) {
      ctx.fillStyle = color;
      ctx.strokeStyle = color;
      ctx.fillRect(x - 2, y - 2, 4, 4);
      ctx.moveTo(x, y);
    }
  };

  const drawCameraFrame = (list: PointType[] = []) => {
    const ctx = ctxRef.current;
    if (!ctx) {
      return;
    }
    ctx.lineWidth = 3;
    ctx.strokeStyle = newColor;
    ctx.lineCap = 'square';
    ctx.beginPath();
    for (let i = 0; i < list.length; i++) {
      if (i == 0) {
        ctx.moveTo(list[i].x, list[i].y);
        // end || point(list[i].x, list[i].y, newColor);
      } else {
        ctx.lineTo(list[i].x, list[i].y);
        // end || point(list[i].x, list[i].y, newColor);
      }
    }
    ctx.lineTo(list[0].x, list[0].y);
    ctx.closePath();
    ctx.strokeStyle = newColor;
    ctx.stroke();
    if (ctx && list.length <= 4) {
      ctx.font = 'bold 30px verdana, sans-serif';
      ctx.textAlign = 'center';
      ctx.lineWidth = 1;
      ctx.fillStyle = newLetterFillColor;
      ctx.strokeStyle = newLetterStrokeColor;
      for (let i = 0; i < list.length; i++) {
        const x = list[i].x;
        const y = list[i].y;
        ctx.fillText(cornerNames[i], x, y - 20);
        ctx.strokeText(cornerNames[i], x, y - 20);
      }
    }
  };

  const drawZones = (list: PointType[]) => {
    const ctx = ctxRef.current;
    if (!ctx) {
      return;
    }
    ctx.lineWidth = 3;
    ctx.strokeStyle = color;
    ctx.lineCap = 'square';
    ctx.beginPath();
    for (let i = 0; i < list.length; i++) {
      if (i == 0) {
        ctx.moveTo(list[i].x, list[i].y);
        // end || point(list[i].x, list[i].y, color);
      } else {
        ctx.lineTo(list[i].x, list[i].y);
        // end || point(list[i].x, list[i].y, color);
      }
    }
    ctx.lineTo(list[0].x, list[0].y);
    ctx.closePath();
    ctx.stroke();
  };

  const draw = (list?: PointType[]) => {
    const ctx = ctxRef.current;
    if (!ctx || !list) {
      return;
    }

    ctx.lineWidth = 3;
    ctx.strokeStyle = color;
    ctx.lineCap = 'square';
    ctx.beginPath();

    const end = list?.length === 4;
    for (let i = 0; i < list.length; i++) {
      if (i == 0) {
        ctx.moveTo(list[i].x, list[i].y);
        end || point(list[i].x, list[i].y, color);
      } else {
        ctx.lineTo(list[i].x, list[i].y);
        end || point(list[i].x, list[i].y, color);
      }
    }
    if (end) {
      ctx.lineTo(list[0].x, list[0].y);
      ctx.closePath();
      setDrawing(false);
      setBlockingZones([...(blockingZones || []), list]);
      setNewZone(undefined);
    }
    ctx.stroke();
  };

  const checkIntersect = (polygon: PointType[], x: number, y: number) => {
    if (polygon.length < 3) {
      return false;
    }
    const p2 = {
      x: polygon[polygon.length - 1].x,
      y: polygon[polygon.length - 1].y,
    } as PointType;
    const p3 = { x, y } as PointType;

    for (let i = 0; i < polygon.length - 1; i++) {
      const p0 = { x: polygon[i].x, y: polygon[i].y };
      const p1 = {
        x: polygon[i + 1].x,
        y: polygon[i + 1].y,
      };
      if (p1.x == p2.x && p1.y == p2.y) {
        continue;
      }
      if (p0.x == p3.x && p0.y == p3.y) {
        continue;
      }
      if (lineIntersects(p0, p1, p2, p3)) {
        return true;
      }
    }
    return false;
  };

  const onClick = (event: any) => {
    if (!drawing || !canvas) {
      return;
    }
    let rect, x, y;
    const list = newZone || [];
    rect = canvas.getBoundingClientRect();
    x = event.clientX - rect.left;
    y = event.clientY - rect.top;
    if (checkIntersect(list, x, y)) {
      setAlertMessage('Стороны не должны пересекаться');
      return;
    }
    list.push({ x, y });
    setNewZone([...list]);
    draw(list);
  };

  const create = () => {
    setDrawing(true);
  };

  const remove = (i: number) => {
    const next = blockingZones?.filter((_, k) => i !== k);
    drawImage();
    drawCameraFrame(cameraFrame);
    next?.forEach(polygon => {
      drawZones(polygon);
    });
    setBlockingZones(next);
  };

  const updateBlockingZones = async () => {
    if (camera?.id && blockingZones?.length && ratio) {
      await update({
        id: camera.id,
        body: {
          polygons: blockingZones.map(points => pointsToStr(points, ratio)),
        },
      }).unwrap();
      close?.();
      refetch();
    }
  };

  return (
    <Dialog open={!!camera} onClose={close} maxWidth={false}>
      <DialogTitle>Зоны непроходимости</DialogTitle>
      <DialogContent>
        {imageLoading || imageLoaded ? null : (
          <div>Отсутствует изображение с камеры</div>
        )}
        <div
          style={{
            display: !imageLoaded || imageLoading ? 'none' : 'grid',
            gridTemplateColumns: 'min-content 200px',
            gridTemplateRows: 'min-content',
            minWidth: '800px',
            gap: '10px 30px',
          }}
        >
          <div>
            <canvas
              style={{ display: imageLoaded ? 'block' : 'none' }}
              ref={canvasRef}
              onClick={onClick}
            />
          </div>
          <div>
            {blockingZones?.length ? (
              <div
                style={{
                  display: 'grid',
                  gridAutoRows: 'min-content',
                  gridTemplateColumns: 'max-content min-content',
                  gap: '5px 20px',
                }}
              >
                {blockingZones?.map((_, i) => (
                  <Fragment key={i}>
                    <div style={{ alignSelf: 'center' }}>Зона {i + 1}</div>
                    <Tooltip title='Удалить' placement='top'>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          remove(i);
                        }}
                      >
                        <Delete fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </Fragment>
                ))}
              </div>
            ) : null}
            {drawing ? (
              'Отметьте точками зону'
            ) : (
              <Button
                onClick={e => {
                  e.stopPropagation();
                  create();
                }}
                size='small'
                style={{ marginTop: '10px' }}
              >
                Добавить зону
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          Отмена
        </Button>
        <Button
          onClick={updateBlockingZones}
          autoFocus
          disabled={drawing || !blockingZones?.length}
        >
          Сохранить
        </Button>
      </DialogActions>
      <Snackbar
        open={!!alertMessage}
        autoHideDuration={3000}
        onClose={() => setAlertMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setAlertMessage('')}
          severity='error'
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
