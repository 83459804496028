import React from 'react';
import { Box, Checkbox, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  value: boolean;
  color: string;
  onChange: (value: boolean) => void;
}

const LegendRow = ({ title, value, color, onChange }: Props) => {
  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Checkbox
        defaultChecked
        value={value}
        onChange={(_, checked) => onChange(checked)}
        sx={{
          mr: 0,
          pr: 0,
        }}
      />
      <Box
        sx={{
          width: '15px',
          height: '15px',
          backgroundColor: color,
        }}
      />
      <Typography>{title}</Typography>
    </Stack>
  );
};

export default LegendRow;
