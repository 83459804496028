import React, {useState} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {headerCellSX} from '../../../../../utils/helpers/table';
import moment from 'moment';
import MarketInfo from '../MarketInfo/MarketInfo';

const MarketsTable = ({clusters}: {clusters: ShopClusterType[]}) => {
  const [cluster, setCluster] = useState<ShopClusterType>();

  const openDialog = (event: React.MouseEvent<HTMLElement>, cluster: ShopClusterType) => {
    event.stopPropagation();
    setCluster(cluster);
  };

  const closeDialog = () => setCluster(undefined);

  return (
    <>
      <TableContainer component={Paper} sx={{mt: '25px'}}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellSX} align="left">Кластер</TableCell>
              <TableCell sx={headerCellSX} align="left">Топ категория</TableCell>
              <TableCell sx={headerCellSX} align="center">Дата расчёта</TableCell>
              <TableCell sx={headerCellSX} align="center">Количество чеков</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clusters?.length ? clusters.map((c, i) => (
              <TableRow
                key={i}
                selected={cluster?.id === c.id}
                sx={{cursor: 'pointer'}}
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  openDialog(event, c)
                }
              >
                <TableCell align="left">{c.cluster_name}</TableCell>
                <TableCell align="left">{c.top_category_name}</TableCell>
                <TableCell align="center">{moment(c.cluster_date).format('DD.MM.YY')}</TableCell>
                <TableCell align="center">{c.sales_count}</TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell align="center" colSpan={4}>Нет данных</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <MarketInfo cluster={cluster as any} onClose={closeDialog}/>
    </>
  );
};

export default MarketsTable;
