import React from 'react';

import { Routes, Route } from 'react-router-dom';
import { LoginPage } from '../pages/Login';
import Clustering from '../pages/Admin/Clustering';
import TradePoints from '../pages/Admin/TradePoints';
import Ratings from '../pages/Reports/Ratings';
import Analytics from '../components/TradeAnalytics';
import EmptyShelves from '../pages/Reports/EmptyShelves';
import Lifts from '../pages/Reports/Lifts';
import MarketProfile from '../pages/Reports/MarketProfile';
import Shelves from '../pages/Shelves';
import HeatMap from '../pages/Reports/HeatMap';
import Layout from '../components/Layout/Layout';
import RequireAuth from './RequireAuth/RequireAuth';
import Public from '../components/Public/Public';
import AdminCameras from '../pages/Admin/AdminCameras';
import { ShelfManager } from '../pages/Admin/AdminShelves';
import CategoryClusters from '../pages/Reports/CategoryClusters';
import ExcludedGoods from '../pages/Admin/ExcludedGoods';
import Sales from '../pages/sales/Sales';
import TradePointsSales from '../pages/sales/TradePointsSales';
import AvailableProducts from '../pages/sales/AvailableProducts';
import LostProfit from '../pages/sales/LostProfit';

function Router() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Public />} />
        <Route path='login' element={<LoginPage />} />
        <Route element={<RequireAuth />}>
          <Route path='sales/lost-profit' element={<LostProfit />} />
          <Route
            path='sales/available-products'
            element={<AvailableProducts />}
          />
          <Route path='sales/trade-points' element={<TradePointsSales />} />
          <Route path='sales' element={<Sales />} />
          <Route path='analytics' element={<Analytics />} />
          <Route path='ratings' element={<Ratings />} />
          <Route path='shelves' element={<Shelves />} />
          <Route path='lifts' element={<Lifts />} />
          <Route path='market-profile' element={<MarketProfile />} />
          <Route path='empty-shelves' element={<EmptyShelves />} />
          <Route path='heat-map' element={<HeatMap />} />
          <Route path='admin-shelves' element={<ShelfManager />} />
          <Route path='admin-cameras' element={<AdminCameras />} />
          <Route path='excluded-goods' element={<ExcludedGoods />} />
          <Route path='cluster-goods' element={<CategoryClusters />} />
          <Route path='admin-trade-points' element={<TradePoints />} />
          <Route path='admin-clustering' element={<Clustering />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Router;
