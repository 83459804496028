import { InfoOutlined } from '@mui/icons-material';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  getTableHeaderAlign,
  headerCellSX,
} from '../../../../../../../utils/helpers/table';

interface Props {
  info: any[];
}

const headers = ['Пара товаров', '', 'Поддержка', 'Достоверность'];

const TripleInfoTable = ({ info }: Props) => {
  return (
    <TableContainer component={Paper} sx={{ mt: '30px', mb: '30px' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            {headers.map((item, index) => (
              <TableCell
                key={item}
                sx={headerCellSX}
                align={getTableHeaderAlign(index)}
              >
                <Stack direction='row'>
                  <Typography sx={{ fontWeight: '700' }}>{item}</Typography>
                  {item === 'Поддержка' && (
                    <Tooltip
                      title='Чеков с товаром/Всего чеков'
                      placement='top'
                    >
                      <InfoOutlined
                        fontSize='small'
                        sx={{
                          ml: 1,
                        }}
                      />
                    </Tooltip>
                  )}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {info?.length ?
            info.map((item: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell align='left'>{item.name_1 || ''}</TableCell>
                <TableCell align='left'>{item.name_2 || ''}</TableCell>
                <TableCell align='center' width='100px'>{Math.round(item.support * 100000) / 100000}</TableCell>
                <TableCell align='center' width='100px'>{Math.round(item.confidence * 10000) / 100}%</TableCell>
              </TableRow>
            ))
            : (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell colSpan={4} align='center'>нет данных</TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TripleInfoTable;
