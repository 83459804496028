import moment from 'moment/moment';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { baseUrl } from '../../../../../redux/api/apiSlice';
import { useGetShelfHistoryQuery } from '../../../../../redux/api/clientApi/shelves';
import { headerCellSX } from '../../../../../utils/helpers/table';
import { History, Image } from '@mui/icons-material';
import EmptyShelfInfo from '../EmptyShelf/EmptyShelfInfo';
import EmptyShelfItem from '../EmptyShelf/EmptyShelfItem';

interface Props {
  list?: EmptyShelf[];
}

const EmptyShelvesTable = ({ list }: Props) => {
  const [shelf, setEmptyShelf] = useState<EmptyShelf>();
  const [historyShelfId, setHistoryShelfId] = useState(0);
  const [historyShelfSrc, setHistoryShelfSrc] = useState('');

  const closeEmptyShelveDialog = () => setEmptyShelf(undefined);

  const { data } = useGetShelfHistoryQuery(
    { id: historyShelfId },
    { skip: !historyShelfId }
  );
  const history = data as unknown as any[];

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: '25px' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellSX} width='40' align='center'>
                ID
              </TableCell>
              <TableCell sx={headerCellSX} width='auto' align='left'>
                Торговая точка
              </TableCell>
              <TableCell sx={headerCellSX} width='auto' align='left'>
                Полка
              </TableCell>
              <TableCell sx={headerCellSX} width='30' align='center'>
                Фото
              </TableCell>
              <TableCell sx={headerCellSX} width='30' align='center'>
                История
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map(shelf => (
              <TableRow
                key={shelf.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: shelf?.emptyPlaces ? '#ffcece' : '',
                }}
              >
                <TableCell align='center'>{shelf.id}</TableCell>
                <TableCell align='left'>{shelf.tradePointName}</TableCell>
                <TableCell align='left'>{shelf.name}</TableCell>
                {shelf.emptyPlaces ? (
                  <TableCell
                    align='center'
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      setEmptyShelf(shelf);
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Image />
                  </TableCell>
                ) : (
                  <TableCell />
                )}
                <TableCell align='center'>
                  <IconButton
                    aria-label='История'
                    onClick={() => setHistoryShelfId(shelf?.id)}
                  >
                    <History />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmptyShelfInfo
        shelve={shelf as EmptyShelf}
        open={!!shelf}
        onClose={closeEmptyShelveDialog}
      />
      <Dialog onClose={() => setHistoryShelfId(0)} open={!!historyShelfId}>
        <DialogTitle>История</DialogTitle>
        <Paper sx={{ width: '500px' }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellSX} align='center' width={150}>
                    Дата
                  </TableCell>
                  <TableCell sx={headerCellSX} align='center' width={150}>
                    Фото
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history?.length ? (
                  history.map((v: any, i) => (
                    <TableRow key={i}>
                      <TableCell align='center' width={150}>
                        {v.datetime
                          ? moment(v.datetime).format('DD.MM.YYYY hh:mm')
                          : '-'}
                      </TableCell>
                      <TableCell
                        align='center'
                        width={150}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                          setHistoryShelfSrc(
                            v?.id
                              ? `${baseUrl}/shelfservice/shelf-history-image?id=${v?.id}`
                              : ''
                          );
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Image />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align='center' width='100%' colSpan={2}>
                      Нет данных
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <DialogActions>
          <Button onClick={() => setHistoryShelfId(0)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      {historyShelfSrc ? (
        <Dialog
          open={true}
          onClose={() => setHistoryShelfSrc('')}
          scroll='paper'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <DialogContent
            dividers
            sx={{
              maxWidth: '400px',
            }}
          >
            <Box sx={{ mt: 2, maxWidth: '500px' }}>
              <img
                src={historyShelfSrc}
                alt='фото'
                style={{ maxWidth: '100%' }}
                onError={() => setHistoryShelfSrc('')}
              />
            </Box>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};

export default EmptyShelvesTable;
