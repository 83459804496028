import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { PreviewImage } from './components';
import styles from './styles';

interface Props {
  image: string;
  onRemove: () => void;
}

const PreviewItem = ({ image, onRemove }: Props) => (
  <Box sx={styles.wrapper}>
    <Box sx={styles.container}>
      <PreviewImage src={image} alt='Preview' />
    </Box>
    <Close onClick={onRemove} sx={styles.icon} color='primary' />
  </Box>
);

export default PreviewItem;
