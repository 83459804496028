import React, { useState } from 'react';
import { ProductCategory } from '../../common/types';
import MainContainer from '../../components/MainContainer/MainContainer';
import { useGetProductListQuery } from '../../redux/api/productApi';
import Categories from '../Reports/components/Categories';
import ProductsAndCategoriesCard from './components/ProductsTable/ProductsAndCategoriesCard/ProductsAndCategoriesCard';
import ProductsTable from './components/ProductsTable/ProductsTable';

const ProductsAndCategories = () => {
  const [category, setCategory] = useState<ProductCategory | undefined>();

  const skip = !category;
  const { data: products } = useGetProductListQuery(
    {
      limit: 1000,
      offset: 0,
      categoryId: category?.id || 0,
    },
    { skip }
  );

  return (
    <MainContainer title='Анализ каталога'>
      <Categories onChange={setCategory} category={category} />
      {products && products.body?.length > 0 && (
        <ProductsTable products={products.body} />
      )}
      {category /*&& category.level < 4*/ && (
        <ProductsAndCategoriesCard
          open={!!category}
          name={category.name}
          categoryId={category.id}
          productId={null}
          categoryLevel={category.level}
          onClose={() => setCategory(undefined)}
        />
      )}
    </MainContainer>
  );
};

export default ProductsAndCategories;
