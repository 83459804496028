import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Product } from '../../../common/types';
import { useGetProductCharacteristicQuery } from '../../../redux/api/productApi';
import {
  useGetCategoriesQuery,
  useGetClusterProductsQuery,
  useGetNonClusterProductsQuery,
  useGetCalcOneCategoryMutation,
  useFetchCategoryClustersMutation,
} from '../../../redux/api/productApi/clusters';
import { headerCellSX } from '../../../utils/helpers/table';

interface Props {
  products: Product[];
}

const CategoriesTable = ({ products }: Props) => {
  const [category, setCategory] = useState<any>();
  const [cluster, setCluster] = useState<any>();
  const [nonCluster, setNonCluster] = useState(false);
  const [good, setGood] = useState<any>();
  const [categoryClustersList, setCategoryClustersList] = useState<any[]>([]);
  const [calcOneCategoryLoading, setCalcOneCategoryLoading] = useState(false);
  const [calcOneCategoryError, setCalcOneCategoryError] = useState(false);

  const [fetchCategoryClusters] = useFetchCategoryClustersMutation();

  useEffect(() => {
    if (category?.cat_id) {
      fetchCategoryClusters(category.cat_id)
        .then((res: any) => {
          setCategoryClustersList(res?.data || []);
        })
        .catch(console.error);
    }
  }, [category?.cat_id]);

  const { data, refetch: refetchCategories } = useGetCategoriesQuery({});

  const { data: clusterProductsData } = useGetClusterProductsQuery(
    { id: cluster?.id },
    { skip: !cluster }
  );
  const clusterProducts = clusterProductsData as unknown as any[];

  const {
    data: categoryNonClusterProductsData,
    refetch: refetchNonClusterProducts,
  } = useGetNonClusterProductsQuery(
    { id: category?.cat_id },
    { skip: !category?.cat_id }
  );
  const categoryNonClusterProducts =
    categoryNonClusterProductsData as unknown as any[];

  const { data: goodAttrResponse } = useGetProductCharacteristicQuery(
    good?.id,
    { skip: !good?.id }
  );

  const [calc] = useGetCalcOneCategoryMutation();

  const goodAttr = useMemo(() => goodAttrResponse?.body, [goodAttrResponse]);

  const calcOneCategory = async () => {
    if (category?.cat_id) {
      setCalcOneCategoryLoading(true);
      await calc(category.cat_id)
        .then(() => fetchCategoryClusters(category.cat_id))
        .then((res: any) => {
          setCategoryClustersList(res?.data || []);
          refetchNonClusterProducts();
          refetchCategories();
        })
        .catch(() => {
          setCalcOneCategoryError(true);
          setTimeout(() => {
            setCalcOneCategoryError(false);
          }, 2000);
        })
        .finally(() => {
          setCalcOneCategoryLoading(false);
        });
    }
  };

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: category ? '1fr 0.4fr' : '1fr',
          gridTemplateRows: '1fr',
          gap: '10px',
        }}
      >
        <Paper sx={{ width: '100%' }}>
          <TableContainer
            sx={{
              mt: '25px',
              height: '75vh',
            }}
          >
            <div>
              <div style={{ position: 'sticky', top: 0 }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={headerCellSX} align='center' width={60}>
                        ID
                      </TableCell>
                      <TableCell sx={headerCellSX} align='left'>
                        Категория
                      </TableCell>
                      <TableCell sx={headerCellSX} align='center' width={60}>
                        Количество кластеров
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </div>
              <div style={{ height: '68vh', overflow: 'auto' }}>
                <Table sx={{ minWidth: 650 }} style={{ tableLayout: 'fixed' }}>
                  <TableBody>
                    {((data || []) as any[]).map((cat: any) => (
                      <TableRow
                        key={cat.cat_id}
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          setCategory(category === cat ? undefined : cat)
                        }
                        selected={cat === category}
                      >
                        <TableCell align='center' width={60}>
                          {cat.cat_id}
                        </TableCell>
                        <TableCell align='left'>{cat.cat_full_name}</TableCell>
                        <TableCell align='center' width={60}>
                          {cat.num_cluster}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </TableContainer>
        </Paper>
        {category ? (
          <Paper sx={{ width: '100%' }}>
            <TableContainer
              sx={{
                mt: '25px',
                height: '75vh',
              }}
            >
              <div
                style={{
                  marginBottom: '10px',
                }}
              >
                <LoadingButton
                  onClick={calcOneCategory}
                  loading={calcOneCategoryLoading}
                  loadingPosition='start'
                  variant='outlined'
                  style={{ width: '100%' }}
                  startIcon={<></>}
                >
                  <span>Пересчитать кластеры</span>
                </LoadingButton>
              </div>
              <div
                style={{
                  borderBottom: '1px solid #bebebe',
                  paddingBottom: '20px',
                }}
              >
                <Button
                  onClick={() => setNonCluster(true)}
                  variant='outlined'
                  style={{ width: '100%' }}
                >
                  Товары не вошедшие в кластер
                </Button>
              </div>
              <div>
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginTop: '10px',
                  }}
                >
                  Кластеры категории
                </div>
                <div style={{ position: 'sticky', top: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={headerCellSX} align='center' width={60}>
                          ID
                        </TableCell>
                        <TableCell sx={headerCellSX} align='left'>
                          Кластер
                        </TableCell>
                        <TableCell sx={headerCellSX} align='center' width={60}>
                          Количество товаров
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </div>
                <div style={{ height: '65vh', overflow: 'auto' }}>
                  <Table style={{ tableLayout: 'fixed' }}>
                    <TableBody>
                      {categoryClustersList?.length ? (
                        categoryClustersList.map((v: any) => (
                          <TableRow
                            key={v.id}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setCluster(v)}
                            selected={v === cluster}
                          >
                            <TableCell align='center' width={60}>
                              {v.id}
                            </TableCell>
                            <TableCell align='left'>{v.name}</TableCell>
                            <TableCell align='center' width={60}>
                              {v.goods_num}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align='center' width={'100%'} colSpan={3}>
                            Нет данных
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </TableContainer>
          </Paper>
        ) : null}
      </div>
      {cluster ? (
        <Dialog onClose={() => setCluster(undefined)} open={true}>
          <DialogTitle>Профиль кластера {cluster.name}</DialogTitle>
          <Paper sx={{ width: '600px' }}>
            <TableContainer sx={{ mt: '25px', height: '60vh' }}>
              <div>
                <div style={{ position: 'sticky', top: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={headerCellSX} align='center' width={60}>
                          ID
                        </TableCell>
                        <TableCell sx={headerCellSX} align='left'>
                          Товар
                        </TableCell>
                        <TableCell sx={headerCellSX} align='center' width={80}>
                          Цена
                        </TableCell>
                        <TableCell
                          sx={headerCellSX}
                          align='center'
                          width={80}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </div>
                <div style={{ height: '53vh', overflow: 'auto' }}>
                  <Table style={{ tableLayout: 'fixed' }}>
                    <TableBody>
                      {clusterProducts?.length ? (
                        clusterProducts.map((v: any) => (
                          <TableRow key={v.id}>
                            <TableCell align='center' width={60}>
                              {v.id}
                            </TableCell>
                            <TableCell align='left'>{v.name}</TableCell>
                            <TableCell align='center' width={60}>
                              {v.base_price}
                            </TableCell>
                            <TableCell align='center' width={80}>
                              <Button onClick={() => setGood(v)} size='small'>
                                Свойства
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align='center' width='100%' colSpan={4}>
                            Нет данных
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </TableContainer>
          </Paper>
          <DialogActions>
            <Button onClick={() => setCluster(undefined)}>Закрыть</Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {nonCluster ? (
        <Dialog onClose={() => setNonCluster(false)} open={true}>
          <DialogTitle>Товары не вошедшие в кластер</DialogTitle>
          <Paper sx={{ width: '600px' }}>
            <TableContainer sx={{ mt: '25px', height: '60vh' }}>
              <div>
                <div style={{ position: 'sticky', top: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={headerCellSX} align='center' width={60}>
                          ID
                        </TableCell>
                        <TableCell sx={headerCellSX} align='left'>
                          Товар
                        </TableCell>
                        <TableCell sx={headerCellSX} align='center' width={80}>
                          Цена
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </div>
                <div style={{ height: '53vh', overflow: 'auto' }}>
                  <Table style={{ tableLayout: 'fixed' }}>
                    <TableBody>
                      {categoryNonClusterProducts?.length ? (
                        categoryNonClusterProducts.map((v: any) => (
                          <TableRow key={v.id}>
                            <TableCell align='center' width={60}>
                              {v.id}
                            </TableCell>
                            <TableCell align='left'>{v.name}</TableCell>
                            <TableCell align='center' width={60}>
                              {v.base_price}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align='center' width={'100%'} colSpan={3}>
                            Нет данных
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </TableContainer>
          </Paper>
          <DialogActions>
            <Button onClick={() => setNonCluster(false)}>Закрыть</Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {good ? (
        <Dialog onClose={() => setGood(undefined)} open={true}>
          <DialogTitle>Свойства товара {good.name}</DialogTitle>
          <Paper sx={{ width: '600px' }}>
            <TableContainer sx={{ mt: '25px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCellSX} align='left'>
                      Имя свойства
                    </TableCell>
                    <TableCell sx={headerCellSX} align='center' width={100}>
                      Значение
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {goodAttr?.length ? (
                    goodAttr.map((v: any, i: number) => (
                      <TableRow key={i}>
                        <TableCell align='left'>{v.name}</TableCell>
                        <TableCell align='center'>{v.value}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align='center' width={'100%'} colSpan={2}>
                        Нет данных
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <DialogActions>
            <Button onClick={() => setGood(undefined)}>Закрыть</Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <Dialog
        open={calcOneCategoryError}
        onClose={() => setCalcOneCategoryError(false)}
        maxWidth={false}
      >
        <DialogTitle>Ошибка выполнения пересчета!</DialogTitle>
        <DialogActions>
          <Button onClick={() => setCalcOneCategoryError(false)} autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CategoriesTable;
