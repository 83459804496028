import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment/moment';
import React, { useState } from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import { baseUrl } from '../../../redux/api/apiSlice';
import { useGetHeatmapsQuery } from '../../../redux/api/clientApi/shop';
import { headerCellSX } from '../../../utils/helpers/table';
import Shop from '../components/Shop/Shop';

const HeatMap = () => {
  const [shop, setShop] = useState<ShopType>();
  const [heatmap, setHeatmap] = useState<HeatmapType>();

  const { data } = useGetHeatmapsQuery(
    { tradePointId: shop?.id },
    { skip: !shop?.id }
  );

  const list = data?.body as HeatmapType[];

  const onLoad = (e: any) => {
    const img = e.target as HTMLImageElement;
    if (img.width / img.height > window.innerWidth / window.innerHeight) {
      img.width = window.innerWidth * 0.8;
      img.style.height = 'auto';
    } else {
      img.height = window.innerHeight * 0.8;
      img.style.width = 'auto';
    }
  };

  return (
    <>
      <MainContainer title='Тепловая карта'>
        <Shop onChange={setShop} />
        <TableContainer component={Paper} sx={{ mt: '25px' }}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={headerCellSX} width='auto' align='left'>
                  Дата и время
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.length ? (
                list.map((hm: HeatmapType) => (
                  <TableRow
                    key={hm.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: hm.hasImage ? 'pointer' : undefined,
                    }}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      if (hm.hasImage) {
                        setHeatmap(hm);
                      }
                    }}
                  >
                    <TableCell align='left'>
                      {hm.heatmapDate
                        ? moment(hm.heatmapDate).format('DD.MM.YYYY hh:mm:ss')
                        : 'нет данных'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align='center' colSpan={3}>
                    Нет данных
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </MainContainer>
      <Dialog
        open={!!heatmap}
        onClose={() => setHeatmap(undefined)}
        maxWidth={false}
      >
        <DialogTitle>Тепловая карта {heatmap?.name || ''}</DialogTitle>
        <DialogContent style={{ width: 'min-content', height: 'min-content' }}>
          <img
            src={`${baseUrl}/clientservice/trade_point_heatmap/${heatmap?.id}/image`}
            alt='Тепловая карта'
            onLoad={e => onLoad(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHeatmap(undefined)} autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HeatMap;
