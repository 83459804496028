import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Product } from '../../../../common/types';
import {
  getTableHeaderAlign,
  headerCellSX,
} from '../../../../utils/helpers/table';
import ProductsAndCategoriesCard from './ProductsAndCategoriesCard/ProductsAndCategoriesCard';

const headers = ['ID', 'Имя', 'Предмет', ''];

interface Props {
  products: Product[];
}

const ProductsTable = ({ products }: Props) => {
  const [product, setProduct] = useState<Product | undefined>();

  const closeDialog = () => setProduct(undefined);

  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer
          sx={{
            mt: '25px',
            height: '75vh',
          }}
        >
          <div>
            <div style={{ position: 'sticky', top: 0 }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    {headers.map((item, index) => (
                      <TableCell
                        key={item}
                        sx={headerCellSX}
                        align={getTableHeaderAlign(index, 1)}
                      >
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              </Table>
            </div>
            <div style={{ height: '68vh', overflow: 'auto' }}>
              <Table sx={{ minWidth: 650 }} style={{ tableLayout: 'fixed' }}>
                <TableBody>
                  {products.map((item: Product, index: number) => (
                    <TableRow
                      key={item.id}
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setProduct(item)}
                    >
                      <TableCell align='left'>{item.id}</TableCell>
                      <TableCell align='left'>{item.name}</TableCell>
                      <TableCell align='center'>{item.article}</TableCell>
                      <TableCell align='center'>
                        <MoreHorizIcon />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </TableContainer>
      </Paper>
      {product && (
        <ProductsAndCategoriesCard
          open={!!product}
          name={product.name}
          categoryId={null}
          productId={product.id}
          categoryLevel={product.level}
          onClose={closeDialog}
        />
      )}
    </>
  );
};

export default ProductsTable;
