import { Stack } from '@mui/material';
import React, { useState } from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import { useGetSalesReportQuery } from '../../../redux/api/productApi/sales';
import Period from '../components/Period/Period';
import Types from '../components/Types/Types';
import LiftsTable from './components/LiftsTable/LiftsTable';

const types = [
  { name: 'Товары', value: 'sku' },
  { name: 'Категории', value: 'cat' },
];

const counts = [
  { name: 'Пары', value: 'pair' },
  { name: 'Тройки', value: 'triple' },
];

const Lifts = () => {
  const [count, setCount] = useState<'pair' | 'triple'>('pair');
  const [type, setType] = useState<'sku' | 'cat'>('cat');
  const [period, setPeriod] = useState<string>('');

  const { data } = useGetSalesReportQuery(
    { count, period, type },
    { skip: !(count && type && period) }
  );

  return (
    <MainContainer title='Топ совместных продаж товаров'>
      <Stack direction='row' spacing={3}>
        <Period
          period={period}
          onChange={setPeriod}
          values={[
            { label: 'Вчера', value: 'day' },
            { label: 'Прошлая неделя', value: 'week' },
            { label: 'Прошлый месяц', value: 'month' },
          ]}
        />
        <Types data={counts} type={count} onChange={setCount as any} />
        <Types data={types} type={type} onChange={setType as any} />
      </Stack>
      {Boolean(period) && (
        <LiftsTable
          lifts={data as unknown as any[]}
          type={type}
          count={count}
          period={period as any}
        />
      )}
    </MainContainer>
  );
};

export default Lifts;
