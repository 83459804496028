import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

interface Props {
  images: string[] | undefined;
  sx?: SxProps<Theme>;
}

const ImageGallery = ({ images, sx }: Props) => {
  if (!images) return <></>;

  return (
    <Box sx={sx}>
      <Carousel showArrows emulateTouch useKeyboardArrows>
        {images.map(item => (
          <div key={item}>
            <img src={item} alt='Something went wrong' />
          </div>
        ))}
      </Carousel>
    </Box>
  );
};

export default ImageGallery;
