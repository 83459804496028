import { Stack } from '@mui/material';
import React from 'react';
import PreviewItem from './PreviewItem/PreviewItem';

interface Props {
  images: string[];
  onChange: (images: string[]) => void;
}

const ImagePreview = ({ images, onChange }: Props) => {
  const onRemove = (index: number) => {
    onChange(images.filter((_, i) => i !== index));
  };

  return (
    <Stack direction='row' spacing={1}>
      {images.map((item, index) => (
        <PreviewItem image={item} onRemove={() => onRemove(index)} />
      ))}
    </Stack>
  );
};

export default ImagePreview;
