import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from '../../api/authApi';
import { RootState } from '../../store';
import REDUCER_NAME from './name';
import { AuthStateType } from './types';

const initialState: AuthStateType = {
  accessToken: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setCredentials: (
      state: AuthStateType,
      action: PayloadAction<AuthStateType>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    logOut: (state: AuthStateType) => {
      localStorage.removeItem('credentials');
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(authApi.endpoints.logout.matchFulfilled, state => {
        localStorage.removeItem('credentials');
        state.accessToken = null;
        state.refreshToken = null;
      })
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          localStorage.setItem('credentials', JSON.stringify(payload.body));
          state.accessToken = payload.body.accessToken;
          state.refreshToken = payload.body.refreshToken;
        }
      );
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice;

export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
