import React, { useEffect, useMemo, useRef } from 'react';
import { Popover, styled, TextField } from '@mui/material';
import { TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  useGetProductCategoriesQuery,
  useGetProductCategoryByIdQuery,
} from '../../../../redux/api/productApi';
import { useAppSelector } from '../../../../redux/store';
import {
  selectProductCategories,
  selectProductCategoriesTree,
} from '../../../../redux/slices/productSlice';
import { ProductCategory } from '../../../../common/types';
import CustomTreeItem from './CustomTreeItem/CustomTreeItem';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-input {
    cursor: pointer;
  }

  .MuiOutlinedInput-root {
    &:hover fieldset {
      cursor: pointer;
    }
  }
`;

interface Props {
  onChange: (category: ProductCategory) => void;
  category?: ProductCategory;
  simpleExpand?: boolean;
}

const Categories = ({ category, onChange, simpleExpand }: Props) => {
  useGetProductCategoriesQuery({
    limit: 100000,
    offset: 0,
    clientId: 2,
  });

  const inputRef = useRef<any>(null);

  const productCategoriesTree = useAppSelector(selectProductCategoriesTree);
  const productCategories = useAppSelector(selectProductCategories);

  const equipmentItem = category?.name || '';
  const equipmentId = category?.id?.toString() || '';

  const [anchorEl, setAnchorEl] = React.useState(null);

  const categoryTree = [
    {
      categories: [],
      categoryId: -1,
      clientId: 2,
      id: -1,
      level: 1,
      name: 'Весь магазин',
      parentCategoryId: 0,
    },
    ...(productCategoriesTree || []),
  ];

  const categoryList = [
    {
      categoryId: -1,
      clientId: 2,
      id: -1,
      level: 1,
      name: 'Весь магазин',
      parentCategoryId: 0,
    },
    ...(productCategories || []),
  ];

  const defaultExpanded = useMemo(() => {
    if (category) {
      const defaultExp: string[] = [];
      const getParent = (productCategory: ProductCategory) => {
        const parent = categoryList.find(
          item => item.categoryId === productCategory.parentCategoryId
        );
        if (parent) {
          defaultExp.push(parent.id.toString());
          if (parent.parentCategoryId !== 0) {
            getParent(parent);
          }
        }
      };
      getParent(category);
      return defaultExp || [];
    }
  }, [category, categoryList]);

  const { data: currentCategory } = useGetProductCategoryByIdQuery(
    equipmentId,
    { skip: !equipmentId || equipmentId === '-1' }
  );

  useEffect(() => {
    if (currentCategory) onChange(currentCategory.body);
  }, [currentCategory, onChange]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    inputRef?.current?.blur();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onNodeSelect = (event: any, nodeIds: string) => {
    const category = categoryList.find(
      category => category.id.toString() === nodeIds
    );
    if (category) {
      onChange(category);
    }
    handleClose();
  };

  const renderTree = (nodes: ProductCategory, simpleExpand?: boolean) => (
    <CustomTreeItem
      key={nodes.id}
      nodeId={nodes.id.toString()}
      label={nodes.name}
      simpleExpand={simpleExpand}
    >
      {Array.isArray(nodes.categories)
        ? nodes.categories.map((node: any) => renderTree(node, simpleExpand))
        : null}
    </CustomTreeItem>
  );

  return (
    <>
      <StyledTextField
        ref={inputRef}
        variant='outlined'
        required={false}
        label='Категория'
        name='productCategoryItem'
        id='productCategoryItem'
        defaultValue={equipmentItem}
        value={equipmentItem}
        contentEditable={false}
        inputProps={{ readOnly: true }}
        onClick={handleClick}
        InputProps={{
          sx: { cursor: 'pointer' },
          endAdornment: open ? (
            <ArrowDropUp color='action' />
          ) : (
            <ArrowDropDown color='action' />
          ),
        }}
        sx={{ minWidth: 280 }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TreeView
          aria-label='icon expansion'
          defaultSelected={equipmentId}
          selected={equipmentId}
          defaultCollapseIcon={
            <ExpandMoreIcon
              sx={{
                color: '#295EF8',
              }}
            />
          }
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={defaultExpanded}
          onNodeSelect={onNodeSelect}
          sx={{
            maxHeight: 600,
            flexGrow: 1,
            minWidth: '200px',
            overflowY: 'auto',
          }}
        >
          {categoryTree.map((item, i) => renderTree(item, simpleExpand))}
        </TreeView>
      </Popover>
    </>
  );
};

export default Categories;
