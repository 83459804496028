import React, { useEffect, useRef, useState } from 'react';

function getCoordinatesForPercent(percent: number) {
  const x = Math.cos(2 * Math.PI * percent);
  const y = Math.sin(2 * Math.PI * percent);
  return [x, y];
}

type PieChartsProps = {
  data: {
    value: number;
    color: string;
    label: string;
  }[];
};

function getRandomPercentages(length: number): number[] {
  let sum = 0;
  let percentages = Array.from({ length }, () => {
    const v = Math.random();
    sum += v;
    return v;
  }); /* new Array(numItems).fill(0).map(() => Math.random()) */
  // let sum = percentages.reduce((a, b) => a + b, 0);
  return percentages.map(value => value / sum);
}

export default function PieChart({ data }: PieChartsProps) {
  const [values, setValues] = useState<number[]>([]);

  const cumulativePercent = useRef(0);

  useEffect(() => {
    if (data?.length) {
      setValues(getRandomPercentages(data.length));
    }
  }, [data]);

  return (
    <svg viewBox='-1 -1 2 2' style={{ transform: 'rotate(-90deg)' }}>
      {data.map((item, i) => {
        if (i === 0) {
          cumulativePercent.current = 0;
        }
        const [startX, startY] = getCoordinatesForPercent(
          cumulativePercent.current
        );
        cumulativePercent.current += values[i];
        const [endX, endY] = getCoordinatesForPercent(
          cumulativePercent.current
        );
        const largeArcFlag = values[i] > 0.5 ? 1 : 0;
        const pathData = [
          `M ${startX} ${startY}`,
          `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
          `L 0 0`,
        ].join(' ');

        return (
          <path key={i} d={pathData} fill={item.color}>
            <title>
              {item.label} {Math.round(values[i] * 10_000) / 100}%
            </title>
          </path>
        );
      })}
    </svg>
  );
}
