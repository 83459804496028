import React, { useState } from 'react';
import MainContainer from '../../../components/MainContainer/MainContainer';
import { useGetShopClustersQuery } from '../../../redux/api/productApi/shop';
import Shop from '../components/Shop/Shop';
import MarketsTable from './components/MarketsTable/MarketsTable';

const MarketProfile = () => {
  const [shop, setShop] = useState<ShopType>();

  const { data } = useGetShopClustersQuery(
    { id: shop?.id },
    { skip: !shop?.id }
  );
  const clusters = data as unknown as ShopClusterType[];

  return (
    <MainContainer title='Профиль магазина'>
      <Shop onChange={setShop} />

      {shop ? (
        <div
          style={{
            display: 'flex',
            gap: '30px',
            margin: '20px 0',
          }}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ color: 'gray' }}>Формат</div>
            <div>{shop.format || 'нет данных'}</div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ color: 'gray' }}>Этаж</div>
            <div>{shop.floor || 'нет данных'}</div>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ color: 'gray' }}>Количество касс</div>
            <div>{shop.cashDeskQuantity || 'нет данных'}</div>
          </div>
        </div>
      ) : null}

      {shop && clusters?.length ? <MarketsTable clusters={clusters} /> : null}
    </MainContainer>
  );
};

export default MarketProfile;
