import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import {useGetShopClusterQuery} from '../../../../../redux/api/productApi/shop';

const ClusterStatTable = ({id}: {id: number}) => {

  const {data} = useGetShopClusterQuery({id}, {skip: !id});
  const clusterInfo = (data as unknown as ShopClusterInfoType[])?.[0];

  return (
    <>
      <Typography>Статистика по кластеру</Typography>
      <TableContainer
        sx={{
          mt: '25px',
        }}
      >
        <Table aria-label="simple table">
          {clusterInfo ? (
            <TableBody>
              <TableRow>
                <TableCell align="left" sx={{border: 1}}>Минимальный чек</TableCell>
                <TableCell align="center" sx={{border: 1}}>{clusterInfo?.min_sale || ''} руб.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" sx={{border: 1}}>Максимальный чек</TableCell>
                <TableCell align="center" sx={{border: 1}}>{clusterInfo?.max_sale || ''} руб.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" sx={{border: 1}}>Средний чек</TableCell>
                <TableCell align="center" sx={{border: 1}}>{clusterInfo?.avg_sale || ''} руб.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" sx={{border: 1}}>Количество чеков</TableCell>
                <TableCell align="center" sx={{border: 1}}>{clusterInfo?.sales_count || ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" sx={{border: 1}}>Дата расчёта</TableCell>
                <TableCell align="center" sx={{border: 1}}>{clusterInfo?.cluster_date ? moment(clusterInfo.cluster_date).format('DD.MM.YY') : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" sx={{border: 1}}>Количество категорий</TableCell>
                <TableCell align="center" sx={{border: 1}}>{clusterInfo?.category_count || ''}</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" sx={{border: 1}}>Нет данных</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default ClusterStatTable;
