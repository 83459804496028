import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  BarControllerChartOptions,
  ChartData,
  ChartDataset,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { _DeepPartialObject } from 'chart.js/types/utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import {useGetProductAnalytics2Query} from '../../../../../redux/api/productApi/analytics';

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  id: any;
  type: any;
  period: any;
  title: any;
}

const cat: any = {
  cat: 'category',
  sku: 'good'
}

const periods: any = {
  day: 1,
  week: 7,
  month: 30
}

const PairLiftChart = ({id, type, period, title}: Props) => {
  const chartTitle = title;

  const {data: products} = useGetProductAnalytics2Query(
    {id, type: cat[type], period: periods[period]},
    {skip: !id || !type || !period}
  );

  const dataset: ChartDataset<'bar', any> = {
    label: '',
    data: ((products as any)?.pairSupport || []).map((value: any) => Math.round(value.confidence * 10000) / 100) as any,
    borderColor: '#4472C4',
    backgroundColor: '#4472C4',
  };

  const options: _DeepPartialObject<
    CoreChartOptions<'bar'> &
      ElementChartOptions<'bar'> &
      PluginChartOptions<'bar'> &
      DatasetChartOptions<'bar'> &
      ScaleChartOptions<'bar'> &
      BarControllerChartOptions
  > = {
    indexAxis: 'y',
    responsive: true,
    layout: {
      padding: {
        right: 35,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        font: {
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.data[context.dataIndex];
            return `${label}`;
          },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          callback: (tickValue) => `${tickValue}%`,
        },
      },
    },
  };

  const data: ChartData<'bar', any, string> = {
    labels: ((products as any)?.pairSupport || []).map((value: any) => value.name) as any,
    datasets: [dataset],
  };

  return (
    <>
      <Typography
        variant='h6'
        sx={{
          fontWeight: 700,
          mt: '25px',
          mb: '15px',
        }}
      >
        {chartTitle || ''}
      </Typography>
      <Stack direction='row' sx={{ flex: 1 }}>
        <Box
          sx={{
            flex: 1,
            border: 2,
            borderColor: grey[300],
          }}
        >
          <Bar options={options} data={data} />
        </Box>
      </Stack>
    </>
  );
};

export default PairLiftChart;
