import { Response } from '../../../common/types';
import { apiSlice } from '../apiSlice';

export const {
  useGetShopClustersQuery,
  useGetShopClusterQuery,
  useGetShopClusterCategoriesInfoQuery,
  useGetShopClusterCategoryLiftsQuery,
} = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getShopClusters: builder.query<Response<any>, any>({
      query: ({id}: {id: number}) => ({
        url: `/clusterservice/shop_cluster_list?id_shop=${id}`,
      }),
    }),
    getShopCluster: builder.query<Response<any>, any>({
      query: ({id}: {id: number}) => ({
        url: `/clusterservice/shop_cluster_basic_info?id_cluster=${id}`,
      }),
    }),
    getShopClusterCategoriesInfo: builder.query<Response<any>, any>({
      query: ({id}: {id: number}) => ({
        url: `/clusterservice/shop_cluster_categories_info?id_cluster=${id}`,
      }),
    }),
    getShopClusterCategoryLifts: builder.query<Response<any>, any>({
      query: (body: {categories: number[]}) => ({
        url: '/associateruleservice/joint_for_categories_list_month',
        method: 'POST',
        body,
      }),
    }),
  }),
});
