import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  getTableHeaderAlign,
  headerCellSX,
} from '../../../../utils/helpers/table';
import { Shelve } from '../../../../common/types';
import { BurstMode } from '@mui/icons-material';
import ImageGalleryModal from '../../../../components/ImageGalleryModal/ImageGalleryModal';
import ShelveInfo from '../ShelveInfo/ShelveInfo';

const headers = ['Название', 'Длина', 'Ширина', ''];

interface Props {
  shelves: Shelve[];
}

const ShelvesTable = ({ shelves }: Props) => {
  const [images, setImages] = useState<string[]>();
  const [shelve, setShelve] = useState<Shelve>();

  const openImageGallery = (
    event: React.MouseEvent<HTMLElement>,
    shelve: Shelve
  ) => {
    event.stopPropagation();
    setImages(shelve.images);
  };

  const openShelveDialog = (
    event: React.MouseEvent<HTMLElement>,
    shelve: Shelve
  ) => {
    event.stopPropagation();
    setShelve(shelve);
  };

  const closeImageGallery = () => setImages(undefined);
  const closeShelveDialog = () => setShelve(undefined);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          mt: '25px',
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              {headers.map((item, index) => (
                <TableCell
                  key={index}
                  sx={headerCellSX}
                  width={index === headers.length - 1 ? 30 : 'auto'}
                  align={getTableHeaderAlign(index, 1)}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {shelves.map((shelve: Shelve, index: number) => (
              <TableRow
                key={shelve.id}
                selected={index === 1}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&.MuiTableRow-root.Mui-selected': {
                    backgroundColor: '#ff00004d',
                  },
                  cursor: 'pointer',
                }}
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  openShelveDialog(event, shelve)
                }
              >
                <TableCell align='left'>{shelve.name}</TableCell>
                <TableCell align='center'>{shelve.length}</TableCell>
                <TableCell align='center'>{shelve.width}</TableCell>
                {shelve.images && (
                  <TableCell
                    align='center'
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      openImageGallery(event, shelve)
                    }
                  >
                    <BurstMode />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ImageGalleryModal
        open={!!images}
        images={images}
        onClose={closeImageGallery}
      />
      <ShelveInfo shelve={shelve} open={!!shelve} onClose={closeShelveDialog} />
    </>
  );
};

export default ShelvesTable;
