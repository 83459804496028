import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import Navbar from './NavBar';
import {
  selectAccessToken,
  setCredentials,
} from '../../redux/slices/authSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useEffect } from 'react';

const RequireAuth = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectAccessToken);

  const location = useLocation();

  const credentials = localStorage.getItem('credentials');

  useEffect(() => {
    if (credentials) {
      dispatch(setCredentials(JSON.parse(credentials)));
    }
  }, [credentials, dispatch]);

  const isAuth = token || credentials;

  return isAuth ? (
    <>
      <Navbar />
      <Outlet />
    </>
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};
export default RequireAuth;
