import {Stack, Typography} from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  subTitle: string;
}

const EmptyShelfItem = ({title, subTitle}: Props) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="subtitle1">{title}: </Typography>
      <Typography variant="body1">{subTitle}</Typography>
    </Stack>
  );
};

export default EmptyShelfItem;
