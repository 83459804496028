import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Shelve } from '../../common/types';
import MainContainer from '../../components/MainContainer/MainContainer';
import Shop from '../Reports/components/Shop/Shop';
import CreateShelves from './components/CreateShelves/CreateShelves';
import ShelvesTable from './components/ShelvesTable/ShelvesTable';
import { mockShelves } from './mockData';

const Shelves = () => {
  const [shop, setShop] = useState<string | undefined>();
  const [shelves, setShelves] = useState<Shelve[]>(mockShelves);
  const [open, setOpen] = useState(false);

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const handleNewShelve = (shelve: Shelve) => {
    setShelves(shalves => [...shalves, shelve]);
    handleModalClose();
  };

  return (
    <MainContainer>
      <Stack direction='row' spacing={3}>
        <Shop onChange={setShop} />
        <Button variant='contained' onClick={handleModalOpen}>
          Добавить полку
        </Button>
      </Stack>
      {!!shop && <ShelvesTable shelves={shelves} />}
      <CreateShelves
        addShelve={handleNewShelve}
        open={open}
        onClose={handleModalClose}
      />
    </MainContainer>
  );
};

export default Shelves;
