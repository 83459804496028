import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { Shelve } from '../../../../common/types';
import ImageGallery from '../../../../components/ImageGallery/ImageGallery';
import ShelveItem from './ShelveItem';

interface Props {
  shelve: Shelve | undefined;
  open: boolean;
  onClose: () => void;
}

const ShelveInfo = ({ shelve, open, onClose }: Props) => {
  if (!shelve) return <></>;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>{shelve.name}</DialogTitle>
      <DialogContent dividers>
        <ShelveItem title='Ширина' subTitle={`${shelve.width} см.`} />
        <ShelveItem title='Длина' subTitle={`${shelve.length} см.`} />
        <ImageGallery images={shelve.images} sx={{ mt: 2 }} />
      </DialogContent>
    </Dialog>
  );
};

export default ShelveInfo;
