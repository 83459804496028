import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import MainContainer from '../../../../../components/MainContainer/MainContainer';
import {
  useCreateTaskQuery,
  useGetTaskQuery,
} from '../../../../../redux/api/taskApi';
import PeriodPicker from '../../../../Reports/Ratings/components/RatingComponent/PeriodPicker/PeriodPicker';
import TrendsChart from '../../../../../components/TrendsChart/TrendsChart';
import ProductsAndCategoriesRatingInfo from './ProductsAndCategoriesRatingInfo/ProductsAndCategoriesRatingInfo';
import { useGetCategoryStatQuery } from '../../../../../redux/api/productApi/analytics';
import { Bar, Pie } from 'react-chartjs-2';
import './styles.css';

interface Props {
  open: boolean;
  name: string;
  categoryId: number | null;
  productId: number | null;
  categoryLevel: number;
  onClose: () => void;
}

const ERROR_TEXT = 'Нет данных или возникла ошибка при формировании отчета';

const round = (v: number) => Math.round(v * 100) / 100;

const ProductsAndCategoriesCard = ({
  open,
  name,
  categoryId,
  productId,
  categoryLevel,
  onClose,
}: Props) => {
  const [period, setPeriod] = useState<{
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({
    dateFrom: null,
    dateTo: null,
  });
  const [taskFailed, setTaskFailed] = useState(false);
  const [finished, setFinished] = useState(false);

  const { data: categoryStat } = useGetCategoryStatQuery(
    { id: categoryId, cat_level: categoryLevel },
    { skip: !!productId }
  );

  const { data: taskStatus, isError: isCreateTaskError } = useCreateTaskQuery(
    {
      type: 'SSA',
      params: {
        id_product: productId,
        id_category: categoryId,
        category_level: categoryLevel || 1,
        date_from: moment(period.dateFrom).format('YYYY-MM-DD'),
        date_to: moment(period.dateTo).format('YYYY-MM-DD'),
      },
    },
    { skip: !(period.dateFrom || period.dateTo) || !(productId || categoryId) }
  );

  const distribution = categoryStat?.product_distribution || {};

  const labels = [
    `от ${Math.round(distribution?.q1 || 0)} до ${Math.round(
      distribution?.q2 || 0
    )} руб: ${distribution?.q1_q2 || 0} шт.`,
    `от ${Math.round(distribution?.q2 || 0)} до ${Math.round(
      distribution?.q3 || 0
    )} руб: ${distribution?.q2_q3 || 0} шт.`,
    `от ${Math.round(distribution?.q3 || 0)} до ${Math.round(
      distribution?.q4 || 0
    )} руб: ${distribution?.q3_q4 || 0} шт.`,
    `от ${Math.round(distribution?.q4 || 0)} до ${Math.round(
      distribution?.q5 || 0
    )} руб: ${distribution?.q4_q5 || 0} шт.`,
  ];

  const data = useMemo(() => {
    const distribution = categoryStat?.product_distribution || {};
    return {
      labels: [''],
      datasets: [
        {
          label: labels[0],
          data: [distribution?.q1_q2 || 0],
          backgroundColor: ['rgba(216,99,255, 0.8)'],
        },
        {
          label: labels[1],
          data: [distribution?.q2_q3 || 0],
          backgroundColor: ['rgba(54, 162, 235, 0.8)'],
        },
        {
          label: labels[2],
          data: [distribution?.q3_q4 || 0],
          backgroundColor: ['rgba(255, 205, 86, 0.8)'],
        },
        {
          label: labels[3],
          data: [distribution?.q4_q5 || 0],
          backgroundColor: ['rgba(47,201,37, 0.8)'],
        },
      ],
    };
  }, [categoryStat]);

  const options = {
    plugins: {
      tooltip: {
        callbacks: { label: (context: any) => labels[context.dataIndex] },
      },
      legend: { position: 'bottom' as any },
    },
  };

  const {
    data: task,
    refetch,
    isError: isGetTaskError,
  } = useGetTaskQuery(taskStatus?.id as any, {
    skip: !taskStatus,
  });

  const intervalId = useRef<NodeJS.Timer | undefined>();
  const numOfRefetch = useRef(0);

  const refetchEnabled = numOfRefetch.current < 15;

  useEffect(() => {
    if (task?.status === 'SUCCESS') {
      clearInterval(intervalId.current);
      setFinished(true);
      setTaskFailed(false);
      numOfRefetch.current = 0;
    } else if (task) {
      if (refetchEnabled && !finished) {
        clearInterval(intervalId.current);
        intervalId.current = setInterval(() => {
          refetch();
          numOfRefetch.current += 1;
        }, 1000);
      } else {
        clearInterval(intervalId.current);
        numOfRefetch.current = 0;
        setTaskFailed(true);
        setFinished(true);
      }
    }
  }, [task, refetch, refetchEnabled]);

  const handlePeriod = (dateFrom: Date, dateTo: Date) => {
    numOfRefetch.current = 0;
    setTaskFailed(false);
    setFinished(false);
    setPeriod({
      dateFrom,
      dateTo,
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={open}
        onClose={onClose}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <MainContainer>
          <Typography sx={{ textAlign: 'center', mt: '40px' }}>
            <strong>Название: {name}</strong>
          </Typography>

          {categoryId && !productId ? (
            <div>
              <Typography sx={{ textAlign: 'center', mt: '40px' }}>
                <strong>Статистика категории</strong>
              </Typography>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridTemplateRows: '1fr',
                  minHeight: '300px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <table className='category-stat'>
                    <tbody>
                      <tr>
                        <td>Товаров в категории</td>
                        <td>{round(categoryStat?.product_count || 0)}</td>
                      </tr>
                      <tr>
                        <td>Минимальная стоимость товара</td>
                        <td>{round(categoryStat?.min_product_cost || 0)}</td>
                      </tr>
                      <tr>
                        <td>Максимальная стоимость товара</td>
                        <td>{round(categoryStat?.max_product_cost || 0)}</td>
                      </tr>
                      <tr>
                        <td>Средняя стоимость товара</td>
                        <td>{round(categoryStat?.mid_product_cost || 0)}</td>
                      </tr>
                      {/*<tr>
                <td>Подкатегорий в категории</td>
                <td>{categoryStat?.subcategories_count || ''}</td>
              </tr>*/}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Bar data={data} width={500} options={options} />
                </div>
              </div>
            </div>
          ) : null}

          <Typography sx={{ textAlign: 'center', mt: '20px' }}>
            <strong>Анализ продаж</strong>
          </Typography>

          <PeriodPicker
            dateFrom={moment().startOf('day').subtract(13, 'days').toDate()}
            dateTo={moment().toDate()}
            onChange={handlePeriod}
          />
          {task?.status !== 'SUCCESS' ? (
            <Box
              sx={{
                display: 'flex',
                height: '40vh',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {period.dateFrom && period.dateTo ? (
                refetchEnabled &&
                !isGetTaskError &&
                !isCreateTaskError &&
                !finished ? (
                  <CircularProgress />
                ) : (
                  <Typography>{ERROR_TEXT}</Typography>
                )
              ) : (
                <></>
              )}
            </Box>
          ) : (
            <TrendsChart
              taskResult={JSON.parse(task.result as any)}
              period={period}
            />
          )}
          {(categoryLevel > 3 || productId) && (
            <ProductsAndCategoriesRatingInfo
              id={productId || categoryId}
              type={productId ? 'good' : categoryId ? 'category' : ''}
            />
          )}
        </MainContainer>
      </Dialog>

      <Dialog
        open={taskFailed}
        onClose={() => setTaskFailed(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Не удалось получить данные, попробуйте изменить период
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setTaskFailed(false)} autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductsAndCategoriesCard;
