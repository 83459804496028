import React from 'react';
import { Dialog } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageGallery from '../ImageGallery/ImageGallery';

interface Props {
  open: boolean;
  onClose: () => void;
  images: string[] | undefined;
}

const ImageGalleryModal = ({ images, open, onClose }: Props) => {
  if (!images) return <></>;

  return (
    <Dialog open={open} onClose={onClose} scroll='paper'>
      <ImageGallery images={images} />
    </Dialog>
  );
};

export default ImageGalleryModal;
