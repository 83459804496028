import React from 'react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { setCredentials } from '../../redux/slices/authSlice';
import { useAppDispatch } from '../../redux/store';

const Layout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      try {
        dispatch(setCredentials(JSON.parse(credentials)));
      } catch (e) {
        console.error(e);
        localStorage.removeItem('credentials');
      }
    }
  }, [dispatch]);
  return <Outlet />;
};

export default Layout;
