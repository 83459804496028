import { DeleteForever, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { baseUrl } from '../../../../../redux/api/apiSlice';
import {
  useDeleteShelfImageMutation,
  useDeleteSubShelfMutation,
  useGetShelfQuery,
  useGetSubShelvesQuery,
  useUploadShelfImageMutation,
} from '../../../../../redux/api/clientApi/shelves';
import './Shelf.scss';
import { getPointsFromStr } from '../../../../../utils/coords';
import { NewEmptyPlace } from './NewEmptyPlace';
import SubShelf from './SubShelf';

interface Props {
  id: number;
  open: boolean;
  onClose: () => void;
}

const imageHeight = 800;
const sectionColor = '#55ff00';
const emptyPlaceColor = '#00a6ff';

const drawPolygon = (
  ctx: CanvasRenderingContext2D,
  polygon: PointType[],
  color: string,
  width: number
) => {
  if (polygon?.length) {
    ctx.lineWidth = width;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(polygon[0].x, polygon[0].y);
    for (let i = 1; i < polygon.length; i++) {
      ctx.lineTo(polygon[i].x, polygon[i].y);
    }
    ctx.lineTo(polygon[0].x, polygon[0].y);
    ctx.closePath();
    ctx.stroke();
  }
};

export const Shelf = ({ id, open, onClose }: Props) => {
  const [src, setSrc] = useState('');
  const [deleteId, setDeleteId] = useState(0);
  const [subShelf, setSubShelf] = useState<SubShelfType>();
  const [newSubShelf, setNewSubShelf] = useState(false);
  const [selected, setSelected] = useState<SubShelfType>();
  const [loading, setLoading] = useState(false);
  const [deleteShelfImage, setDeleteShelfImage] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [upload] = useUploadShelfImageMutation();
  const [removeImage] = useDeleteShelfImageMutation();
  const [removeSubShelf] = useDeleteSubShelfMutation();
  const { data: shelfData, refetch: refetchShelf } = useGetShelfQuery(
    { id },
    { skip: !id }
  );
  const { data: subShelfData, refetch: refetchSubShelf } =
    useGetSubShelvesQuery({ id }, { skip: !id });
  const shelf = shelfData?.body as ShelfType;
  const subShelves = subShelfData?.body as SubShelfType[];
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvas = canvasRef.current;

  const drawSubShelves = (
    list: SubShelfType[],
    ctx: CanvasRenderingContext2D,
    imageRatio: number
  ) => {
    if (list?.length && ctx) {
      list.forEach(shelf => {
        const section = getPointsFromStr(shelf.coords, imageRatio);
        const emptyPlace = getPointsFromStr(shelf.emptySpaceCoords, imageRatio);
        if (ctx) {
          drawPolygon(ctx, section, sectionColor, shelf === selected ? 7 : 3);
          drawPolygon(
            ctx,
            emptyPlace,
            emptyPlaceColor,
            shelf === selected ? 7 : 3
          );
        }
        // const polygon = (shelf.coords
        //   ?.replace('((', '')
        //   .replace('))', '')
        //   .split('),(')
        //   .map((str) => {
        //     const [x, y] = str.split(',');
        //     return {x: +x * imageRatio, y: +y * imageRatio};
        //   }) || []) as PointType[];
        // if (ctx && polygon.length) {
        //   ctx.lineWidth = 3;
        //   ctx.strokeStyle = shelf === selected ? '#ff4e35' : '#55ff00';
        //   ctx.beginPath();
        //   ctx.moveTo(polygon[0].x, polygon[0].y);
        //   for (let i = 1; i < polygon.length; i++) {
        //     ctx.lineTo(polygon[i].x, polygon[i].y);
        //   }
        //   ctx.lineTo(polygon[0].x, polygon[0].y);
        //   ctx.closePath();
        //   ctx.stroke();
        // }
      });
    }
  };

  const draw = (subShelves: SubShelfType[]) => {
    // const canvas = canvasRef.current;
    if (canvas && src) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setLoading(false);
        let imageRatio: number;
        if (img.width > 1200) {
          canvas.width = 1200;
          imageRatio = 1200 / img.width;
          canvas.height = img.height * imageRatio;
        } else {
          canvas.height = imageHeight;
          imageRatio = imageHeight / img.height;
          canvas.width = img.width * imageRatio;
        }
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        drawSubShelves(subShelves, ctx, imageRatio);
      };
    }
  };

  useEffect(() => {
    if (src) {
      draw(subShelves);
    }
  }, [src, subShelves, selected]);

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   if (canvas) {
  //     setCanvas(canvas);
  //   }
  //   console.log('canvas', canvas);
  //   return () => {
  //     setCanvas(undefined);
  //   };
  // }, []);

  useEffect(() => {
    if (open) {
      const imgUrl = shelf?.hasShelfImage
        ? `${baseUrl}/clientservice/shelf/${shelf?.id}/shelf_image`
        : '';
      setLoading(!!imgUrl);
      setSrc(imgUrl);
    } else {
      setLoading(false);
      setSrc('');
    }
  }, [shelf, open]);

  const fileDialog = () => {
    inputRef.current?.click();
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file && shelf?.id) {
      const body = new FormData();
      body.append('shelfImage', file);
      await upload({ id: shelf.id, body }).unwrap();
      refetchShelf?.();
    }
  };

  const removeShelfImage = async () => {
    await removeImage({ id: shelf.id }).unwrap();
    setDeleteShelfImage(false);
    setSrc('');
    refetchShelf?.();
  };

  const deleteSubShelf = async () => {
    if (deleteId) {
      await removeSubShelf({ id: deleteId }).unwrap();
      refetchSubShelf?.();
      setDeleteId(0);
    }
  };

  if (!shelf) return <></>;

  return (
    <>
      <Dialog open={open} onClose={onClose} scroll='paper' maxWidth={false}>
        <DialogContent dividers>
          <div className='head'>
            <div className='name'>Торговая точка</div>
            <div className='title'>{shelf.tradePointName || ''}</div>
            <div className='name'>Полка</div>
            <div className='title'>{shelf.name || ''}</div>
          </div>
          <Stack
            direction='row'
            spacing={1}
            width='100%'
            style={{ marginTop: '15px' }}
          >
            <Stack
              direction='row'
              alignContent='center'
              justifyContent='center'
              width='100%'
              minHeight={200}
            >
              <Box
                className='shelf-image'
                sx={{ display: !src || loading ? 'none' : undefined }}
              >
                <canvas ref={canvasRef} />
                <Tooltip title='Удалить' placement='top'>
                  <button
                    className='remove-button'
                    onClick={() => setDeleteShelfImage(true)}
                  >
                    &times;
                  </button>
                </Tooltip>
              </Box>
              {src ? (
                loading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : null
              ) : (
                <Paper sx={{ width: '100%' }}>
                  <Stack
                    alignContent='center'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    sx={{ flexWrap: 'wrap' }}
                  >
                    <input
                      type='file'
                      style={{ display: 'none' }}
                      ref={inputRef}
                      onChange={e => onFileChange(e)}
                    />
                    <Button
                      sx={{ height: 'max-content', width: 'max-content' }}
                      variant='outlined'
                      size='large'
                      onClick={fileDialog}
                    >
                      Добавить фото
                    </Button>
                  </Stack>
                </Paper>
              )}
            </Stack>
            {src && !loading ? (
              <Paper sx={{ width: '100%', padding: '10px' }}>
                <div className='title'>Секции полки</div>
                <List
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    cursor: 'pointer',
                  }}
                >
                  {subShelves?.map(s => (
                    <Paper
                      sx={{ width: '100%', marginBottom: '5px' }}
                      key={s.id}
                    >
                      <ListItem
                        onClick={() => {
                          if (selected === s) {
                            setSelected(undefined);
                          } else {
                            setSelected(s);
                          }
                        }}
                        sx={{
                          backgroundColor: selected === s ? '#ffcece' : '',
                        }}
                      >
                        <ListItemText primary={s.name} />
                        {s.hasImage ? (
                          <Tooltip title='Показать секцию' placement='top'>
                            <IconButton
                              onClick={e => {
                                e.stopPropagation();
                                setSubShelf(s);
                              }}
                            >
                              <Search
                                fontSize='small'
                                className='cell-button'
                              />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        <Tooltip title='Удалить' placement='top'>
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              setDeleteId(s.id);
                            }}
                          >
                            <DeleteForever
                              fontSize='small'
                              className='cell-button'
                            />
                          </IconButton>
                        </Tooltip>
                      </ListItem>
                    </Paper>
                  ))}
                </List>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={() => setNewSubShelf(true)}
                >
                  Добавить секцию
                </Button>
              </Paper>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!deleteId} onClose={() => setDeleteId(0)}>
        <DialogTitle>Удалить секцию?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteId(0)}>Отмена</Button>
          <Button onClick={deleteSubShelf} autoFocus>
            Да
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteShelfImage}
        onClose={() => setDeleteShelfImage(false)}
      >
        <DialogTitle>Удалить фото секции?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteShelfImage(false)}>Отмена</Button>
          <Button onClick={removeShelfImage} autoFocus>
            Да
          </Button>
        </DialogActions>
      </Dialog>
      {newSubShelf ? (
        <NewEmptyPlace
          list={subShelves}
          shelfId={id}
          onClose={() => {
            refetchSubShelf?.();
            setNewSubShelf(false);
          }}
          open={true}
        />
      ) : null}
      <SubShelf data={subShelf} onClose={() => setSubShelf(undefined)} />
    </>
  );
};
